import Button from '@/components/Button';
import { H1 } from '@/components/Typography';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RpmMyPatientHeader = () => {
  const navigate = useNavigate();

  const redirectToRpmPatientOnboardScreen = () => {
    navigate('/global-lookup');
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <H1
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '32px',
          flex: 1,
          textAlign: 'left'
        }}
      >
        RPM Patients
      </H1>
      <Button label="Onboard Patients" onClick={() => redirectToRpmPatientOnboardScreen()} />
    </Stack>
  );
};

export default RpmMyPatientHeader;
