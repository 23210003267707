import type { TruentityDateInput } from './date';

export type UnitValue<T> = {
  id?: string;
  value: string;
  unit?: T;
  timestamp?: string;
  isPrescribed?: boolean;
};

export type SimpleValue<T> = {
  value: T;
  timestamp?: string;
  isPrescribed?: boolean;
};

export enum StrengthUnit {
  MCG = 'mcg',
  MG = 'mg',
  G = 'g',
  IU = 'IU',
  ML = 'ml',
  CC = 'cc'
}

export enum MedicationPeriod {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  AS_NEEDED = 'as needed'
}

export enum DosageForm {
  TABLET = 'tablet',
  CAPSULE = 'capsule',
  PILL = 'pill',
  CHEWABLE = 'chewable',
  SYRUP = 'syrup',
  WAFER = 'wafer',
  INJECTION = 'injection',
  LOTION = 'lotion',
  OINTMENT = 'ointment',
  SPRAY = 'spray',
  SOLUTION = 'solution',
  NONE = ''
}

export enum SourceType {
  MANUAL = 'Manual',
  LABEL_DETECT = 'Label Scan',
  BARCODE_DETECT = 'Barcode Scan',
  PROVIDER_IMPORT = 'Provider Import',
  PHARMACY_IMPORT = 'Pharmacy Import',
  PROVIDER = 'Provider',
  PHARMACY = 'Pharmacy',
  QUICK_SCAN = 'Quick Scan',
  IMPORTED_ALLSCRIPTS = 'Imported:Allscripts',
  IMPORTED_ESIHEALTHCARE = 'Imported:ESIHealthcare',
  IMPORTED_OTHER = 'Imported:Other'
}

//TODO:  Remove this.  This has been combined with SourceType above
export const EXTERNAL_MED_SOURCE_OPTIONS = [
  { label: 'Allscripts', value: 'Imported:Allscripts' },
  { label: 'ESIHealthcare', value: 'Imported:ESIHealthcare' },
  { label: 'Other', value: 'Imported:Other' }
];

export enum TimeOfDayOptions {
  MORNING = 'morning',
  NOON = 'noon',
  EVENING = 'evening',
  NIGHT = 'night'
}

export enum RouteOfAdministrationOptions {
  BY_MOUTH = 'By mouth',
  TOPICALLY = 'Topically',
  INTRANASALLY = 'Intranasally',
  SUBLINGUALLY = 'Sublingually',
  RECTALLY = 'Rectally',
  SUBCUTANEOUSLY = 'Subcutaneously',
  INTRAMUSCULARLY = 'Intramuscularly',
  VIA_NEBULIZER = 'Via Nebulizer',
  OTHER = 'Other'
}

export type Strength = UnitValue<StrengthUnit>;

export type Dosage = UnitValue<DosageForm>;

export type Period = UnitValue<MedicationPeriod>;

export type RouteOfAdministration = UnitValue<RouteOfAdministrationOptions>;

export type TimeOfDay = UnitValue<TimeOfDayOptions>;

export type Reason = SimpleValue<string>;

export const DEFAULT_STRENGTH: Strength = {
  value: '',
  unit: StrengthUnit.MCG
};

export const DEFAULT_DOSAGE: Dosage = {
  value: '',
  unit: DosageForm.NONE
};

export const DEFAULT_PERIOD: Period = {
  value: '',
  unit: MedicationPeriod.DAILY
};

export const DEFAULT_ROUTE_OF_ADMINISTRATION: RouteOfAdministration = {
  value: RouteOfAdministrationOptions.BY_MOUTH
};

export const DEFAULT_TIME_OF_DAY: TimeOfDay = {
  value: TimeOfDayOptions.MORNING
};

export const DEFAULT_REASON: Reason = {
  value: ''
};

export type FormState = {
  id: string | null;
  ndc: null;
  name: string | null;
  displayName: string | null;
  strength: Strength;
  modifiedStrength: Strength;
  dosage: Dosage;
  modifiedDosage: Dosage;
  period: Period;
  modifiedPeriod: Period;
  routeOfAdministration: RouteOfAdministration;
  modifiedRouteOfAdministration: RouteOfAdministration;
  timeOfDay: TimeOfDay;
  modifiedTimeOfDay: TimeOfDay;
  instructions: string;
  isTakenAsPrescribed: boolean;
  isTakenAsDirected: boolean;
  isNoLongerTaking: boolean;
  isTakingDifferently: boolean;
  isUnableToVerify: boolean;
  prescriberName: string;
  lastFillDateAt: Date | null;
  quantity: number | null;
  refills: string;
  isEffective: boolean;
  source: SourceType;
  reason: Reason;
  isSupplement: boolean;
  rxcui: null;
  pharmacyName: string;
  prescriptionWrittenDateAt: Date | null;
  soldDateAt: Date | null;
};

export type TaskFormState = {
  id: string | null;
  type: string | null;
  subType: string | null;
  healthPlan: string | null;
  medicationName: string | null;
  name: string | null;
  performedOn: Date | null;
  status: string | null;
  resolutionStatus: string | null;
  modeOfResolution: string | null;
  wrapUpStatus: string | null;
  nextSteps: string | null;
  location: string | null;
  billedAmount: number | null;
  isPrimaryAffiliation: boolean;
};

export type MedicationInformation = {
  name: string;
  brand: string;
  type: string;
  description: string;
  route: string;
  rxcui: string;
  images: string[];
  manufactorerName: string;
  drugInteractions: string;
  packageLabels: string;
  precautions: string;
  indicationsAndUsage: string;
  pediatricUse: string;
  contraindications: string;
  drugAbuseAndDependence: string;
  pregnancy: string;
  nursingMothers: string;
  splProductDataElements: string;
  dosageAndAdministration: string;
  controlledSubstance: string;
  adverseReactions: string;
  informationForPatients: string;
  howSupplied: string;
  packageLabelPrincipalDisplayPanel: string;
  clinicalPharmacology: string;
  carcinogenesisAndMutagenesisAndImpairmentOfFertility: string;
  overdosage: string;
  boxedWarning: string;
  geriatricUse: string;
  otherReferences: string;
  splUnclassifiedSection: string;
};

type PrescriberInfo = {
  phoneNumber: string;
  faxNumber: string;
};

type Interaction = {
  title: string;
  severityLevel: string;
};

type PharmacyInfo = {
  phoneNumber: string;
  faxNumber: string;
};

export type Medication = {
  id?: string | undefined;
  ndc: string;
  name: string;
  displayName?: string;
  strength?: UnitValue<StrengthUnit>[];
  dosage?: UnitValue<DosageForm>[];
  period?: UnitValue<MedicationPeriod>[];
  routeOfAdministration?: RouteOfAdministration[];
  timeOfDay?: TimeOfDay[];
  note?: string;
  instructions?: string;
  isCurrent?: boolean;
  isTakenAsPrescribed?: boolean;
  isNoLongerTaking?: boolean;
  isTakingDifferently?: boolean;
  isTakenAsDirected?: boolean;
  isUnableToVerify?: boolean;
  prescriberName?: string;
  lastFillDateAt?: string | null;
  quantity?: number;
  numRefills?: number;
  prescriptionWrittenDateAt?: string | null;
  soldDateAt?: string | null;
  pharmacyName?: string;
  isEffective?: boolean;
  source?: SourceType;
  isSupplement?: boolean;
  attachments?: AttachmentDetails[];
  adherance?: TruentityDateInput;
  updatedAt?: TruentityDateInput;
  createdAt?: TruentityDateInput;
  fillOptions?: MedicationFillOption[];
  rxcui?: string;
  importedName?: string;
  lastVerifiedById?: string;
  pharmacy?: PharmacyInfo;
  prescriber?: PrescriberInfo;
  interactions?: Interaction[];
};

export const STRENGTH_UNIT_OPTIONS = [
  { label: 'mcg', value: 'mcg' },
  { label: 'mg', value: 'mg' },
  { label: 'g', value: 'g' },
  { label: 'UI', value: 'ui' },
  { label: 'ml', value: 'ml' },
  { label: 'cc', value: 'cc' }
];

export const frequencyOptionsMap = {
  '': ':daily',
  'Once Daily': '1:daily',
  'Twice Daily': '2:daily',
  'Thrice Daily': '3:daily',
  'Four Times Daily': '4:daily',
  'Every 4-6 Hours': '4-6:daily_hours_after',
  'Once Weekly': '1:weekly',
  'As Needed': '1:as_needed'
};

export const DOSAGE_UNIT_OPTIONS = [
  { label: 'tablet', value: 'tablet' },
  { label: 'capsule', value: 'capsule' },
  { label: 'pill', value: 'pill' },
  { label: 'chewable', value: 'chewable' },
  { label: 'syrup', value: 'syrup' },
  { label: 'wafer', value: 'wafer' },
  { label: 'injection', value: 'injection' },
  { label: 'lotion', value: 'lotion' },
  { label: 'ointment', value: 'ointment' },
  { label: 'spray', value: 'spray' },
  { label: 'solution', value: 'solution' }
];

export const ROUTE_OF_ADMINISTRATION_OPTIONS = [
  { label: 'By mouth', value: 'By mouth' },
  { label: 'Topically', value: 'Topically' },
  { label: 'Intranasally', value: 'Intranasally' },
  { label: 'Sublingually', value: 'Sublingually' },
  { label: 'Rectally', value: 'Rectally' },
  { label: 'Subcutaneously', value: 'Subcutaneously' },
  { label: 'Intramuscularly', value: 'Intramuscularly' },
  { label: 'Via Nebulizer', value: 'Via Nebulizer' },
  { label: 'Other', value: 'Other' }
];
export const TIME_OF_DAY_OPTIONS = [
  { label: 'morning', value: 'morning' },
  { label: 'noon', value: 'noon' },
  { label: 'evening', value: 'evening' },
  { label: 'night', value: 'night' },
  { label: 'multiple', value: 'multiple' }
];

export type AttachmentDetails = {
  id: string;
  url: string;
};

export type MedicationFillOption = {
  form: string;
  rxterm: string;
  rxcui: string;
  strength: number;
  unit: string;
};

export type MedicationVerificationStatus = {
  id: string;
  isVerified: boolean;
};

export type Encounter = {
  id?: string | undefined;
  calledOn: Date | undefined;
  performedOn?: Date;
  disposition: string;
  notes?: string;
  subType?: string;
  title?: string;
  type?: string;
  assigned?: boolean;
  edited?: boolean;
};

export const TASK_TYPES = [
  { label: 'CMR', value: 'CMR' },
  { label: 'Adherence', value: 'Adherence' },
  { label: 'Adherence - Needs Check In', value: 'AdherenceNeedsCheckIn' },
  { label: 'TIP', value: 'TIP' },
  { label: 'TIP - Needs Medication Assessment', value: 'TIPNeedsMedicationAssessment' },
  { label: 'TIP - Adherence 90/100 day supply', value: 'TIPAdherence90/100DaySupply' },
  { label: 'TIP - Needs drug therapy', value: 'TIPNeedsDrugTherapy' },
  { label: 'TIP - Needs lab monitoring', value: 'TIPNeedsLabMonitoring' },
  { label: 'TIP - Opioid', value: 'TIPOpioid' },
  { label: 'TIP - High Risk Medication', value: 'TIPHighRiskMedication' },
  { label: 'TIP - Suboptimal Drug Selection', value: 'TIPSuboptimalDrugSelection' }
];

export const UPDATED_SUB_TASK_TYPES = [
  { label: 'Needs Refill', value: 'TIPNeedsRefill' },
  { label: 'Needs Medication Assessment', value: 'TIPNeedsMedicationAssessment' },
  { label: 'Adherence 90/100 day supply', value: 'TIPAdherence90/100DaySupply' },
  { label: 'Needs drug therapy', value: 'TIPNeedsDrugTherapy' },
  { label: 'Needs lab monitoring', value: 'TIPNeedsLabMonitoring' },
  { label: 'Adherence Check In', value: 'AdherenceNeedsCheckIn' },
  { label: 'Opioid', value: 'TIPOpioid' },
  { label: 'High Risk Medications', value: 'TIPHighRiskMedication' },
  { label: 'Suboptimal Drug Selection', value: 'TIPSuboptimalDrugSelection' },
  { label: 'Patient Education', value: 'PatientEducation' },
  { label: 'New Therapy Consultation ', value: 'NewTherapyConsultation' }
];

export const TASK_DUPLICATION_COUNT_TYPES = [
  { label: 'One', value: '1' },
  { label: 'Two ', value: '2' },
  { label: 'Three', value: '3' },
  { label: 'Four', value: '4' },
  { label: 'Five', value: '5' }
];

export const TASK_MODE_OF_RESOLUTION_TYPES = [
  { label: 'Call Patient', value: 'CallPatient' },
  { label: 'Call Prescriber', value: 'CallPrescriber' },
  { label: 'Fax Prescriber', value: 'FaxPrescriber' },
  { label: 'Local Pharmacy Follow-up', value: 'LocalPharmacyFollowUp' },
  { label: 'Chart Review', value: 'ChartReview' },
  { label: 'Sent Text Message', value: 'SentTextMessage' }
];

export const TASK_WRAP_UP_TYPES = [
  { label: 'Completed', value: 'Completed' },
  { label: 'Patient Declined', value: 'PatientDeclined' },
  { label: 'Prescriber Declined', value: 'PrescriberDeclined' },
  { label: 'Unable To Reach (3 attempts made)', value: 'UnableToReach(3 attempts made)' },
  { label: 'TIP Removed', value: 'TIPRemoved' }
];

export const TASK_SUB_TYPES = [
  { label: 'Billable Analysis', value: 'BillableAnalysis' },
  { label: 'Medication Triage', value: 'MedicationTriage' },
  { label: 'TIP', value: 'TIP' },
  { label: 'CMR', value: 'CMR' },
  { label: 'Submit Claim', value: 'SubmitClaim' },
  { label: 'Resolve Claim', value: 'ResolveClaim' }
];

export const TASK_STATUSES = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Approved & Pending Payment', value: 'ApprovedAndPendingPayment' },
  { label: 'Approved & Paid', value: 'ApprovedAndPaid' },
  { label: 'TIP Removed', value: 'TIPRemoved' },
  { label: 'Mailout Pending', value: 'MailoutPending' },
  { label: 'Claim Pending CMR Completion', value: 'ClaimPendingCMRCompletion' }
];

export const TASK_RESOLUTION_STATUSES = [
  { label: 'Successful', value: 'Successful' },
  { label: 'Patient Refused', value: 'PatientRefused' },
  { label: 'Patient Unreachable', value: 'PatientUnreachable' },
  { label: 'Prescriber Refused', value: 'PrescriberRefused' },
  { label: 'Provider Refused', value: 'ProviderRefused' }
];

export const TASK_NEXT_STEPS = [
  { label: 'Call Patient', value: 'CallPatient' },
  { label: 'Call Pharmacy', value: 'CallPharmacy' },
  { label: 'Call Provider', value: 'CallProvider' }
];

export const DISPOSITION_TYPES = [
  { label: 'No Answer - No VM', value: 'NoAnswerNoVm' },
  { label: 'Left VM', value: 'LeftVM' },
  { label: 'Inactive or Wrong Number', value: 'InactiveWrongNo' },
  { label: 'Do Not Contact', value: 'DoNotContact' },
  { label: 'Refuses Services', value: 'RefusesServices' },
  { label: 'Incoming Call', value: 'IncomingCall' },
  { label: 'ESL/LEP (refer to bilingual representative)', value: 'ESL_LEP' },
  { label: 'Answered', value: 'Answered' }
];

export type EncounterData = {
  id: string;
  title: string;
  type: string;
  subType: string;
  disposition: string;
  calledOn: string;
  modeOfResolution: string;
  notes: string;
  tasks: [];
};
