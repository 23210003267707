import type { RpmFollowupConsultTabType, RpmFollowupConsultType } from '@/types/remotePatientMonitoring';
import { RpmSetupStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { create } from 'zustand';

type RpmFollowupConsultStoreType = {
  rpmFollowupConsult: RpmFollowupConsultType | null;
  setRpmFollowupConsult: (value: RpmFollowupConsultType | null) => void;
  shouldReFetchRpmFollowupConsult: boolean;
  setShouldReFetchRpmFollowupConsult: (value: boolean) => void;
  rpmFollowupConsultTabs: Pick<
    Record<RpmWorkflowTab, RpmFollowupConsultTabType>,
    RpmWorkflowTab.CLINICAL_SUMMARY | RpmWorkflowTab.MEDICAL_CONSULTS | RpmWorkflowTab.CARE_PLAN | RpmWorkflowTab.MEDICAL_RECONCILIATION
  >;
  setRpmFollowupConsultTabs: (
    value: Pick<
      Record<RpmWorkflowTab, RpmFollowupConsultTabType>,
      RpmWorkflowTab.CLINICAL_SUMMARY | RpmWorkflowTab.MEDICAL_CONSULTS | RpmWorkflowTab.CARE_PLAN | RpmWorkflowTab.MEDICAL_RECONCILIATION
    >
  ) => void;
  triggerTabStatusUpdate: (completed: boolean, isManual?: boolean, tab?: RpmWorkflowTab) => Promise<void>;
  setTriggerTabStatusUpdate: (fn: (completed: boolean, isManual?: boolean, tab?: RpmWorkflowTab) => Promise<void>) => void;
};

export const initialRpmFollowupConsultTabs: Pick<
  Record<RpmWorkflowTab, RpmFollowupConsultTabType>,
  RpmWorkflowTab.CLINICAL_SUMMARY | RpmWorkflowTab.MEDICAL_CONSULTS | RpmWorkflowTab.CARE_PLAN | RpmWorkflowTab.MEDICAL_RECONCILIATION
> = {
  [RpmWorkflowTab.CLINICAL_SUMMARY]: {
    id: '1',
    type: RpmWorkflowTab.CLINICAL_SUMMARY,
    status: RpmSetupStatusTypes.IN_PROGRESS,
    isManualStatusUpdate: false,
    createdAt: new Date().toISOString()
  },
  [RpmWorkflowTab.MEDICAL_RECONCILIATION]: {
    id: '2',
    type: RpmWorkflowTab.MEDICAL_RECONCILIATION,
    status: RpmSetupStatusTypes.IN_PROGRESS,
    isManualStatusUpdate: false,
    createdAt: new Date().toISOString()
  },
  [RpmWorkflowTab.MEDICAL_CONSULTS]: {
    id: '3',
    type: RpmWorkflowTab.MEDICAL_CONSULTS,
    status: RpmSetupStatusTypes.IN_PROGRESS,
    isManualStatusUpdate: false,
    createdAt: new Date().toISOString()
  },
  [RpmWorkflowTab.CARE_PLAN]: {
    id: '4',
    type: RpmWorkflowTab.CARE_PLAN,
    status: RpmSetupStatusTypes.IN_PROGRESS,
    isManualStatusUpdate: false,
    createdAt: new Date().toISOString()
  }
};

export const useRpmFollowupConsultStore = create<RpmFollowupConsultStoreType>(set => ({
  rpmFollowupConsult: null,
  setRpmFollowupConsult: value => set(() => ({ rpmFollowupConsult: value })),
  shouldReFetchRpmFollowupConsult: false,
  setShouldReFetchRpmFollowupConsult: value => set(() => ({ shouldReFetchRpmFollowupConsult: value })),
  rpmFollowupConsultTabs: initialRpmFollowupConsultTabs,
  setRpmFollowupConsultTabs: value => set(() => ({ rpmFollowupConsultTabs: value })),
  triggerTabStatusUpdate: async () => {},
  setTriggerTabStatusUpdate: fn => set({ triggerTabStatusUpdate: fn })
}));
