import Button from '@/components/Button';
import { Body1, H4 } from '@/components/Typography';
import type { RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { RpmWorkflowTabNames } from '@/types/remotePatientMonitoring';
import { Popover, Stack } from '@mui/material';
import parse from 'html-react-parser';

type ButtonPopoverProps = {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  mandatoryTabs: RpmWorkflowTab[] | string[];
  rpmFollowupConsultId?: string;
};

const ButtonPopover = ({ id, open, anchorEl, handleClose, mandatoryTabs, rpmFollowupConsultId }: ButtonPopoverProps) => {
  const mandatoryTabsSentence =
    mandatoryTabs
      .map(tab => `<strong>${RpmWorkflowTabNames[tab]}</strong>`)
      .join(', ')
      .replace(/,([^,]*)$/, ', and$1') +
    ` Needs to be completed in order to ${rpmFollowupConsultId ? 'complete this Follow-up Visit' : 'enroll this Patient'}.`;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      sx={{ mt: 1 }}
    >
      <Stack py={2} px={3} maxWidth={'27.75rem'} rowGap={1.5} sx={{ bgcolor: 'white' }}>
        <H4>{rpmFollowupConsultId ? 'Follow-up Visit cannot be completed at the moment' : 'Patient cannot be enrolled at the moment'}</H4>
        <Body1 style={{ fontSize: '.75rem' }}>{parse(mandatoryTabsSentence)}</Body1>
        <Button sx={{ alignSelf: 'flex-end' }} onClick={handleClose}>
          OK
        </Button>
      </Stack>
    </Popover>
  );
};

export default ButtonPopover;
