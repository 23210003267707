import CustomDatePickerHeader from '@/components/CustomDatePickerHeader';
import type { TruentityTextFieldProps } from '@/components/TruentityTextField';
import { incrementMonths, incrementYears, toTruentityDateInput } from '@/util/date';
import styled from '@emotion/styled';
import type { SlotComponentProps } from '@mui/base';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import type { SxProps } from '@mui/material';
import { IconButton, Stack } from '@mui/material';
import type { MobileDatePickerProps } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { Moment } from 'moment';
import { forwardRef, useEffect, useState } from 'react';

type Props = Omit<MobileDatePickerProps<any>, 'renderInput'> & {
  TextFieldProps?: TruentityTextFieldProps;
  defaultValue?: Date | Moment | string | null;
  fullWidth?: boolean;
  sx?: SxProps;
  slotProps?: SlotComponentProps<any, any, any>;
  showYear?: boolean;
  showMonth?: boolean;
};

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  height: 50,
  width: 50,
  marginRight: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: theme.palette.primary.light
  }
}));

const TruentityDatePicker = forwardRef(
  ({ TextFieldProps, fullWidth, sx, slotProps = {}, showYear, showMonth, defaultValue, value, ...props }: Props, ref) => {
    const [selectedDate, setSelectedDate] = useState(toTruentityDateInput(value) ?? null);

    useEffect(() => {
      if (defaultValue) {
        setSelectedDate(toTruentityDateInput(defaultValue) ?? null);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (props.onChange) {
        props.onChange(selectedDate, 'date');
      }
    }, [selectedDate, props?.onChange]);

    const handleDateChange = date => {
      setSelectedDate(date);
    };

    const changeYear = increment => {
      setSelectedDate(incrementYears(selectedDate, increment));
    };

    const changeMonth = increment => {
      setSelectedDate(incrementMonths(selectedDate, increment));
    };

    if (typeof fullWidth === 'undefined') {
      fullWidth = true;
    }
    return (
      <Stack direction={'row'} alignItems={'center'} sx={{ width: sx?.width || '100%' }}>
        {showYear && (
          <CustomIconButton onClick={() => changeYear(-1)}>
            <KeyboardDoubleArrowLeft />
          </CustomIconButton>
        )}
        {showMonth && (
          <CustomIconButton onClick={() => changeMonth(-1)}>
            <KeyboardArrowLeftIcon />
          </CustomIconButton>
        )}
        <DatePicker
          {...props}
          ref={ref}
          value={selectedDate}
          onChange={handleDateChange}
          slots={{ ...props.slots, calendarHeader: showYear || showMonth ? CustomDatePickerHeader : undefined }}
          slotProps={{
            textField: {
              sx: {
                ...sx,
                width: '100%'
              },
              fullWidth,
              margin: 'dense',
              size: TextFieldProps?.size || 'medium',
              variant: 'outlined',
              ...TextFieldProps
            },
            actionBar: {
              actions: ['clear']
            },
            calendarHeader: {
              changeMonth: changeMonth,
              changeYear: changeYear,
              selectedDate: selectedDate
            },
            ...slotProps
          }}
        />
        {showMonth && (
          <CustomIconButton onClick={() => changeMonth(1)}>
            <KeyboardArrowRightIcon />
          </CustomIconButton>
        )}
        {showYear && (
          <CustomIconButton onClick={() => changeYear(1)}>
            <KeyboardDoubleArrowRight />
          </CustomIconButton>
        )}
      </Stack>
    );
  }
);

export default TruentityDatePicker;
