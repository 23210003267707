import { TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { PatientsData } from '@/routes/Patients/patients';
import type { RpmDiagnoses } from '@/types/administration';
import { Box, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';

type Props = {
  setHasClinicalDiagnoses: (value: boolean) => void;
};

const ClinicalDiagnosesDataGrid: React.FC<Props> = ({ setHasClinicalDiagnoses }) => {
  const { patientInfo } = useContext(PatientDetailContext);

  const getCustomRowId = useCallback((row: RpmDiagnoses) => {
    return `${row.diagnosisCode}-${row.diagnosesName}}`;
  }, []);

  const diagnosesColumns: GridColDef<PatientsData[]>[] = useMemo(
    () => [
      {
        field: 'diagnosesName',
        headerName: 'Diagnoses Name',
        minWidth: 350,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'diagnosesCode',
        headerName: 'Diagnoses Code',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      }
    ],
    []
  );

  useEffect(() => {
    const clinicalDiagnosesLength = patientInfo?.accountRpmDiagnosis?.length ?? 0;
    setHasClinicalDiagnoses(clinicalDiagnosesLength > 0);
  }, [patientInfo?.accountRpmDiagnosis]);

  return (
    <Box mt={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <H4>Clinical Diagnoses</H4>
      </Stack>
      <TruentityDataGrid
        name={'dg-clinical-diagnoses'}
        autoHeight
        columns={diagnosesColumns}
        disableRowSelectionOnClick
        sx={{ backgroundColor: '#ffffff' }}
        getRowId={getCustomRowId}
        rows={patientInfo?.accountRpmDiagnosis || []}
      />
    </Box>
  );
};

export default ClinicalDiagnosesDataGrid;
