import type { JSONData } from '@/components/JsonKit/JsonEditor';
import { Box, Popover } from '@mui/material';
import ReactJson from 'react-json-view';

type Props = {
  open: boolean;
  anchorEl: null | HTMLElement;
  onClose: () => void;
  jsonData: JSONData;
  onTransitionExited: () => void;
};

const ProcessDataPopover = ({ open, anchorEl, onClose, jsonData, onTransitionExited }: Props) => {
  return (
    <Popover
      id={open ? 'process-data-simple-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      disableRestoreFocus
      onTransitionExited={onTransitionExited}
    >
      <Box p={3} bgcolor="white">
        <ReactJson src={jsonData} collapsed={1} enableClipboard displayObjectSize displayDataTypes />
      </Box>
    </Popover>
  );
};

export default ProcessDataPopover;
