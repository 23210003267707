import Button from '@/components/Button';
import ChipsList from '@/components/ChipsList';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { Body1 } from '@/components/Typography';
import type { GetAccountMonitoringDevicesResponse } from '@/graphql/remotePatientMonitoring';
import { GET_ACCOUNT_MONITORING_DEVICE_NAMES } from '@/graphql/remotePatientMonitoring';
import styled from '@/styles';
import type { AccountsMonitoringDeviceType } from '@/types/remotePatientMonitoring';
import { maxNamesToShow } from '@/util/constants';
import { useQuery } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, Popover, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

type Props = {
  truentityId?: string;
};

const ButtonMoreContainer = styled('div', {
  margin: 'auto !important',
  '& button': {
    marginLeft: '8px'
  },
  '.MuiButton-iconSizeSmall': {
    margin: '0px !important'
  }
});

const RpmDevicesList = ({ truentityId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [devicesSuppliedAnchorEl, setDevicesSuppliedAnchorEl] = useState(null);
  const [showMoreAssignedDevices, setShowMoreAssignedDevices] = useState(false);
  const [monitoringDevices, setMonitoringDevices] = useState<AccountsMonitoringDeviceType[]>([]);

  const { data: monitoringDevicesData, error: monitoringDevicesError } = useQuery<GetAccountMonitoringDevicesResponse>(
    GET_ACCOUNT_MONITORING_DEVICE_NAMES,
    {
      variables: {
        truentityId: truentityId,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: 1
      },
      skip: !truentityId,
      fetchPolicy: 'cache-and-network'
    }
  );

  const handleAssignedDevicesPopoverClick = event => {
    setDevicesSuppliedAnchorEl(event.currentTarget);
    setShowMoreAssignedDevices(true);
  };

  const handleAssignedDevicesPopoverClose = () => {
    setDevicesSuppliedAnchorEl(null);
    setShowMoreAssignedDevices(false);
  };

  useEffect(() => {
    if (monitoringDevicesData?.getAccountDevices?.devices) {
      setMonitoringDevices(monitoringDevicesData?.getAccountDevices?.devices);
    }
  }, [monitoringDevicesData?.getAccountDevices?.devices]);

  useEffect(() => {
    if (monitoringDevicesError) {
      enqueueSnackbar('Could not get monitoring devices details', {
        variant: 'error'
      });
    }
  }, [monitoringDevicesError]);

  return (
    <Stack direction="row" spacing={1} alignItems={'center'}>
      <Body1>Devices Supplied:</Body1>
      {monitoringDevices?.length > 0 ? (
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Stack direction="row" spacing={1}>
            {monitoringDevices.map(
              ({ monitoringDevice }, index) =>
                index < maxNamesToShow && (
                  <div key={`${monitoringDevice?.name}-${index}`}>
                    {monitoringDevice?.name && (
                      <Chip size="medium" variant={'outlined'} label={monitoringDevice?.name} style={{ marginRight: '0px' }} />
                    )}
                  </div>
                )
            )}

            {monitoringDevices.length > maxNamesToShow && (
              <ButtonMoreContainer>
                <Button
                  variant="text"
                  label={`${monitoringDevices.length - maxNamesToShow}+`}
                  onClick={handleAssignedDevicesPopoverClick}
                  endIcon={showMoreAssignedDevices ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                />
              </ButtonMoreContainer>
            )}
          </Stack>
          <Popover open={showMoreAssignedDevices} anchorEl={devicesSuppliedAnchorEl} onClose={handleAssignedDevicesPopoverClose}>
            <Stack direction="row" spacing={1} sx={{ p: 2 }}>
              <ChipsList
                items={monitoringDevices?.map(({ monitoringDevice }) => monitoringDevice?.name)}
                maxItemsToShow={maxNamesToShow}
                sx={{ marginRight: '4px' }}
              />
              <Button variant="text" onClick={handleAssignedDevicesPopoverClose}>
                Close
              </Button>
            </Stack>
          </Popover>
        </Stack>
      ) : (
        <Chip size="medium" variant={'outlined'} label={'Not Assigned'} style={{ marginRight: '4px' }} />
      )}
    </Stack>
  );
};

export default RpmDevicesList;
