import { SCHEDULE_CLAIM_SUBMISSION } from '@/graphql/claimSubmissions';
import { MedicalServices } from '@/types/admin';
import type { RelyingPartyType } from '@/types/graphql';
import { today } from '@/util/date';
import { formatDate } from '@/util/format';
import { useMutation } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { Box, DialogActions, DialogContent, Divider, Stack } from '@mui/material';
import type { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityDatePicker from '../TruentityDatePicker';
import { Body2, Label } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  description: string;
  monthAndYear: DateInput;
  allowableRelyingParties: RelyingPartyType[];
  selectedRelyingPartyId: string;
  onSuccess: () => void;
};

type ServiceDates = Record<string, Moment | null>;

type FormValues = {
  defaultServiceDate: Moment | null;
  serviceDates: ServiceDates;
};

const ScheduleClaimSubmission = ({
  title,
  monthAndYear,
  description,
  hideDialog,
  allowableRelyingParties,
  selectedRelyingPartyId,
  onSuccess,
  ...props
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [handleScheduleSubmission, { loading: loadingScheduleSubmission }] = useMutation(SCHEDULE_CLAIM_SUBMISSION, {
    variables: {
      monthYear: monthAndYear,
      serviceType: MedicalServices.RPM,
      ...(selectedRelyingPartyId !== 'ALL' && { relyingPartyIds: [selectedRelyingPartyId] })
    },
    onCompleted: data => {
      const { status = 'Error', message = 'Unexpected error occurred.' } = data?.scheduleClaimSubmission ?? {};
      const variant = status === 'Success' ? 'success' : 'error';
      enqueueSnackbar(message, { variant });
      onSuccess();
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to schedule the claim submissions. Please contact support.', { variant: 'error' });
      }
    }
  });

  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      defaultServiceDate: today(),
      serviceDates: allowableRelyingParties.reduce(
        (acc, party) => ({
          ...acc,
          [party.id]: today()
        }),
        {}
      )
    }
  });

  const defaultServiceDate = watch('defaultServiceDate');

  const onSubmit = (data: FormValues) => {
    try {
      const refactoredServiceDates = Object.entries(data.serviceDates).map(([key, value]) => {
        return { relyingPartyId: key, serviceDate: formatDate(value, 'DD-MM-YYYY') };
      });
      handleScheduleSubmission({
        variables: {
          relyingPartyServiceDates: refactoredServiceDates
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to schedule the claim submissions. Please contact support.', { variant: 'error' });
    }
  };

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Body2>{description}</Body2>
          <Stack direction="row" mt={2} alignItems="center">
            <Label flex={0.3}>Default Date:</Label>
            <Controller
              name="defaultServiceDate"
              control={control}
              render={({ field }) => (
                <Box flex={0.7}>
                  <TruentityDatePicker disableFuture value={field.value} onChange={field.onChange} />
                </Box>
              )}
            />
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Box overflow="auto" maxHeight="17.1875rem">
            {allowableRelyingParties.map(party => (
              <Stack direction="row" mt={2} alignItems="center" key={party.id}>
                <Label flex={0.3}>{party.name}:</Label>
                <Controller
                  name={`serviceDates.${party.id}`}
                  control={control}
                  render={({ field }) => (
                    <Box flex={0.7}>
                      <TruentityDatePicker disableFuture defaultValue={defaultServiceDate} value={field.value} onChange={field.onChange} />
                    </Box>
                  )}
                />
              </Stack>
            ))}
          </Box>
          <DialogActions>
            <Button variant="outlined" onClick={hideDialog}>
              Cancel
            </Button>
            <Button type="submit" isLoading={loadingScheduleSubmission}>
              Schedule
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </BaseDialog>
  );
};

export default ScheduleClaimSubmission;
