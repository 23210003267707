import Alert from '@/components/Alert';
import Button from '@/components/Button';
import PdfDialog from '@/components/Dialogs/PdfDialog';
import { H3, H5 } from '@/components/Typography';
import type { UpdateRpmSetupResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import type { CreatePdfRpmEncounterReportResponse, GetDocumentPreviewResponse, GetRpmAccountSummaryResponse } from '@/graphql/rpmWorkflow';
import { CREATE_PDF_RPM_ENCOUNTER_REPORT, GET_DOCUMENT_PREVIEW, GET_RPM_ACCOUNT_SUMMARY } from '@/graphql/rpmWorkflow';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import ClinicalSummariesDataGrid from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ClinicalSummary/ClinicalSummariesDataGrid';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { updateRpmTabStatus } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Checkbox, Divider, FormControlLabel, Stack } from '@mui/material';
import type { GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export interface FetchDocumentPreviewProps {
  s3Key: string;
}

const ClinicalSummary = () => {
  const { id: truentityId } = useParams();
  // const { patientInfo } = useContext(PatientDetailContext);
  const { showModal } = useModal();
  const { activeRpmSetupTab } = useRpmSetupStore();
  const { enqueueSnackbar } = useSnackbar();

  const isReadOnly = useMemo(() => activeRpmSetupTab?.isReadOnly || false, [activeRpmSetupTab]);

  const [rpmEncounterSelected, setRpmEncounterSelected] = useState<boolean>(true);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const { rpmSetupTabs, editRpmStatus, hasAvailableAccountCareSummaries } = useRpmSetupStore();
  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    data: rpmAccountSummaryData,
    error: getRpmAccountSummaryError,
    loading: loadingRpmAccountSummary,
    refetch: refetchAccountSummary
  } = useQuery<GetRpmAccountSummaryResponse>(GET_RPM_ACCOUNT_SUMMARY, {
    variables: {
      truentityId
    },
    skip: !truentityId,
    fetchPolicy: 'cache-and-network'
  });

  const [createRpmEncounterReport, { loading: loadingCreateRpmEncounterReport }] = useMutation<CreatePdfRpmEncounterReportResponse>(
    CREATE_PDF_RPM_ENCOUNTER_REPORT,
    {
      variables: {
        truentityId
      }
    }
  );

  const updateRpmStatus = async () => {
    if (hasAvailableAccountCareSummaries) {
      await updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CLINICAL_SUMMARY, editRpmStatus, updateRpmSetup, true);
    }
  };

  const fetchDocumentPreview = useCallback(
    async ({ s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: s3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          fetch(`${documentResponse?.data?.documentPreview?.documentUrl}`)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
              const modal = showModal(PdfDialog, {
                title: 'New Patient Initial Visit Document Preview',
                pdfUrl: url,
                hideDialog: () => {
                  modal.hide();
                }
              });
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getDocumentPreview, showModal]
  );

  const hasRpmCarePlan: boolean = useMemo(
    () => rpmAccountSummaryData?.getRpmAccountSummary?.isCarePlanFilled ?? false,
    [rpmAccountSummaryData]
  );

  const hasRpmEncounterDocument: boolean = useMemo(
    () => (rpmAccountSummaryData?.getRpmAccountSummary?.rpmEncounterS3Key?.length ?? 0) > 0,
    [rpmAccountSummaryData]
  );

  // Hidden with new enrolment flow (Comment On 15 Oct 2024)
  // const reviewBtnTooltipTitle = useMemo((): string => {
  //   if (selectionModel.length === 0) {
  //     return 'Patient Encounter document and at least one Clinical Summary document must be selected';
  //   } else if (reviewAccountCareSummaryLoading) {
  //     return 'Submitting Patient Care Summary for review';
  //   } else if (!hasRpmEncounterDocument) {
  //     return 'Patient Encounter document and at least one Clinical Summary document must be selected';
  //   } else if (!hasAvailableAccountCareSummaries) {
  //     return 'At least one clinical document must be available';
  //   } else if (!rpmEncounterSelected) {
  //     return 'Patient Encounter document must be selected';
  //   } else {
  //     return '';
  //   }
  // }, [selectionModel, reviewAccountCareSummaryLoading, hasRpmCarePlan, hasAvailableAccountCareSummaries, rpmEncounterSelected]);
  //
  // const getReviewLabel = useCallback(() => {
  //   return patientInfo?.rpmApprovalStatus === RpmStatusTypes.IN_REVIEW ||
  //     patientInfo?.rpmApprovalStatus === RpmStatusTypes.SCHEDULE_FOR_REVIEW
  //     ? 'Re-Schedule For Review'
  //     : 'Schedule For Review';
  // }, [patientInfo?.rpmApprovalStatus]);
  //
  // const handleAccountReview = useCallback(
  //   async (reviewType: ReviewSubmitTypeEnum) => {
  //     if (selectionModel.length === 0) {
  //       enqueueSnackbar('Please select at least one account care summary to submit for review', { variant: 'error' });
  //       return;
  //     }
  //
  //     try {
  //       const response = await reviewAccountCareSummary({
  //         variables: {
  //           truentityId,
  //           accountCareSummaryIds: selectionModel,
  //           isRpmEncounterSubmitted: rpmEncounterSelected,
  //           reviewSubmitType: reviewType
  //         }
  //       });
  //
  //       if (response?.data?.reviewAccountCareSummary?.status === 'Success') {
  //         enqueueSnackbar('Successfully submitted account for review', { variant: 'success' });
  //         setReloadPatientInfo(true);
  //         setShouldReFetchAccountCareSummaries(true);
  //         if (!isAutoEnrollmentEligible()) {
  //           navigate('/remote-patient-monitoring/provider-approvals');
  //         } else {
  //           triggerAutoEnrollment();
  //         }
  //       } else if (response?.errors && response?.errors?.length > 0) {
  //         enqueueSnackbar(response?.errors[0]?.message, { variant: 'error' });
  //       } else {
  //         enqueueSnackbar('Failed to submit account care summary for review', { variant: 'error' });
  //       }
  //     } catch (err) {
  //       enqueueSnackbar('Failed to submit account care summary for review', { variant: 'error' });
  //     }
  //   },
  //   [
  //     selectionModel,
  //     enqueueSnackbar,
  //     reviewAccountCareSummary,
  //     truentityId,
  //     rpmEncounterSelected,
  //     setReloadPatientInfo,
  //     setShouldReFetchAccountCareSummaries,
  //     isAutoEnrollmentEligible,
  //     navigate,
  //     triggerAutoEnrollment
  //   ]
  // );

  const regenerateRpmEncounterReport = useCallback(async () => {
    try {
      const rpmEncounterReportResponse = await createRpmEncounterReport();

      if (rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.status === 'Success') {
        enqueueSnackbar('Patient Initial Visit Document regenerated successfully', { variant: 'success' });
        refetchAccountSummary().catch(err => console.error(err));
      } else if (
        rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.errors &&
        rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.errors?.length > 0
      ) {
        enqueueSnackbar(rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.errors[0], { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to generate Patient Initial Visit Document. Please try again later.', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Failed to generate Patient Initial Visit Document. Please try again later.', { variant: 'error' });
      console.error(err);
    }
  }, [createRpmEncounterReport]);

  const loadClinicalSummaryAlert = () => {
    if (loadingRpmAccountSummary) {
      return <Alert status="info" description="Loading clinical summary documents..." />;
    }
    if (!hasAvailableAccountCareSummaries) {
      return <Alert status="info" description="At least one clinical document must be available before enrolling a Patient" />;
    }
    if (!hasRpmCarePlan) {
      return <Alert status="info" description="Care Plan Document must be filled before approving a Patient" />;
    }
    if (!hasRpmEncounterDocument) {
      return (
        <Alert
          status="info"
          description="New Patient Initial Visit Document must be generated before enrolling a Patient"
          hidden={hasRpmEncounterDocument}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (hasAvailableAccountCareSummaries) {
      updateRpmStatus();
    }
  }, [hasAvailableAccountCareSummaries]);

  useEffect(() => {
    if (getRpmAccountSummaryError) {
      enqueueSnackbar(getRpmAccountSummaryError?.message ?? 'Could not get Patient summary', {
        variant: 'error'
      });
    }
  }, [getRpmAccountSummaryError]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center" marginY={1}>
          <H3>Clinical Summary</H3>
          {/*<Stack p={1} direction={'row'} alignItems={'center'} spacing={1}>*/}
          {/*  <Tooltip title={reviewBtnTooltipTitle} placement={'top'}>*/}
          {/*    <Box>*/}
          {/*      <MuiMenu*/}
          {/*        variant="contained"*/}
          {/*        disabled={*/}
          {/*          selectionModel.length === 0 ||*/}
          {/*          reviewAccountCareSummaryLoading ||*/}
          {/*          !hasAvailableAccountCareSummaries ||*/}
          {/*          !hasRpmEncounterDocument ||*/}
          {/*          !hasRpmCarePlan ||*/}
          {/*          !rpmEncounterSelected*/}
          {/*        }*/}
          {/*        label="Review"*/}
          {/*        options={[*/}
          {/*          // {*/}
          {/*          //   label: patientInfo?.rpmApprovalStatus === RpmStatusTypes.READY_FOR_REVIEW ? 'Re-Add For Review' : 'Add For Review',*/}
          {/*          //   disabled: patientInfo?.rpmApprovalStatus === RpmStatusTypes.IN_REVIEW,*/}
          {/*          //   onAction: () => hanadleAccountReview('ADD_FOR_REVIEW')*/}
          {/*          // },*/}
          {/*          {*/}
          {/*            label: getReviewLabel(),*/}
          {/*            onAction: () => handleAccountReview(ReviewSubmitTypeEnum.SCHEDULE_FOR_REVIEW)*/}
          {/*          }*/}
          {/*        ]}*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*  </Tooltip>*/}
          {/*</Stack>*/}
        </Stack>
        <Stack spacing={4} sx={{ height: 'auto', minHeight: '200px', width: '100%' }}>
          <Box>
            {loadClinicalSummaryAlert()}
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%',
                height: 'auto',
                my: 2,
                p: 1,
                border: '1px solid',
                borderColor: theme => theme.palette.divider,
                backgroundColor: color.grey50,
                borderRadius: 1
              }}
            >
              <Box flex={'1'} display={'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center">
                {hasRpmEncounterDocument ? (
                  <>
                    <FormControlLabel
                      label={''}
                      control={
                        <Checkbox
                          value={rpmEncounterSelected}
                          onChange={e => {
                            setRpmEncounterSelected(e.target.checked);
                          }}
                          defaultChecked
                        />
                      }
                    />
                    <Box
                      sx={{ width: '66px' }}
                      component={'img'}
                      src={'https://truentity-general.s3.amazonaws.com/Indycare-CarePlan-Thumbnail.png'}
                    />
                    <H5
                      sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: color.black100,
                        marginLeft: 2
                      }}
                    >
                      Preview New Patient Initial Visit Document
                    </H5>
                  </>
                ) : (
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: color.black100,
                      marginLeft: 2
                    }}
                  >
                    New Patient Initial Visit Document
                  </H5>
                )}
              </Box>
              <Stack flexDirection="row" justifyContent="flex-end" columnGap={2}>
                <Button
                  variant="outlined"
                  label={hasRpmEncounterDocument ? 'Regenerate' : 'Generate'}
                  disabled={!hasRpmCarePlan || isReadOnly}
                  isLoading={loadingCreateRpmEncounterReport}
                  onClick={regenerateRpmEncounterReport}
                />

                {hasRpmEncounterDocument && (
                  <Button
                    sx={{ width: '86px' }}
                    label="View"
                    disabled={loadingCreateRpmEncounterReport}
                    onClick={() => {
                      if (rpmAccountSummaryData?.getRpmAccountSummary?.rpmEncounterS3Key) {
                        fetchDocumentPreview({
                          s3Key: rpmAccountSummaryData.getRpmAccountSummary.rpmEncounterS3Key
                        });
                      }
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Box>
          <Divider />
          <ClinicalSummariesDataGrid selectionModel={selectionModel} setSelectionModel={setSelectionModel} isReadOnly={isReadOnly} />
        </Stack>
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default ClinicalSummary;
