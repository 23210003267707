import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { GET_CLIENT_ORGANIZATIONS, GET_CLIENT_STORES_BY_ORG, GET_CLIENT_STORES_STANDARD } from '@/graphql/administration';
import { formatDateTimeIgnoreTZ, toLocalDate } from '@/util/format';
import { unknown } from '@/util/string';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Grid, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';

const GET_ENCOUNTERS_BY_FILTER = gql`
  query GetEncountersByFilterQuery($filterOptions: EncounterFilterOptionsInput!) {
    encountersByFilter(filterOptions: $filterOptions) {
      encounters {
        id
        title
        type
        subType
        calledOn
        notes
        disposition
        createdAt
        relyingPartyAdmin {
          user {
            firstName
            lastName
          }
        }
        account {
          truentityId
          user {
            firstName
            lastName
          }
        }
        originatingClientOrg {
          id
          name
        }
        originatingClientStore {
          id
          name
        }
      }
    }
  }
`;

type Encounter = {
  id: string;
  title: string;
  calledOn: Date;
  notes: string;
  disposition: string;
  relyingPartyAdmin: string;
  account: string;
};

type FilterOptions = {
  organization?: string;
  orgStore?: string;
  startDate?: Date;
  endDate?: Date;
};

//TODO:  Unable to add server pagination, api doesnt support it
//Desperately needs it, returns all records atm and severely hampers performance
const Encounters = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [encounters, setEncounters] = useState<Encounter[]>([]);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [organizations, setOrganizations] = useState<any>([{}]);
  const [stores, setStores] = useState<any>([{}]);
  const [dataLoading, setDataLoading] = useState(false);

  const [getEncounters, { loading: encountersLoading, data: encountersData }] = useLazyQuery(GET_ENCOUNTERS_BY_FILTER, {
    notifyOnNetworkStatusChange: true
  });

  const callGetEncounters = (filterOptions: FilterOptions) => {
    getEncounters({
      variables: {
        filterOptions
      }
    });
  };

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery(GET_CLIENT_ORGANIZATIONS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: clientStoresDataLoading,
    data: clientStoresData,
    called: clientStoresDataCalled
  } = useQuery(GET_CLIENT_STORES_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: storesByOrgDataLoading,
    data: storesByOrgData,
    called: storesByOrgDataCalled,
    refetch: storesByOrgDataRefetch
  } = useQuery(GET_CLIENT_STORES_BY_ORG, {
    variables: {
      clientOrgId: selectedOrg,
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      const orgs = [{}];
      organizationData.clientOrganizations.clientOrganizations.map(n => orgs.push({ label: n.name, value: n.id }));
      setOrganizations(orgs);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    const clientStores = [{}];
    if (selectedOrg != '' && selectedOrg != 'all') {
      if (storesByOrgDataCalled && !storesByOrgDataLoading && storesByOrgData && storesByOrgData?.clientStores?.clientStores) {
        setStoreOptions(storesByOrgData.clientStores.clientStores);
      } else {
        fetchStoreOptions(selectedOrg);
      }
    } else {
      if (clientStoresDataCalled && !clientStoresDataLoading && clientStoresData) {
        clientStores.pop();
        clientStoresData.clientStores.clientStores.map(n => clientStores.push({ label: n.name, value: n.id }));
      }
      setStores(clientStores);
    }

    setSelectedStore('');
  }, [selectedOrg, clientStoresDataCalled, clientStoresData, clientStoresDataLoading, storesByOrgData]);

  const setStoreOptions = storeList => {
    const clientStores = [{}];
    if (storeList) {
      clientStores.pop();
      storeList.map(n => clientStores.push({ label: n.name, value: n.id }));
    }
    setStores(clientStores);
  };

  const fetchStoreOptions = useCallback(async selectedOrg => {
    const response = (
      await storesByOrgDataRefetch({
        clientOrgId: selectedOrg,
        pageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE
      })
    ).data.clientStoresByOrg?.clientStores;
    if (response && response.length > 0) {
      setStoreOptions(response);
    } else {
      setStoreOptions('');
    }
  }, []);

  const runReport = () => {
    setDataLoading(true);
    callGetEncounters({
      organization: selectedOrg?.replace('all', ''),
      orgStore: selectedStore?.replace('all', ''),
      startDate: toLocalDate(startDate),
      endDate: toLocalDate(endDate)
    });
  };

  useEffect(() => {
    if (encountersData && encountersData.encountersByFilter?.encounters) {
      setEncounters(encountersData.encountersByFilter.encounters);
      setRowCount(encountersData.encountersByFilter.encounters.length);
      setDataLoading(false);
    }
  }, [encountersData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: 'Encounters',
            delimiter: ',',
            utf8WithBom: true
          }}
        />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'account',
        headerName: 'Patient',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => params.row.account.user.firstName + ' ' + params.row.account.user.lastName,
        renderCell: params => {
          return (
            <a href={`providers/${params.row.account.truentityId}/manage`}>
              {params.row.account.user.firstName + ' ' + params.row.account.user.lastName}
            </a>
          );
        }
      },
      {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        flex: 1,
        align: 'left'
      },
      {
        field: 'relyingPartyAdmin',
        headerName: 'Called By',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => params.row.relyingPartyAdmin.user.firstName + ' ' + params.row.relyingPartyAdmin.user.lastName
      },
      {
        field: 'originatingClientOrg',
        headerName: 'Originating Client Org',
        sortable: true,
        flex: 1,
        minWidth: 130,
        align: 'left',
        valueGetter: params => params.row?.originatingClientOrg?.name || unknown()
      },
      {
        field: 'originatingClientStore',
        headerName: 'Originating Client Store',
        sortable: true,
        flex: 1,
        minWidth: 130,
        align: 'left',
        valueGetter: params => params.row?.originatingClientStore?.name || unknown()
      },
      {
        field: 'calledOn',
        headerName: 'Called On',
        sortable: true,
        renderCell: params => {
          return (
            <a href={`patients/${params.row.account.truentityId}/details/medications/encounters/view/${params.row.id}`}>
              {formatDateTimeIgnoreTZ(params.row.calledOn)}
            </a>
          );
        },
        flex: 1,
        align: 'left'
      }
    ],
    []
  );

  return (
    <Stack spacing={2} paddingBottom={10}>
      <Grid container key={'filter'} alignItems="center" style={{ marginBottom: '10px' }}>
        <Grid item xs={12}>
          {/* <EncounterFilter filter={updateFilter} /> */}
          <Stack direction="row">
            <SelectList
              fullWidth={false}
              formControlProps={{ sx: { width: 400, marginRight: 2 } }}
              label="Organization"
              options={organizations}
              value={selectedOrg}
              displayEmpty={true}
              placeholder="Select an option..."
              onChange={({ target }) => setSelectedOrg(target.value as string)}
            />

            {stores.length > 0 && stores[0].value && (
              <SelectList
                fullWidth={false}
                formControlProps={{ sx: { width: 400 } }}
                label="Location"
                options={stores}
                value={selectedStore}
                displayEmpty={true}
                placeholder="Select an option..."
                onChange={({ target }) => setSelectedStore(target.value as string)}
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent={'flex-start'}>
            <TruentityDatePicker
              label="Start Date"
              fullWidth={false}
              sx={{ width: 400, marginRight: 2 }}
              value={startDate}
              format="MMM DD, YYYY"
              onChange={date => setStartDate(date as Date)}
            />
            <TruentityDatePicker
              label="End Date"
              fullWidth={false}
              sx={{ width: 400 }}
              value={endDate}
              format="MMM DD, YYYY"
              onChange={date => setEndDate(date as Date)}
            />
            <Grid item xs={1} sx={{ m: '0.6rem' }}>
              <Button label={'Run Report'} type="submit" size="large" onClick={() => runReport()} />
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Paper component={Stack} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-encounters'}
            slots={{
              toolbar: CustomToolbar
            }}
            autoHeight
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            rows={encounters}
            rowCount={rowCountState}
            columns={columns}
            paginationMode="client"
            loading={encountersLoading || dataLoading}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Encounters;
