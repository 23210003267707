import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { GetRpmProviderStatisticsResponse, ProviderStatisticsDataType } from '@/graphql/administration';
import { GET_RPM_PROVIDER_STATISTICS } from '@/graphql/administration';
import { color } from '@/styles/assets/colors';
import { useLazyQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

const RpmProviders = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);

  const [rpmProvidersTableData, setRpmProvidersTableData] = useState<ProviderStatisticsDataType[]>([]);

  const [getRpmProviderStatisticsQuery, { data: getRpmProviderStatisticsQueryData, loading: getRpmProviderStatisticsQueryLoading }] =
    useLazyQuery<GetRpmProviderStatisticsResponse>(GET_RPM_PROVIDER_STATISTICS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (getRpmProviderStatisticsQueryData?.getRpmProviderStatistics) {
      setRpmProvidersTableData(getRpmProviderStatisticsQueryData?.getRpmProviderStatistics?.providerStatistics);
      setRowCount(getRpmProviderStatisticsQueryData?.getRpmProviderStatistics?.meta.totalCount ?? 0);
    }
  }, [getRpmProviderStatisticsQueryData]);

  const callGetRpmProviderStatisticsQuery = async () => {
    try {
      const variables = {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1
      };

      await getRpmProviderStatisticsQuery({ variables });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve data', { variant: 'error' });
    }
  };

  useEffect(() => {
    callGetRpmProviderStatisticsQuery().catch(console.error);
  }, [currentPage]);

  const renderProviderName = (cellValues: any) => {
    const { individualFirstName: firstName, individualLastName: lastName } = cellValues.row.provider;

    return (
      <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent="start">
        {`${firstName} ${lastName}`}
      </Stack>
    );
  };

  const renderPatientReviewed = (cellValues: any) => {
    const { totalCount, approvedCount, rejectedCount, skippedCount } = cellValues.row;

    return (
      <Stack spacing={2} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent="center">
        {`${approvedCount}, ${rejectedCount}, ${skippedCount} (${totalCount})`}
      </Stack>
    );
  };

  const columns = useMemo<GridColDef<ProviderStatisticsDataType>[]>(
    () => [
      {
        field: 'monthYear',
        headerName: 'Month Year',
        flex: 1,
        valueGetter: params => params.row.monthYear,
        sortable: false
      },
      {
        field: 'providerName',
        headerName: 'Provider Name',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        renderCell: renderProviderName,
        sortable: false
      },
      {
        field: 'rpmReportsReviewed',
        headerName: 'No. of RPM Reports Reviewed',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        valueGetter: params => params.row.reportsReviewedCount
      },
      {
        field: 'signedOff',
        headerName: 'No. of RPM Patients Signed Off',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        valueGetter: params => params.row.signedOffCount
      },
      {
        field: 'patientReviewed',
        headerName: 'No. of RPM Patients Approved, Rejected, Incomplete (Total)',
        flex: 2,
        headerAlign: 'center',
        sortable: false,
        renderCell: renderPatientReviewed
      }
    ],
    []
  );

  const getCustomRowId = useCallback((row: ProviderStatisticsDataType) => {
    return `${row.provider.id}-${row.monthYear}}`;
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '16px',
        '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '24px 16px' }
      }}
    >
      <TruentityDataGrid
        name={'rpm-provider-statistics'}
        autoHeight
        getRowHeight={() => 'auto'}
        rows={rpmProvidersTableData}
        rowCount={rowCount || 0}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
        disableRowSelectionOnClick={true}
        disableMultipleRowSelection={true}
        hideFooterSelectedRowCount
        loading={getRpmProviderStatisticsQueryLoading}
        paginationMode="server"
        columns={columns}
        getRowId={getCustomRowId}
        autoPageSize={false}
        sx={{
          '& .MuiDataGrid-cell': {
            paddingY: 1
          },
          // wrap data grid header text
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
          }
        }}
      />
    </Stack>
  );
};

export default RpmProviders;
