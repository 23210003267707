import Button from '@/components/Button';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { SendMeetUrlTextMessage } from '@/graphql/rpmWorkflow';
import { SEND_MEET_URL_TEXT_MESSAGE } from '@/graphql/rpmWorkflow';
import type { PatientsData } from '@/routes/Patients/patients';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getMeetUrlUsername } from '@/util/rpm';
import { useMutation, useReactiveVar } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { DialogContent, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

interface PatientSendVisitUrlDialogProps extends BaseDialogProps {
  hideDialog: () => void;
  patientInfo: PatientsData | null;
  meetUrl: string;
  onSend: () => Promise<void>;
}

type FormValues = {
  phoneNumber: string;
};

const defaultValues: FormValues = {
  phoneNumber: ''
};

const PatientSendVisitUrlDialog: React.FC<PatientSendVisitUrlDialogProps> = ({
  title,
  patientInfo,
  meetUrl,
  hideDialog,
  onSend,
  ...props
}) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues });

  const [sendMeetUrlTextMessage, { loading: sendMeetUrlTextMessageLoading }] =
    useMutation<SendMeetUrlTextMessage>(SEND_MEET_URL_TEXT_MESSAGE);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const response = await sendMeetUrlTextMessage({
        variables: {
          toPhoneNumber: values.phoneNumber,
          accountUsername: getMeetUrlUsername({
            firstName: patientInfo?.user?.firstName,
            lastName: patientInfo?.user?.lastName,
            relyingPartyName: currentUser?.relyingParty?.name
          }),
          meetUrl: meetUrl
        }
      });

      if (response?.data?.sendMeetUrlTextMessage?.status === 'Success') {
        enqueueSnackbar('Successfully sent Meeting URL as text message.', {
          variant: 'success'
        });
        await onSend();
      } else {
        const errorMessage = response?.errors?.[0]?.message || 'Failed to send the meeting URL as a text message.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Failed to send the meeting URL as a text message.', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    const adminPhoneNumber = currentUser?.phone;
    if (adminPhoneNumber) {
      reset({
        phoneNumber: adminPhoneNumber
      });
    }
  }, [currentUser, reset]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack flexDirection="column" justifyContent="flex-start" alignItems="stretch">
          <Stack component={'form'} flexDirection="row" justifyContent="flex-start" alignItems="stretch" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{ required: 'Please enter a valid Contact Number' }}
              render={({ field: { onChange, value } }) => (
                <TruentityPhoneNumber required editable={true} label={'Phone'} value={value} onChange={onChange} />
              )}
            />
            <Button
              startIcon={<SendIcon />}
              sx={{
                margin: 1,
                textTransform: 'none'
              }}
              size="medium"
              isLoading={sendMeetUrlTextMessageLoading}
              type="submit"
              variant="contained"
            >
              Send
            </Button>
          </Stack>
        </Stack>
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="text" type="button" onClick={hideDialog}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default PatientSendVisitUrlDialog;
