import { Body1 } from '@/components/Typography';
import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = BoxProps & {
  title: string;
};

const WidgetContainer = ({ title, children, ...props }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: 0,
        borderRadius: '12px',
        padding: 2,
        backgroundColor: 'white',
        position: 'relative',
        height: '100%'
      }}
      {...props}
    >
      <Body1
        sx={{
          position: 'absolute',
          left: '12px',
          backgroundColor: theme.palette.common.white,
          padding: '0 8px'
        }}
      >
        {title}
      </Body1>
      <Box
        sx={{
          paddingTop: '30px'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default WidgetContainer;
