import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { Body1 } from '../Typography';

type Props = {
  filters: string[];
  handleRemoveFilter: (filter: string) => void;
};

const FilterPanel = ({ filters, handleRemoveFilter, ...props }: Props) => {
  return (
    <Stack direction="row" spacing={1} {...props}>
      {filters.map((filter, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          sx={{
            bgcolor: 'white',
            py: 0.5,
            px: 1,
            borderRadius: 1,
            boxShadow: 1,
            maxWidth: 300
          }}
        >
          <Body1 sx={{ flexGrow: 1 }}>{filter}</Body1>
          <IconButton onClick={() => handleRemoveFilter(filter)} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
};

export default FilterPanel;
