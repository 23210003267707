import SideMenuContext from '@/context/sideMenuContext';
import { GetSystemOptionsResponse, GET_SYSTEM_OPTIONS } from '@/graphql/administration';
import { LoadMessageContextResponse, LOAD_MESSAGE_CONTEXT, SEND_COPILOT_MESSAGE } from '@/graphql/coPilot';
import { SystemOptionKeyEnum } from '@/types/administration';
import { SenderType } from '@/types/coPilot';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Card, CardActions, CardHeader, CircularProgress, Stack, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import Button from '../Button';
import MuiAccordion from '../MuiAccordion';
import { Small } from '../Typography';

type Props = {
  title: string;
  onClose: () => void;
};

const CoPilotWindow = ({ title, onClose }: Props) => {
  const { id } = useParams();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const messages = useCopilotMessageStore(state => state.messages);
  const addMessage = useCopilotMessageStore(state => state.addMessage);

  const [input, setInput] = useState<string>('');
  const [contextLoadError, setContextLoadError] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const [isWindowExpanded, setIsWindowExpanded] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const { drawerOpen } = useContext(SideMenuContext);

  const [loadMessageContext] = useMutation<LoadMessageContextResponse>(LOAD_MESSAGE_CONTEXT, {
    variables: {
      truentityId: id,
      relyingPartyId: currentUser?.relyingParty?.id
    },
    onError: error => {
      console.error('Error loading message context:', error.message);
      setContextLoadError(true);
    }
  });

  const [sendMessage] = useMutation(SEND_COPILOT_MESSAGE, {
    onCompleted: () => {
      setSendMessageLoading(false);
    },
    onError: error => {
      console.error('Send Message Error:', error.message);
      addMessage({
        sender: SenderType.BOT,
        text: 'There was an error sending your message. Please try again later. :disappointed:'
      });
      setSendMessageLoading(false);
    }
  });

  const {
    data: systemOptionsData,
    error: systemOptionsError,
    loading
  } = useQuery<GetSystemOptionsResponse>(GET_SYSTEM_OPTIONS, {
    variables: {
      optionKey: SystemOptionKeyEnum.RPM_CO_PILOT_SUGGESTIONS
    },
    fetchPolicy: 'cache-first'
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (systemOptionsError) {
      enqueueSnackbar('Could not fetch Suggestions', {
        variant: 'error'
      });
    }
  }, [systemOptionsError]);

  const transformedOptions = useMemo(() => {
    if (!systemOptionsData?.getSystemOptions?.options) return [];

    return systemOptionsData.getSystemOptions.options.map(option => ({
      category: option.value,
      suggestions: option.nestedOptions?.map(nestedOption => ({
        title: nestedOption.value
      }))
    }));
  }, [systemOptionsData]);

  const handleSend = () => {
    if (!input.trim()) {
      return;
    }

    addMessage({ sender: SenderType.USER, text: input });
    setInput('');
    setSendMessageLoading(true);

    sendMessage({
      variables: {
        truentityId: id,
        relyingPartyId: currentUser?.relyingParty.id,
        message: input
      }
    }).catch(error => {
      console.error('Error caught in handleSend:', error.message);
    });
  };

  const handleClose = () => {
    onClose();
  };

  const onSuggestionSelected = suggestion => {
    setInput(suggestion.title);
  };

  const handleAccordionChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    loadMessageContext();
  }, [loadMessageContext]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleExpand = () => {
    isWindowExpanded ? setIsWindowExpanded(false) : setIsWindowExpanded(true);
  };

  const accordionOptions = transformedOptions.map((option, index) => ({
    label: option.category,
    defaultExpand: false,
    content: (
      <Stack rowGap={1}>
        {option.suggestions?.map((suggestion, suggestionIndex) => (
          <Button
            key={suggestionIndex}
            variant="outlined"
            color="primary"
            onClick={() => onSuggestionSelected(suggestion)}
            sx={{ fontSize: '0.875rem', flexShrink: 0, textTransform: 'capitalize' }}
          >
            {suggestion.title}
          </Button>
        ))}
      </Stack>
    ),
    expanded: expanded === `category${index}`,
    onChange: handleAccordionChange(`category${index}`)
  }));

  const drawerWidth = drawerOpen ? '360px' : '72px';
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      position={isWindowExpanded ? { x: 0, y: 0 } : undefined}
      disabled={isWindowExpanded}
    >
      <Card
        elevation={14}
        sx={{
          zIndex: theme.zIndex.drawer + 20,
          position: 'fixed',
          bottom: isWindowExpanded ? 0 : 10,
          right: isWindowExpanded ? 0 : 40,
          width: isWindowExpanded ? `calc(100% - ${drawerWidth})` : 500,
          height: isWindowExpanded ? `calc(100% - 85px)` : 590,
          background: '#fff',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CardHeader
          title={title}
          id="draggable-dialog-title"
          action={
            <Stack direction="row">
              <IconButton aria-label="Close" onClick={handleExpand}>
                {isWindowExpanded ? <CloseFullscreen /> : <OpenInFull />}
              </IconButton>
              <IconButton aria-label="Close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          }
          sx={{ p: 2, cursor: isWindowExpanded ? 'auto' : 'move', borderBottom: 1, borderColor: theme => theme.palette.grey[300] }}
        />
        <CardContent
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            flexGrow: 1,
            padding: 1,
            gap: 1.5,
            overflowY: 'auto'
          }}
        >
          {messages.length > 0 ? (
            messages.map((msg, index) => (
              <Stack key={index} direction={msg.sender === SenderType.BOT ? 'row' : 'row-reverse'} spacing={1}>
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: msg.sender === SenderType.BOT ? theme.palette.secondary.dark : theme.palette.primary.dark,
                    color: '#fff',
                    maxWidth: '70%',
                    position: 'relative',
                    overflow: 'visible'
                  }}
                >
                  {sendMessageLoading && msg.sender === SenderType.USER && index === messages.length - 1 && (
                    <CircularProgress
                      size={15}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '-20px',
                        marginTop: '-7.5px',
                        zIndex: 1
                      }}
                    />
                  )}
                  <Markdown>{msg.text}</Markdown>
                </Box>
              </Stack>
            ))
          ) : loading ? (
            <Stack justifyContent="center" direction="row" sx={{ width: '100%', mt: 1 }}>
              <CircularProgress />
            </Stack>
          ) : (
            accordionOptions?.length > 0 && (
              <>
                <Small sx={{ fontWeight: 500, color: theme => theme.palette.grey[500] }}>Suggestions</Small>
                <MuiAccordion
                  sx={{
                    borderRadius: 2,
                    '&:before': {
                      display: 'none'
                    }
                  }}
                  options={accordionOptions}
                  disableGutters
                />
              </>
            )
          )}
          <div ref={messagesEndRef} />
        </CardContent>
        <CardActions sx={{ p: 1 }}>
          <Stack
            direction="row"
            component="form"
            onSubmit={e => {
              e.preventDefault();
              handleSend();
            }}
            alignItems="center"
            flexGrow={1}
          >
            <TextField
              value={input}
              onChange={e => setInput(e.target.value)}
              placeholder={contextLoadError ? 'Error loading context. Try again later.' : 'Type a message...'}
              variant="outlined"
              fullWidth
              sx={{ marginRight: '8px' }}
              disabled={contextLoadError || sendMessageLoading}
            />
            <IconButton type="submit" disabled={!input.trim() || sendMessageLoading || contextLoadError}>
              <SendIcon />
            </IconButton>
          </Stack>
        </CardActions>
      </Card>
    </Draggable>
  );
};

export default CoPilotWindow;
