import ChipColumn from '@/components/ChipLabel/ChipColumn';
import { Body1, Caption, H2, H4, Label } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import RpmDevicesList from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmDevices/RpmDevicesList';
import RpmConditionsSelect from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/RpmConditionsSelect';
import { theme } from '@/styles/mui-theme';
import { RpmFollowupConsultStatusType } from '@/types/remotePatientMonitoring';
import { formatDate, formatDateAndTime } from '@/util/format';
import { formatRpmStatusString, mapRpmSignOffStatus } from '@/util/rpm';
import { convertToTitleCase } from '@/util/string';
import { useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { Box, Stack } from '@mui/material';
import { useContext, type ReactNode } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  children?: ReactNode;
};

const RpmFollowupConsultToolbar = ({ children }: Props) => {
  const { patientInfo } = useContext(PatientDetailContext);
  const { rpmFollowupConsultId, id } = useParams();
  const { rpmFollowupConsult } = useRpmFollowupConsultStore();

  return (
    <Stack
      sx={{ background: theme.palette.background.paper }}
      padding={4}
      borderRadius={4}
      spacing={2}
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <H2>Patient Follow-up</H2>
          <H4>{rpmFollowupConsult?.consultAt ? formatDate(rpmFollowupConsult?.consultAt) : 'Unavailable'}</H4>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <ChipColumn
            label={<Label>Status</Label>}
            value={<Body1>{convertToTitleCase(rpmFollowupConsult?.status ?? 'Unavailable')}</Body1>}
            secondaryValue={
              rpmFollowupConsult?.status === RpmFollowupConsultStatusType.COMPLETED ? (
                <Box
                  sx={{
                    margin: 1
                  }}
                >
                  <Caption textTransform="none" color="primary">
                    {`Completed on ${formatDateAndTime(rpmFollowupConsult.statusUpdatedAt)}`}
                  </Caption>
                </Box>
              ) : undefined
            }
          />
          {patientInfo?.rpmApprovalStatus && (
            <ChipColumn
              label={<Label>Approval Status</Label>}
              value={<Body1>{formatRpmStatusString(patientInfo?.rpmApprovalStatus)}</Body1>}
              secondaryValue={
                patientInfo?.rpmApprovalStatus ? (
                  <Box
                    sx={{
                      margin: 1
                    }}
                  >
                    <Caption textTransform="none" color="primary">
                      {mapRpmSignOffStatus({
                        rpmSignOffStatus: patientInfo?.rpmSignOffStatus
                      }) ?? ''}
                    </Caption>
                  </Box>
                ) : undefined
              }
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" width="100%">
        <Stack gap={1}>
          <RpmConditionsSelect rpmFollowupConsultId={rpmFollowupConsultId} />
          <RpmDevicesList truentityId={id} />
        </Stack>
        <Stack spacing={2} alignSelf="baseline">
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RpmFollowupConsultToolbar;
