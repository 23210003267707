import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { MedicalServices } from '@/types/admin';
import { followUpStatuses, followUpSubTypes, followUpTypes } from '@/util/followUp';
import { Divider, Grid } from '@mui/material';
import type { BaseSyntheticEvent } from 'react';
import { useMemo } from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

export type AddFollowupConsultFormType = {
  followupConsultDate: Date | null;
  followupConsultTime: Date | null;
  calendarFollowupType: string;
  calendarFollowupSubType: string;
  calendarFollowupStatus: string;
  calendarFollowupNote: string;
};

type Props = {
  control: Control<AddFollowupConsultFormType>;
  onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
};

const AddFollowupConsultForm = ({ onSubmit, control }: Props) => {
  const FOLLOW_UP_TYPES: TextSelectOption[] = useMemo(() => followUpTypes(false, true, false, [MedicalServices.RPM]), []);
  const FOLLOW_UP_SUB_TYPES: TextSelectOption[] = useMemo(() => followUpSubTypes(), []);
  const FOLLOW_UP_STATUSES = useMemo(() => followUpStatuses(), []);
  return (
    <Grid component={'form'} onSubmit={onSubmit} container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="followupConsultDate"
          render={({ field: { onChange, value } }) => (
            <TruentityDatePicker
              label="Follow-up Date"
              fullWidth={true}
              TextFieldProps={{ required: true }}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="followupConsultTime"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField
              label="Follow-up Time"
              type="time"
              value={value ?? ''}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300
              }}
              required
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ width: '100%', marginY: 2 }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="calendarFollowupType"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  label={'Follow-up Type'}
                  options={FOLLOW_UP_TYPES}
                  placeholder="Select an option..."
                  value={value}
                  keepOriginalValue
                  onChange={onChange}
                  required={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="calendarFollowupSubType"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id="calendarFollowupSubType"
                  label={'Follow-up Sub Type (Optional)'}
                  options={FOLLOW_UP_SUB_TYPES}
                  placeholder="Select an option..."
                  value={value}
                  keepOriginalValue
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="calendarFollowupStatus"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id="status"
                  label={'Status'}
                  options={FOLLOW_UP_STATUSES}
                  placeholder="Select an Status..."
                  value={value}
                  keepOriginalValue
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="calendarFollowupNote"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField
                  value={value}
                  multiline
                  fullWidth
                  rows={2}
                  inputProps={{ max: 2 }}
                  placeholder="Type your note here"
                  id="note"
                  label="Notes (Optional)"
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddFollowupConsultForm;
