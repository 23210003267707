import type { TextSelectOption } from '@/components/SelectList';
import type { PatientsData } from '@/routes/Patients/patients';
import { color } from '@/styles/assets/colors';
import { Role, ROLE_TYPE_OPTIONS, Titles, TITLE_TYPE_OPTIONS } from '@/types/admin';
import { AdminRoleTypeEnum } from '@/types/graphql';
import type { RpmFollowupConsultType, RpmWorkflowTabType } from '@/types/remotePatientMonitoring';
import { RpmSetupStatusTypes, RpmStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';

export const superAdminAccessibleRoles: string[] = [Titles.SUPER_ADMIN];
export const adminAccessibleRoles: string[] = [Titles.PHARMACY_DIRECTOR, Titles.PHARMACY_MANAGER, Titles.CARE_ADMINISTRATOR];
export const pharmacistRoleTypes = [Role.PHARMACIST, Role.PHARMACIST_ADMIN, Role.ADT_ADMIN];

export const getCurrentAdminsUsableTitles = (useRole: string | undefined | null, isSelfProfileEditing?: boolean): TextSelectOption[] => {
  if (!useRole) return [];

  const isSuperAdmin = useRole === Role.SUPER;
  const isPharmacistAdmin = useRole === Role.PHARMACIST_ADMIN;
  const isPharmacist = useRole === Role.PHARMACIST;

  const styledOptions = TITLE_TYPE_OPTIONS.map(option => {
    const isRed = adminAccessibleRoles.includes(option.value);
    return {
      label: option.label,
      value: option.value,
      ...(isRed && { color: color.truentitySalmon[800] })
    };
  });
  if (isSuperAdmin) {
    return styledOptions;
  }
  if (isPharmacistAdmin && isSelfProfileEditing) {
    return styledOptions.filter(option => adminAccessibleRoles.includes(option.value));
  }
  if (isPharmacist || !(isPharmacistAdmin && isSelfProfileEditing)) {
    return styledOptions.filter(option => !adminAccessibleRoles.includes(option.value));
  }

  return styledOptions;
};

export const getRoleTypeUsingTitle = (title: string): AdminRoleTypeEnum => {
  if (superAdminAccessibleRoles.includes(title)) return AdminRoleTypeEnum.SUPER_ADMIN;
  if (adminAccessibleRoles.includes(title)) return AdminRoleTypeEnum.PHARMACIST_ADMIN;
  return AdminRoleTypeEnum.PHARMACIST;
};

export const mapRolesToText = (role: string) => {
  return [...ROLE_TYPE_OPTIONS, { label: 'Super Admin', value: Role.SUPER }].find(option => option.value === role)?.label ?? 'N/A';
};

export const mapTitlesToText = (title: string): string => {
  return (
    [...TITLE_TYPE_OPTIONS, { label: 'Super Admin', value: Titles.SUPER_ADMIN }].find(option => option.value === title)?.label ?? 'N/A'
  );
};

export const convertRoleToRoleType = (role: string): AdminRoleTypeEnum => {
  switch (role) {
    case Role.SUPER:
      return AdminRoleTypeEnum.SUPER_ADMIN;
    case Role.PHARMACIST:
      return AdminRoleTypeEnum.PHARMACIST;
    case Role.PHARMACIST_ADMIN:
      return AdminRoleTypeEnum.PHARMACIST_ADMIN;
    case Role.ADT_ADMIN:
      return AdminRoleTypeEnum.ADT_ADMIN;
    default:
      return AdminRoleTypeEnum.SUPER_ADMIN;
  }
};

export const checkTabDisability = (
  roleType: string | null,
  type: string,
  patientInfo: PatientsData | null,
  tabs: RpmWorkflowTabType[],
  rpmFollowupConsult: RpmFollowupConsultType | null
): boolean => {
  const isCandidatePatient = patientInfo?.rpmStatus
    ? [RpmStatusTypes.IS_CANDIDATE, RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT].includes(patientInfo.rpmStatus as RpmStatusTypes)
    : false;
  if (!rpmFollowupConsult && !isCandidatePatient) return false;
  if (!roleType) return true;

  let effectiveWorkflowTabs = tabs;

  const isTelehealthVisitLaunched = rpmFollowupConsult
    ? rpmFollowupConsult.isTelehealthVisitLaunched
    : patientInfo?.accountSummary?.isTelehealthVisitLaunched ?? false;
  if (isTelehealthVisitLaunched) {
    effectiveWorkflowTabs = tabs.map(workflowTab => ({
      ...workflowTab,
      accessDisableRoleTypes: workflowTab.accessDisableRoleTypes.filter(role => !pharmacistRoleTypes.includes(role))
    }));
  }

  const accessDisableRoleTypes = effectiveWorkflowTabs.find(workflowTab => workflowTab.tabType.toString() === type)?.accessDisableRoleTypes;

  if (!accessDisableRoleTypes) return true;

  return accessDisableRoleTypes.includes(roleType as Role);
};

export const checkTabReadOnly = (
  roleType: string | null,
  type: string,
  patientInfo: PatientsData | null,
  workflowTabs: RpmWorkflowTabType[],
  rpmFollowupConsult: RpmFollowupConsultType | null
): boolean => {
  if (!roleType) return true;

  const isCandidatePatient = patientInfo?.rpmStatus
    ? [RpmStatusTypes.IS_CANDIDATE, RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT].includes(patientInfo.rpmStatus as RpmStatusTypes)
    : false;
  if (!rpmFollowupConsult && !isCandidatePatient) return false;

  let effectiveWorkflowTabs = workflowTabs;

  const isTelehealthVisitLaunched = rpmFollowupConsult
    ? rpmFollowupConsult.isTelehealthVisitLaunched
    : patientInfo?.accountSummary?.isTelehealthVisitLaunched ?? false;
  if (isTelehealthVisitLaunched) {
    effectiveWorkflowTabs = workflowTabs.map(workflowTab => ({
      ...workflowTab,
      tabReadOnlyRoleTypes:
        workflowTab.tabType !== RpmWorkflowTab.CARE_PLAN
          ? workflowTab.tabReadOnlyRoleTypes.filter(role => !pharmacistRoleTypes.includes(role))
          : workflowTab.tabReadOnlyRoleTypes
    }));
  }

  const tabReadOnlyRoleTypes = effectiveWorkflowTabs.find(workflowTab => workflowTab.tabType.toString() === type)?.tabReadOnlyRoleTypes;
  if (!tabReadOnlyRoleTypes) return true;

  return tabReadOnlyRoleTypes.includes(roleType as Role);
};

export const checkTabProgressDisabled = (
  roleType: string | null,
  tabType: string,
  patientInfo: PatientsData | null,
  workflowTabs: RpmWorkflowTabType[],
  rpmFollowupConsult: RpmFollowupConsultType | null
): boolean => {
  const isFollowupCompleted = rpmFollowupConsult ? rpmFollowupConsult.status === RpmSetupStatusTypes.COMPLETED : false;
  const isProgressDisabled = workflowTabs.find(workflowTab => workflowTab.tabType.toString() === tabType)?.disableProgress ?? false;
  return isFollowupCompleted || isProgressDisabled || checkTabReadOnly(roleType, tabType, patientInfo, workflowTabs, rpmFollowupConsult);
};
