import { Autocomplete, DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';

import Button from '@/components/Button';
import { useMutation } from '@apollo/client';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

import Alert from '@/components/Alert';
import type { AssignAdminsToPatientsResponse } from '@/graphql/administration';
import { ASSIGN_ADMINS_TO_PATIENTS } from '@/graphql/administration';
import type { RelyingPartyAdminData } from '@/routes/Administration';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  relyingPartyAdmins: RelyingPartyAdminData[];
  selectedAdmins?: Partial<RelyingPartyAdminData>[];
  selectedPatients: string[];
  isFollowUpPresent?: boolean;
};

//TODO: Improve this implementation
const AssignRelyingPartyAdminDialog = ({
  title,
  hideDialog,
  relyingPartyAdmins,
  selectedAdmins,
  selectedPatients,
  isFollowUpPresent = false,
  ...props
}: Props) => {
  const [assignAdminsToPatients] = useMutation<AssignAdminsToPatientsResponse>(ASSIGN_ADMINS_TO_PATIENTS);

  const [admins, setAdmins] = useState<Partial<RelyingPartyAdminData>[]>([]);

  const handleOnAssignSubmitClicked = async () => {
    if (admins.length > 0 && selectedPatients.length > 0) {
      const admin_ids = admins.map(admin => admin.id);

      let variables = {};
      variables = {
        accountIds: selectedPatients,
        relyingPartyAdminIds: admin_ids
      };

      try {
        await assignAdminsToPatients({
          variables: { ...variables }
        });
      } catch (err) {
        console.error(err);
      } finally {
        hideDialog();
      }
    }
  };

  return (
    <BaseDialog
      disableBackdropClick={true}
      canNavigateBack={false}
      title={title}
      hideDialog={hideDialog}
      {...props}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogContent>
        {isFollowUpPresent && (
          <Alert
            status="info"
            title="Important: Previous Follow-ups Exist"
            description="Note that this patient has follow-ups assigned to one of more of these users. Changing these assignments will impact their respective follow-ups as well."
          />
        )}
        <Autocomplete
          multiple
          id="tags-standard"
          options={relyingPartyAdmins}
          onChange={(event, value) => setAdmins(value)}
          getOptionLabel={option => option.name || ''}
          defaultValue={selectedAdmins}
          renderInput={params => <TextField {...params} variant="standard" label="Portal users" placeholder="select an option" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </DialogContent>

      <DialogActions>
        <Button a11yLabel="Submit" appearance="primary" onClick={() => handleOnAssignSubmitClicked()} />
        <Button a11yLabel="Cancel" type="reset" appearance="outline" onClick={hideDialog} />
      </DialogActions>
    </BaseDialog>
  );
};

export default AssignRelyingPartyAdminDialog;
