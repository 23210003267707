import Button from '@/components/Button';
import { Body1 } from '@/components/Typography';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge, Box, ClickAwayListener, Fade, Paper, Popper, Stack } from '@mui/material';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState, type ReactNode } from 'react';

type Props<T> = {
  children: ReactNode;
  customRefreshMessage?: string;
  refreshButton?: {
    onClick: () => Promise<T>;
    isLoading?: boolean;
  };
};

function RpmWorkflowTabTitle<T>({ children, customRefreshMessage, refreshButton }: Props<T>) {
  const { activeRpmSetupTab: activeTab, setActiveRpmSetupTab: setActiveTab } = useRpmSetupStore();
  const { enqueueSnackbar } = useSnackbar();
  const shouldRefresh = activeTab?.shouldRefreshTab || false;

  const [isPopoverOpen, setIsPopoverOpen] = useState(shouldRefresh);
  const anchorRef = useRef<HTMLDivElement>(null);

  const open = !!anchorRef.current && isPopoverOpen;

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handlePopoverOpen = () => {
    setIsPopoverOpen(true);
  };

  const handleRefresh = () => {
    if (!refreshButton) return;

    if (activeTab) {
      const newActiveTabValue = { ...activeTab, shouldRefreshTab: false };

      refreshButton
        .onClick()
        .then(() => {
          setActiveTab(newActiveTabValue);
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar('Refresh active RPM setup issue', { variant: 'error' });
        })
        .finally(() => handlePopoverClose());
    } else {
      console.error('No active tab data available');
      handlePopoverClose();
    }
  };

  const refreshMessage = customRefreshMessage
    ? customRefreshMessage
    : `Data in this tab has changed. Click <b>Refresh</b> to update the data.`;
  const boxProps = shouldRefresh ? { onClick: handlePopoverOpen } : {};

  useEffect(() => {
    setIsPopoverOpen(shouldRefresh);
  }, [shouldRefresh]);

  return (
    <>
      <Box
        ref={anchorRef}
        {...boxProps}
        sx={{
          display: 'inline-block',
          ...(shouldRefresh ? { cursor: 'pointer' } : {})
        }}
      >
        <Badge variant="dot" color="error" invisible={!shouldRefresh}>
          {children}
        </Badge>
      </Box>

      <Popper
        id={open ? 'refresh-popover' : undefined}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        disablePortal={true}
        transition
        modifiers={[
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: 'viewport',
              padding: 8
            }
          },
          {
            name: 'preventOverflow',
            enabled: false,
            options: {
              altAxis: false,
              altBoundary: true,
              tether: true,
              rootBoundary: 'viewport',
              padding: 8
            }
          }
        ]}
        sx={{ mt: 1, zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <ClickAwayListener onClickAway={handlePopoverClose}>
                <Box component={Paper} p={2} width={'22.875rem'} elevation={3} sx={{ bgcolor: 'white' }}>
                  <Body1>{parse(refreshMessage)}</Body1>
                  {refreshButton && (
                    <Stack direction="row" mt={1} justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        color="error"
                        isLoading={refreshButton.isLoading}
                        onClick={handleRefresh}
                      >
                        Refresh
                      </Button>
                    </Stack>
                  )}
                </Box>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default RpmWorkflowTabTitle;
