import { gql } from '@apollo/client';

export const GET_CLAIM_SUBMISSION_INFO = gql`
  query GetClaimSubmissionInfo($monthYear: String!, $submissionIds: [ID!], $relyingPartyIds: [ID!]) {
    getClaimSubmissionInfo(monthYear: $monthYear, submissionIds: $submissionIds, relyingPartyIds: $relyingPartyIds) {
      submissionAllowedRelyingParties {
        id
        name
      }
    }
  }
`;

export const GET_CLAIM_SUBMISSIONS = gql`
  query GetClaimSubmissions(
    $monthYear: String!
    $serviceType: MedicalServiceTypeEnum!
    $status: ClaimSubmissionStatusTypeEnum!
    $relyingPartyIds: [ID!]
    $pageNum: Int
    $pageSize: Int
  ) {
    getClaimSubmissions(
      monthYear: $monthYear
      serviceType: $serviceType
      status: $status
      relyingPartyIds: $relyingPartyIds
      pageNum: $pageNum
      pageSize: $pageSize
    ) {
      claimSubmissions {
        id
        account {
          user {
            firstName
            lastName
          }
        }
        relyingParty {
          name
        }
        provider {
          individualFirstName
          individualLastName
        }
        billingKey
        status
        scheduledOn
        additionalData
        processData
        serviceDate
        performedOn
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const UPDATE_CLAIM_SUBMISSIONS = gql`
  mutation UpdateClaimSubmission($submissionIds: [ID!]!, $submissionStatus: ClaimSubmissionStatusTypeEnum!) {
    updateClaimSubmission(submissionIds: $submissionIds, submissionStatus: $submissionStatus) {
      status
      message
    }
  }
`;

export const SCHEDULE_CLAIM_SUBMISSION = gql`
  mutation ScheduleClaimSubmission(
    $monthYear: String!
    $serviceType: MedicalServiceTypeEnum!
    $relyingPartyServiceDates: [ClaimSubmissionRelyingPartyServiceDate!]!
    $relyingPartyIds: [ID!]
  ) {
    scheduleClaimSubmission(
      monthYear: $monthYear
      serviceType: $serviceType
      relyingPartyServiceDates: $relyingPartyServiceDates
      relyingPartyIds: $relyingPartyIds
    ) {
      status
      message
    }
  }
`;
