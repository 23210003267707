import { Box } from '@mui/material';
import { H3, H4 } from '../Typography';
import AlertBadge from './AlertBadge';
import WidgetContainer from './WidgetContainer';

export const EnrollmentsWidget = ({
  enrolledThisMonth,
  enrolledYesterday,
  enrolledTotal
}: {
  enrolledThisMonth: number;
  enrolledYesterday: number;
  enrolledTotal: number;
}) => {
  return (
    <WidgetContainer title="Enrollments">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
            <H4 sx={{ textAlign: 'left' }}>This month</H4>
            <H3 sx={{ textAlign: 'right' }}>{enrolledThisMonth}</H3>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
            <H4 sx={{ textAlign: 'left' }}>Yesterday</H4>
            <H3 sx={{ textAlign: 'right' }}>{enrolledYesterday}</H3>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <AlertBadge colorScheme="scheme4" count={enrolledTotal} countTitle="Total" fullSize={true} />
        </Box>
      </Box>
    </WidgetContainer>
  );
};
