import ConfirmNavigateAwayDialog from '@/components/Dialogs/ConfirmNavigateAwayDialog';
import { Body2 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type {
  CreateRpmSetupResponse,
  GetRpmSetupResponse,
  PatientSetupDataUpdateNotificationResponse,
  RpmSetupTypes,
  UpdateRpmSetupResponse
} from '@/graphql/remotePatientMonitoring';
import { CREATE_RPM_SETUPS, GET_RPM_SETUPS, ON_PATIENT_SETUP_DATA_CHANGE, UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import RpmTabStatusHandler from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/RpmTabStatusHandler';
import RpmWorkflowToolbar from '@/routes/PatientDetails/RemotePatientMonitoring/RpmWorkflowToolbar';
import type { PatientsData } from '@/routes/Patients/patients';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { Role } from '@/types/admin';
import type { RpmWorkflowTabType, TabBasedActiveAdminsType } from '@/types/remotePatientMonitoring';
import { RpmSetupStatusTypes, RpmStatusTypes, RpmWorkflowTab, RpmWorkflowTabNames } from '@/types/remotePatientMonitoring';
import { checkTabDisability, checkTabProgressDisabled, checkTabReadOnly, pharmacistRoleTypes } from '@/util/adminstration';
import { findMissingElements } from '@/util/array';
import { mandatoryTabsForEnrolment } from '@/util/constants';
import { pathnameIncludes } from '@/util/location';
import { enrollmentEligibility, getSelectedTabData, refactorNotAvailableRpmSetups } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalHospital from '@mui/icons-material/LocalHospital';
import { Stack, Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import RpmActiveUsers from './Components/RpmWorkflow/RpmActiveUsers';
import RpmWorkflowTabLabelWithStatus from './Components/RpmWorkflow/RpmWorkflowTabLabelWithStatus';
import RpmWorkflowStatusBar from './RpmWorkflowStatusBar';

export enum RpmMonitoringActivityTypes {
  CareActivity,
  Encounter
}

export const getRpmWorkflowSessionType = () => {
  if (pathnameIncludes('patient-setup/care-activity')) {
    return RpmMonitoringActivityTypes.CareActivity;
  } else if (pathnameIncludes('patient-setup/encounter')) {
    return RpmMonitoringActivityTypes.Encounter;
  }
  return undefined;
};

const getRpmWorkflowSessionTypePathStringFromId = id => {
  if (id === RpmMonitoringActivityTypes.CareActivity) {
    return 'care-activity';
  } else if (id === RpmMonitoringActivityTypes.Encounter) {
    return 'encounter';
  }
  return '';
};

const workflowTabs: RpmWorkflowTabType[] = [
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.CLINICAL_SUMMARY],
    path: 'clinical-summary',
    type: RpmMonitoringActivityTypes.CareActivity,
    tabType: RpmWorkflowTab.CLINICAL_SUMMARY,
    tabReadOnlyRoleTypes: [Role.PROVIDER],
    accessDisableRoleTypes: []
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.MEDICAL_CONSULTS],
    path: 'medical-consults',
    type: RpmMonitoringActivityTypes.Encounter,
    tabType: RpmWorkflowTab.MEDICAL_CONSULTS,
    tabReadOnlyRoleTypes: [Role.PROVIDER, ...pharmacistRoleTypes],
    accessDisableRoleTypes: pharmacistRoleTypes
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.CONSENT_MANAGEMENT],
    path: 'consent-management',
    type: RpmMonitoringActivityTypes.Encounter,
    tabType: RpmWorkflowTab.CONSENT_MANAGEMENT,
    tabReadOnlyRoleTypes: [Role.PROVIDER, ...pharmacistRoleTypes],
    accessDisableRoleTypes: pharmacistRoleTypes
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.MEDICAL_RECONCILIATION],
    path: 'medication-reconciliation',
    type: RpmMonitoringActivityTypes.CareActivity,
    tabType: RpmWorkflowTab.MEDICAL_RECONCILIATION,
    tabReadOnlyRoleTypes: [Role.PROVIDER, ...pharmacistRoleTypes],
    accessDisableRoleTypes: pharmacistRoleTypes
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.CARE_PLAN],
    path: 'care-plan',
    type: RpmMonitoringActivityTypes.Encounter,
    tabType: RpmWorkflowTab.CARE_PLAN,
    tabReadOnlyRoleTypes: pharmacistRoleTypes,
    accessDisableRoleTypes: pharmacistRoleTypes,
    disableProgress: true
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.DEVICES],
    path: 'devices',
    type: RpmMonitoringActivityTypes.Encounter,
    tabType: RpmWorkflowTab.DEVICES,
    tabReadOnlyRoleTypes: [Role.PROVIDER],
    accessDisableRoleTypes: []
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.CONFIGURATION],
    path: 'configuration',
    type: RpmMonitoringActivityTypes.CareActivity,
    tabType: RpmWorkflowTab.CONFIGURATION,
    tabReadOnlyRoleTypes: [Role.PROVIDER, ...pharmacistRoleTypes],
    accessDisableRoleTypes: pharmacistRoleTypes
  }
];

const RpmMonitoringActivityTypesLabel = new Map<RpmMonitoringActivityTypes, string>([
  [RpmMonitoringActivityTypes.CareActivity, 'Care Activity'],
  [RpmMonitoringActivityTypes.Encounter, 'Encounter']
]);

const RpmWorkflow = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { patientInfo } = useContext(PatientDetailContext);
  const { rpmSetupTabs, activeRpmSetupTab, setActiveRpmSetupTab, setRpmSetupTabs, setIsEnrollmentEligibility, editRpmStatus } =
    useRpmSetupStore();
  const { enqueueSnackbar } = useSnackbar();
  const { roleType } = useToken();

  const { activeTimer, setActiveTimer, careActivityTimer, encounterTimer } = useRpmWorkflowStore();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState<RpmSetupTypes>();

  const [tabBasedActiveAdmins, setTabBasedActiveAdmins] = useState<TabBasedActiveAdminsType[]>(
    workflowTabs.map(workflowTab => {
      const admins = Object.values(Role)
        .filter(role => role !== Role.ADT_ADMIN)
        .reduce((acc, role) => {
          acc[role as Exclude<Role, Role.ADT_ADMIN>] = 0;
          return acc;
        }, {} as Record<Exclude<Role, Role.ADT_ADMIN>, number>);

      return {
        tabType: workflowTab.tabType,
        path: workflowTab.path,
        admins: admins
      };
    })
  );

  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);
  const [createRpmSetups] = useMutation<CreateRpmSetupResponse>(CREATE_RPM_SETUPS);
  useSubscription<PatientSetupDataUpdateNotificationResponse>(ON_PATIENT_SETUP_DATA_CHANGE, {
    variables: {
      truentityId: id,
      rpmSetupType: activeRpmSetupTab?.type
    },
    skip: activeRpmSetupTab?.type !== RpmWorkflowTab.MEDICAL_CONSULTS,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data && Object.values(data).length > 0 && activeRpmSetupTab) {
        setActiveRpmSetupTab({ ...activeRpmSetupTab, shouldRefreshTab: true });
      }
    },
    onError: error => console.error(error)
  });
  const { data: rpmSetups, refetch: rpmSetupsRefetch } = useQuery<GetRpmSetupResponse>(GET_RPM_SETUPS, {
    variables: {
      truentityId: id
    }
  });

  const isAnyAdminActive = useMemo(() => {
    return tabBasedActiveAdmins.some(tabBasedActiveAdmin => Object.values(tabBasedActiveAdmin.admins).some(count => count > 0));
  }, [tabBasedActiveAdmins]);

  const createNotAvailableRpmSetups = useCallback(
    (notAvailableRpmSetups: string[]) => {
      createRpmSetups({
        variables: {
          truentityId: id,
          rpmSetupValues: refactorNotAvailableRpmSetups(notAvailableRpmSetups)
        }
      }).then(() => rpmSetupsRefetch());
    },
    [createRpmSetups, id, rpmSetupsRefetch]
  );

  const checkRpmStatusTabAvailability = useCallback(
    (rpmWorkflowSetups: RpmSetupTypes[], roleType: string | null, patientInfo: PatientsData | null) => {
      const rpmWorkflowTabTypes: string[] = Object.values(RpmWorkflowTab);
      const availableTabs = rpmWorkflowSetups.filter(setup => rpmWorkflowTabTypes.includes(setup.type));
      let notAvailableTabs = rpmWorkflowTabTypes.filter(tabType => !rpmWorkflowSetups.some(setup => setup.type === tabType));
      const mandatoryTypeArray = rpmWorkflowSetups.filter(setup => setup.isRequiredForEnrollment).map(setup => setup.type);
      const missingElements = findMissingElements(mandatoryTypeArray, mandatoryTabsForEnrolment);

      if (notAvailableTabs.length === 0 && missingElements.length > 0) notAvailableTabs = missingElements;

      if (notAvailableTabs.length !== 0) {
        return createNotAvailableRpmSetups(notAvailableTabs);
      } else {
        const updatedAvailableTabs = availableTabs.map(tabItem => ({
          ...tabItem,
          canCurrentTabBeCompleted: !checkTabProgressDisabled(roleType, tabItem.type, patientInfo, workflowTabs, null),
          isDisabled: checkTabDisability(roleType, tabItem.type, patientInfo, workflowTabs, null),
          isReadOnly: checkTabReadOnly(roleType, tabItem.type, patientInfo, workflowTabs, null),
          shouldRefreshTab: false
        }));

        setRpmSetupTabs(updatedAvailableTabs);
      }
    },
    [createNotAvailableRpmSetups, setRpmSetupTabs]
  );

  const onStatusChange = (completed: boolean) => {
    try {
      if (selectedTab?.id) {
        updateRpmSetup({
          variables: {
            rpmSetupId: selectedTab?.id,
            status: completed ? RpmSetupStatusTypes.COMPLETED : RpmSetupStatusTypes.IN_PROGRESS,
            isManualStatusUpdate: true
          }
        })
          .then(res => {
            if (!res?.data) {
              throw new Error('Empty / Null data response');
            }
            const { status, message, rpmSetups } = res.data.updateRpmSetup;

            const variant = status === 'Success' ? 'success' : 'error';
            if (variant === 'error') {
              enqueueSnackbar(message, { variant });
            } else {
              editRpmStatus({ ...rpmSetups });
              enqueueSnackbar('RPM tab status updated successfully', { variant });
            }
          })
          .catch(error => {
            console.error(error);
            enqueueSnackbar('Failed to update RPM setup', { variant: 'error' });
          });
      }
    } catch (error) {
      enqueueSnackbar('Failed to update RPM setup', { variant: 'error' });
    }
  };

  useEffect(() => {
    const tabIndex = workflowTabs.findIndex(item => pathnameIncludes(item.path));
    setSelectedTabIndex(tabIndex);
  }, [location.pathname]);

  useEffect(() => {
    const selectedTab = getSelectedTabData(rpmSetupTabs, workflowTabs[selectedTabIndex].tabType);
    if (selectedTab) {
      setSelectedTab(selectedTab);
      setActiveRpmSetupTab(selectedTab);
    } else {
      setActiveRpmSetupTab(null);
    }
  }, [selectedTabIndex, rpmSetupTabs, setActiveRpmSetupTab]);

  useEffect(() => {
    const active = [careActivityTimer, encounterTimer].find(timer => timer.isRunning);
    setActiveTimer(active);
    // disabling this rule here otherwise maximum callstack error is triggered due to individual timer objects causing endless loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careActivityTimer.isRunning, careActivityTimer.timeElapsed, encounterTimer.isRunning, encounterTimer.timeElapsed]);

  useEffect(() => {
    if (patientInfo?.rpmStatus === null) {
      navigate('/home');
    }
  }, [navigate, patientInfo]);

  useEffect(() => {
    if (rpmSetups?.getRpmSetup && Array.isArray(rpmSetups.getRpmSetup)) {
      const rpmWorkflowSetups = rpmSetups.getRpmSetup;
      checkRpmStatusTabAvailability(rpmWorkflowSetups, roleType, patientInfo);
    }
  }, [checkRpmStatusTabAvailability, roleType, rpmSetups, patientInfo]);

  useEffect(() => {
    if (rpmSetupTabs && rpmSetupTabs.length > 0 && patientInfo?.rpmStatus !== RpmStatusTypes.ENROLLED) {
      setIsEnrollmentEligibility(enrollmentEligibility(rpmSetupTabs));
    }
  }, [rpmSetupTabs, patientInfo, setIsEnrollmentEligibility]);

  const getIcon = useCallback(
    (tabType: RpmWorkflowTab, tabBasedActiveAdmins: TabBasedActiveAdminsType[]) => {
      const rpmTabStatus = rpmSetupTabs.find(setupTab => setupTab.type === tabType);

      return (
        <Stack justifyContent="center" alignItems="center" gap="2px">
          <RpmWorkflowTabLabelWithStatus tabBasedActiveAdmin={tabBasedActiveAdmins.find(activeAdmin => activeAdmin.tabType === tabType)} />
          <CheckCircleIcon
            sx={{ visibility: rpmTabStatus?.status === RpmSetupStatusTypes.COMPLETED ? 'visible' : 'hidden' }}
            fontSize={'medium'}
            color="success"
          />
        </Stack>
      );
    },
    [rpmSetupTabs]
  );

  return (
    <Stack spacing={1} p={1} sx={{ background: theme.palette.background.default, borderRadius: 5 }}>
      <RpmWorkflowStatusBar />
      <RpmWorkflowToolbar>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" alignSelf="baseline" gap={2}>
          <Body2 hidden={!isAnyAdminActive}>Active Users:</Body2>
          <RpmActiveUsers id={id} tabBasedActiveAdmins={tabBasedActiveAdmins} setTabBasedActiveAdmins={setTabBasedActiveAdmins} />
        </Stack>
      </RpmWorkflowToolbar>

      <Stack direction="column" py={4} px={2} sx={{ background: theme.palette.background.paper, borderRadius: 4 }}>
        <Tabs
          orientation={'horizontal'}
          variant={'fullWidth'}
          value={selectedTabIndex}
          sx={{
            '& .MuiTab-root': {
              fontSize: '1rem',
              textTransform: 'capitalize'
            }
          }}
        >
          {workflowTabs.map((tab, index) => (
            <Tab
              label={
                tab.label === RpmWorkflowTabNames[RpmWorkflowTab.CARE_PLAN] ? (
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <LocalHospital fontSize="small" style={{ marginRight: 3 }} />
                    {tab.label}
                  </span>
                ) : (
                  tab.label
                )
              }
              key={`tab-${index}`}
              id={`tab-${index}`}
              icon={getIcon(tab.tabType, tabBasedActiveAdmins)}
              iconPosition="top"
              data-typeid={tab.type}
              disabled={checkTabDisability(roleType, tab.tabType, patientInfo, workflowTabs, null)}
              onClick={() => {
                navigate(getRpmWorkflowSessionTypePathStringFromId(tab.type) + '/' + tab.path);
              }}
              style={{
                position: 'relative',
                color: tab.label === RpmWorkflowTabNames[RpmWorkflowTab.CARE_PLAN] ? color.secondaryDark : 'primary'
              }}
            />
          ))}
        </Tabs>
      </Stack>
      <Stack direction="column" spacing={2} p={4} sx={{ background: theme.palette.background.paper, borderRadius: 4 }}>
        {selectedTab?.status && (
          <RpmTabStatusHandler
            tabType={selectedTab.type as RpmWorkflowTab}
            tabStatus={selectedTab?.status}
            onStatusChange={onStatusChange}
            canCurrentTabBeCompleted={selectedTab.canCurrentTabBeCompleted}
          />
        )}
        <Outlet />
      </Stack>

      {activeTimer && (
        <ConfirmNavigateAwayDialog
          when={() => {
            return activeTimer?.isRunning && document.activeElement?.getAttribute('data-typeId') !== activeTimer.id.toString();
          }}
          title={RpmMonitoringActivityTypesLabel.get(activeTimer.id) + ' is running'}
          message={'If you navigate away, the timer will be stopped.  Stop anyway?'}
          onNavigateAway={() => {
            console.warn('navigated away');
            activeTimer?.stopTimer();
          }}
        />
      )}
    </Stack>
  );
};

export default RpmWorkflow;
