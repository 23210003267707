import { Body1, H2, H4 } from '@/components/Typography';
import { Box } from '@mui/material';

type AlertBadgeProps = {
  title?: string;
  countTitle?: string;
  count?: number;
  fullSize?: boolean;
  isHorizontal?: boolean;
  colorScheme: 'scheme1' | 'scheme2' | 'scheme3' | 'scheme4';
};

const AlertBadge = ({ title = '', countTitle = '', count, colorScheme, fullSize = false, isHorizontal = false }: AlertBadgeProps) => {
  const colorMapping = {
    scheme1: { background: '#FFDED1', text: '#EB5353' },
    scheme2: { background: '#E7F0E7', text: '#387F39' },
    scheme3: { background: '#FEF8E2', text: '#CA7F00' },
    scheme4: { background: '#EBEFF5', text: '#243A58' }
  };

  const colors = colorMapping[colorScheme];

  return (
    <>
      {title && <H4 sx={{ textAlign: 'center', maxWidth: '80px', wordWrap: 'break-word', marginRight: isHorizontal ? 2 : 0 }}>{title}</H4>}
      <Box
        sx={{
          width: fullSize ? 100 : 60,
          height: fullSize ? 100 : 60,
          borderRadius: '30%',
          backgroundColor: colors.background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: fullSize ? -3 : 1
        }}
      >
        {countTitle && <Body1 sx={{ color: colors.text }}>{countTitle}</Body1>}
        <H2 sx={{ color: colors.text }}>{count}</H2>
      </Box>
    </>
  );
};

export default AlertBadge;
