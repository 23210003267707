import { H4, H5 } from '@/components/Typography';
import type { GetAccountOrdersResponse } from '@/graphql/remotePatientMonitoring';
import { GET_ACCOUNT_ORDERS, UPDATE_ACCOUNT_ORDER } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { AccountOrderTypes } from '@/types/remotePatientMonitoring';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  rpmFollowupConsultId?: string;
  isAccountApproved?: boolean;
  onAccountOrdersUpdate: () => void;
  setHasOrders: (value: boolean) => void;
  hasClinicalDiagnoses: boolean;
  hasGoals: boolean;
  isReadOnly: boolean;
};

const OrdersDataGrid: React.FC<Props> = ({
  onAccountOrdersUpdate,
  setHasOrders,
  hasGoals,
  hasClinicalDiagnoses,
  isReadOnly,
  rpmFollowupConsultId,
  isAccountApproved
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: truentityId } = useParams();

  const [bloodPressureChecked, setBloodPressureChecked] = useState<boolean>(false);
  const [bloodSugarChecked, setBloodSugarChecked] = useState<boolean>(false);

  const { data: rpmAccountOrderData } = useQuery<GetAccountOrdersResponse>(GET_ACCOUNT_ORDERS, {
    variables: {
      truentityId
    },
    skip: !truentityId,
    fetchPolicy: 'cache-and-network'
  });

  const [updateAccountOrder] = useMutation(UPDATE_ACCOUNT_ORDER, {
    onCompleted: data => {
      if (data.updateAccountOrder.errors) {
        enqueueSnackbar('Failed to update order status', { variant: 'error' });
      } else {
        onAccountOrdersUpdate();
        enqueueSnackbar('Order status updated successfully', { variant: 'success' });
      }
    },
    onError: () => {
      enqueueSnackbar('Error occurred while updating order status', { variant: 'error' });
    }
  });

  const handleCheckboxChange = (orderType: string, checked: boolean) => {
    if (orderType === AccountOrderTypes.RPM_BLOOD_PRESSURE_AND_HEART_RATE) {
      setBloodPressureChecked(checked);
    } else if (orderType === AccountOrderTypes.RPM_BLOOD_SUGAR) {
      setBloodSugarChecked(checked);
    }

    updateAccountOrder({
      variables: {
        truentityId,
        orderChecked: checked,
        orderType
      }
    }).catch(error => {
      console.error('Error updating order:', error);
    });
  };

  const isOrdersDisabled = !hasClinicalDiagnoses || isReadOnly || !hasGoals || (!rpmFollowupConsultId && isAccountApproved);

  useEffect(() => {
    setHasOrders(bloodPressureChecked || bloodSugarChecked);
  }, [bloodPressureChecked, bloodSugarChecked]);

  useEffect(() => {
    if (rpmAccountOrderData && rpmAccountOrderData.getAccountOrders) {
      rpmAccountOrderData.getAccountOrders.map(order => {
        if (order.orderType === AccountOrderTypes.RPM_BLOOD_PRESSURE_AND_HEART_RATE && order.orderChecked) {
          setBloodPressureChecked(true);
        }
        if (order.orderType === AccountOrderTypes.RPM_BLOOD_SUGAR && order.orderChecked) {
          setBloodSugarChecked(true);
        }
      });
    }
  }, [rpmAccountOrderData]);

  return (
    <Stack spacing={4} sx={{ height: 'auto', width: '100%' }} mt={4}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
          <H4>Orders</H4>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            width: '100%',
            height: 'auto',
            my: 2,
            p: 1,
            border: '1px solid',
            borderColor: theme => theme.palette.divider,
            backgroundColor: color.grey50,
            borderRadius: 1
          }}
        >
          <Box flex={'1'} display={'flex'} flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Box display={'flex'} flexDirection="row" alignItems="center">
              <FormControlLabel
                label={''}
                control={
                  <Checkbox
                    checked={bloodPressureChecked}
                    disabled={isOrdersDisabled}
                    onChange={e => handleCheckboxChange(AccountOrderTypes.RPM_BLOOD_PRESSURE_AND_HEART_RATE, e.target.checked)}
                  />
                }
              />
              <H5
                sx={{
                  fontSize: '14px',
                  fontWeight: 400
                }}
              >
                Remote Physiological Monitoring - Blood pressure & heart rate
              </H5>
            </Box>
            <Box display={'flex'} flexDirection="row" alignItems="center">
              <FormControlLabel
                label={''}
                control={
                  <Checkbox
                    checked={bloodSugarChecked}
                    disabled={isOrdersDisabled}
                    onChange={e => handleCheckboxChange(AccountOrderTypes.RPM_BLOOD_SUGAR, e.target.checked)}
                  />
                }
              />
              <H5
                sx={{
                  fontSize: '14px',
                  fontWeight: 400
                }}
              >
                Remote Physiological Monitoring - Blood sugar
              </H5>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default OrdersDataGrid;
