import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import Alert from '@/components/Alert';
import ChipColumn from '@/components/ChipLabel/ChipColumn';
import { Body1, Caption, H2, Label } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import RpmDevicesList from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmDevices/RpmDevicesList';
import RpmConditionsSelect from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/RpmConditionsSelect';
import { theme } from '@/styles/mui-theme';
import { ActivityLogType, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { formatRpmStatusString, mapRpmSignOffStatus } from '@/util/rpm';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Box, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useContext, useMemo, useState } from 'react';
import type { ButtonMenuItemTypes } from './Components/RpmWorkflow/RpmInfoTabsButtonMenu';
import RpmInfoTabsButtonMenu from './Components/RpmWorkflow/RpmInfoTabsButtonMenu';

type Props = {
  children?: ReactNode;
};

const RpmWorkflowToolbar = ({ children }: Props) => {
  const { patientInfo } = useContext(PatientDetailContext);

  const [type, setType] = useState<ActivityLogType>(ActivityLogType.COMMUNICATION_SUMMARY);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  const buttonMenuItems: ButtonMenuItemTypes[] = useMemo(
    () => [
      {
        buttonToolTipText: 'Communication Summery',
        buttonIcon: StickyNote2Icon,
        buttonAction: () => openActivityDrawer(ActivityLogType.COMMUNICATION_SUMMARY)
      }
    ],
    []
  );

  const openActivityDrawer = (type: ActivityLogType) => {
    setType(type);
    setIsActivityDrawerOpen(true);
  };
  const closeActivityDrawer = () => setIsActivityDrawerOpen(false);

  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }} p={4} borderRadius={4} spacing={2}>
        {patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED && (
          <Box flex={1}>
            <Alert
              status={'error'}
              description={`${getAccountUserFullName(patientInfo?.user)} unenrolled from the program on ${formatDate(
                patientInfo?.rpmUnenrolledAt
              )}`}
            />
          </Box>
        )}
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent="space-between">
          <H2>RPM Initial Visit & Setup</H2>
          <Stack>
            <Stack direction="row" columnGap={2}>
              {patientInfo?.rpmStatus && (
                <ChipColumn
                  label={<Label>Status</Label>}
                  color={patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED ? 'error' : 'primary'}
                  value={
                    <Body1 color={patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED ? 'error' : 'default'}>
                      {formatRpmStatusString(patientInfo?.rpmStatus) +
                        '' +
                        (patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED
                          ? ' on ' + formatDate(patientInfo?.rpmUnenrolledAt)
                          : patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED
                          ? ' on ' + formatDate(patientInfo?.rpmEnrolledAt)
                          : '')}
                    </Body1>
                  }
                  secondaryValue={
                    patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED && patientInfo?.lastCompletedFollowup ? (
                      <Box
                        sx={{
                          margin: 1
                        }}
                      >
                        <Caption textTransform="none" color="primary">
                          {`Last Follow-up: ${formatDate(patientInfo.lastCompletedFollowup.consultAt)}`}
                        </Caption>
                      </Box>
                    ) : undefined
                  }
                />
              )}
              {patientInfo?.rpmApprovalStatus && (
                <ChipColumn
                  label={<Label>Approval Status</Label>}
                  value={<Body1>{formatRpmStatusString(patientInfo?.rpmApprovalStatus)}</Body1>}
                  secondaryValue={
                    patientInfo?.rpmApprovalStatus ? (
                      <Box
                        sx={{
                          margin: 1
                        }}
                      >
                        <Caption textTransform="none" color="primary">
                          {mapRpmSignOffStatus({
                            rpmSignOffStatus: patientInfo?.rpmSignOffStatus
                          }) ?? ''}
                        </Caption>
                      </Box>
                    ) : undefined
                  }
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack gap={1}>
            <RpmConditionsSelect />
            <RpmDevicesList truentityId={patientInfo?.truentityId} />
          </Stack>
          <Stack spacing={2} alignSelf="baseline">
            {children}
          </Stack>
        </Stack>
        <Box overflow="hidden" position="absolute" bottom="10px" right="10px">
          <RpmInfoTabsButtonMenu buttonMenuItems={buttonMenuItems} />
        </Box>
      </Stack>
      <ActivityDrawer isOpen={isActivityDrawerOpen} onClose={closeActivityDrawer} type={type} />
    </>
  );
};

export default RpmWorkflowToolbar;
