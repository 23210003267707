import type { ScheduledReportType, SystemOptionType } from '@/types/administration';
import type { ClientOrganizationType, RelyingPartyAdminSessionType, RelyingPartyPerformanceReportType } from '@/types/graphql';
import type Meta from '@/types/meta';
import type { ProviderType } from '@/types/remotePatientMonitoring';
import { gql } from '@apollo/client';

export type AddUpdateClientOrgResponse = {
  saveOrganization: {
    clientOrganization: Partial<ClientOrganizationType>;
  };
};

export const ADD_UPDATE_CLIENT_ORG = gql`
  mutation createOrganization(
    $organizationId: String
    $organizationData: ClientOrganizationInput!
    $clientStoreInputs: [ClientStoreInput!]
    $relyingPartyId: String
  ) {
    saveOrganization(
      organizationId: $organizationId
      organizationData: $organizationData
      clientStoreInputs: $clientStoreInputs
      relyingPartyId: $relyingPartyId
    ) {
      clientOrganization {
        id
      }
    }
  }
`;

export type GetClientOrganizationResponse = {
  clientOrganization: ClientOrganizationType;
};

export const GET_CLIENT_ORGANIZATION_LOCATIONS = gql`
  query getClientOrganizationQuery($clientOrganizationId: String!) {
    clientOrganization(clientOrganizationId: $clientOrganizationId) {
      id
      name
      createdAt
      clientStores {
        id
        name
        nameTag
        isPrimary
      }
    }
  }
`;

export const GET_CLIENT_ORGANIZATION = gql`
  query getClientOrganizationQuery($clientOrganizationId: String!) {
    clientOrganization(clientOrganizationId: $clientOrganizationId) {
      id
      name
      createdAt
      addresses {
        city
        line1
      }
      contacts {
        type
        value
      }
      tasks {
        id
        name
        type
        subType
        status
        billedAmount
        healthPlan
        completedByAdmin
        location
        resolutionStatus
        resolutionNotes
        performedOn
        account {
          truentityId
        }
        encounters {
          id
        }
      }
      accounts {
        truentityId
        id
        address
        birthDate
        gender
        user {
          id
          firstName
          lastName
        }
        accountsAssignments {
          assignedAt
          relyingPartyAdmin {
            name
          }
        }
      }
      clientStores {
        id
        name
        nameTag
      }
    }
  }
`;

export const GET_CLIENT_ORGANIZATION_ACCOUNTS = gql`
  query getClientOrganizationAccountsQuery($clientOrganizationId: String!) {
    clientOrganizationAccounts(clientOrganizationId: $clientOrganizationId) {
      truentityId
      id
      address
      birthDate
      gender
      user {
        id
        firstName
        lastName
      }
      accountsAssignments {
        assignedAt
        relyingPartyAdmin {
          name
        }
      }
    }
  }
`;

export const GET_CLIENT_ORGANIZATIONS = gql`
  query getClientOrganizationsQuery($relyingPartyId: ID, $pageNum: Int!, $pageSize: Int!) {
    clientOrganizations(relyingPartyId: $relyingPartyId, pageNum: $pageNum, pageSize: $pageSize) {
      clientOrganizations {
        id
        name
        createdAt
        addresses {
          city
          line1
        }
        tasksCount
        accountsCount
        clientStoresCount
        clientStores {
          id
          name
          nameTag
        }
      }
      meta {
        totalCount
      }
    }
  }
`;

export type GetClientOrganizationsResponse = {
  clientOrganizations: {
    clientOrganizations: ClientOrganizationType[];
    meta: Meta;
  };
};

export const GET_CLIENT_ORGANIZATIONS_STANDARD = gql`
  query getClientOrganizationsQuery($relyingPartyId: ID, $pageNum: Int!, $pageSize: Int!) {
    clientOrganizations(relyingPartyId: $relyingPartyId, pageNum: $pageNum, pageSize: $pageSize) {
      clientOrganizations {
        id
        name
        settings
        createdAt
        addresses {
          city
          line1
        }
        clientStores {
          id
          name
          nameTag
          isPrimary
          settings
        }
      }
      meta {
        totalCount
      }
    }
  }
`;

export const GET_CLIENT_STORE = gql`
  query getClientStoreQuery($clientStoreId: String!) {
    clientStore(clientStoreId: $clientStoreId) {
      id
      name
      nameTag
      createdAt
      addresses {
        city
        line1
      }
      contacts {
        type
        value
      }
      tasks {
        id
        name
        type
        subType
        status
        resolutionStatus
        resolutionNotes
        completedByAdmin
        status
        billedAmount
        healthPlan
        location
        performedOn
        account {
          truentityId
        }
        encounters {
          id
        }
      }
      accounts {
        truentityId
        id
        address
        birthDate
        gender
        user {
          id
          firstName
          lastName
        }
        accountsAssignments {
          assignedAt
          relyingPartyAdmin {
            name
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_STORES = gql`
  query GetClientStoresQuery($pageNum: Int!, $pageSize: Int!) {
    clientStores(pageNum: $pageNum, pageSize: $pageSize) {
      clientStores {
        id
        name
        clientOrg {
          name
          id
        }
        tasks {
          id
          name
          type
          subType
          status
          resolutionStatus
          resolutionNotes
          completedByAdmin
          status
          billedAmount
          healthPlan
          location
          performedOn
          encounters {
            id
          }
        }
      }
    }
  }
`;

export type GetClientStoresStandardResponse = {
  clientStores: {
    clientStores: ClientOrganizationType[];
  };
};

export const GET_CLIENT_STORES_STANDARD = gql`
  query GetClientStoresQuery($pageNum: Int!, $pageSize: Int!) {
    clientStores(pageNum: $pageNum, pageSize: $pageSize) {
      clientStores {
        id
        name
        clientOrg {
          name
          id
        }
      }
    }
  }
`;

export const GET_CLIENT_STORES_BY_ORG = gql`
  query GetClientStoresByOrgQuery($clientOrgId: String!, $pageNum: Int!, $pageSize: Int!) {
    clientStoresByOrg(clientOrgId: $clientOrgId, pageNum: $pageNum, pageSize: $pageSize) {
      clientStores {
        id
        name
        nameTag
        tasks {
          id
          name
          type
          subType
          status
          resolutionStatus
          resolutionNotes
          completedByAdmin
          status
          billedAmount
          healthPlan
          location
          performedOn
          encounters {
            id
          }
        }
      }
    }
  }
`;

export const ADD_CLIENT_STORE = gql`
  mutation AddClientStore($clientOrgId: ID!, $storeInput: ClientStoreInput!) {
    addClientStore(clientOrgId: $clientOrgId, storeInput: $storeInput) {
      status
      message
    }
  }
`;

export const UPDATE_CLIENT_STORE = gql`
  mutation UpdateClientStore($clientStoreId: ID!, $name: String!, $nameTag: String!, $isPrimary: Boolean, $settings: JSON) {
    updateClientStore(clientStoreId: $clientStoreId, name: $name, nameTag: $nameTag, isPrimary: $isPrimary, settings: $settings) {
      status
      message
    }
  }
`;

export const ASSIGN_PATIENTS_TO_ORGANIZATIONS = gql`
  mutation assignAccountsToOrganization($organizationId: String!, $accountIds: [String!]) {
    assignAccountsToOrganization(organizationId: $organizationId, accountIds: $accountIds) {
      status
    }
  }
`;

export const ASSIGN_CLIENT_ORGS_STORES_TO_ACCOUNT = gql`
  mutation assignClientOrgsStoresToAccount($truentityId: String!, $clientOrganizationStoreData: [ClientOrganizationInput!]!) {
    assignClientOrgsStoresToAccount(truentityId: $truentityId, clientOrganizationStoreData: $clientOrganizationStoreData) {
      status
      message
    }
  }
`;

export type AssignAdminsToPatientsResponse = {
  assignAdminsToPatients: {
    status: string;
  };
};
export const ASSIGN_ADMINS_TO_PATIENTS = gql`
  mutation AssignAdminsToPatients($accountIds: [String!], $relyingPartyAdminIds: [String!]) {
    assignAdminsToPatients(accountIds: $accountIds, relyingPartyAdminIds: $relyingPartyAdminIds) {
      status
      message
    }
  }
`;

export const UNASSIGN_ADMINS_TO_PATIENTS = gql`
  mutation UnassignAdminsToPatients($accountIds: [String!]) {
    unassignAdminsToPatients(accountIds: $accountIds) {
      status
      message
    }
  }
`;

export const GET_RELYING_PARTY_ADMIN_BY_ID = gql`
  query RelyingPartyAdminById($relyingPartyAdminId: String!) {
    relyingPartyAdminById(relyingPartyAdminId: $relyingPartyAdminId) {
      id
      user {
        id
        firstName
        lastName
        email
      }
      contactsRelyingPartyAdmins {
        contactType
        value
        relyingPartyAdminId
      }
      roleType
      isObserver
      isRpmPrimary
      npiNumber
      externalDialpadId
      techLicense
      title
      hourlyRate
      supervisorEmail
      businessName
      addressLine1
      addressLine2
      addressZipcode
      addressCity
      addressState
      phone
      alternateEmail
      bankName
      bankAccountNumber
      bankRoutingNumber
      settings
      presignedAvatarUrl
    }
  }
`;

export const GET_RELYING_PARTY_RPM_REVIEWER = gql`
  query GetRpmReviewer {
    getRpmReviewer {
      id
      isObserver
      isRpmPrimary
    }
  }
`;

export const GET_RELYING_PARTY_ADMINS = gql`
  query GetRelyingPartyAdminsAll($pageNum: Int!, $pageSize: Int!) {
    relyingPartyAdmins(pageNum: $pageNum, pageSize: $pageSize) {
      relyingPartyAdmins {
        id
        user {
          id
          firstName
          lastName
          email
        }
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        roleType
        npiNumber
        isObserver
        isRpmPrimary
        externalDialpadId
        techLicense
        title
        hourlyRate
        supervisorEmail
        businessName
        addressLine1
        addressLine2
        addressZipcode
        addressCity
        addressState
        phone
        alternateEmail
        bankName
        bankAccountNumber
        bankRoutingNumber
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type AddRelyingPartyAdminResponse = {
  addRelyingPartyAdmin: {
    status: string;
    message: string;
  };
};

export const ADD_RELYING_PARTY_ADMIN = gql`
  mutation addRelyingPartyAdmin(
    $email: Email!
    $firstName: String!
    $lastName: String!
    $roleType: String!
    $isObserver: Boolean!
    $isRpmPrimary: Boolean
    $organization: String!
    $techLicense: String!
    $npiNumber: String!
    $title: String!
    $isTextLoginOtp: Boolean!
    $isEmailLoginOtp: Boolean!
    $mobileNumber: String!
    $relyingPartyId: String
  ) {
    addRelyingPartyAdmin(
      email: $email
      firstName: $firstName
      lastName: $lastName
      roleType: $roleType
      isObserver: $isObserver
      isRpmPrimary: $isRpmPrimary
      organization: $organization
      techLicense: $techLicense
      npiNumber: $npiNumber
      title: $title
      isEmailLoginOtp: $isEmailLoginOtp
      isTextLoginOtp: $isTextLoginOtp
      mobileNumber: $mobileNumber
      relyingPartyId: $relyingPartyId
    ) {
      status
      message
    }
  }
`;

export const DEACTIVATE_RELYING_PARTY_ADMIN = gql`
  mutation DeactivateRelyingPartyAdmin($isActive: Boolean!, $relyingPartyAdminId: String!) {
    deactivateRelyingPartyAdmin(isActive: $isActive, relyingPartyAdminId: $relyingPartyAdminId) {
      status
      message
    }
  }
`;

export type CommonPerformanceStatStringType = {
  dailyValue: string;
  weeklyValue: string;
  monthlyValue: string;
};

export type CommonPerformanceStatNumType = {
  dailyValue: number;
  weeklyValue: number;
  monthlyValue: number;
};

export type GetAdminPerformancesResponse = {
  getAdminPerformances: {
    dailyLabel: string;
    weeklyLabel: string;
    monthlyLabel: string;
    tipsCompleted: CommonPerformanceStatNumType;
    cmrsCompleted: CommonPerformanceStatNumType;
    encounters: CommonPerformanceStatNumType;
    tcmsCompleted: CommonPerformanceStatNumType;
    rpmsCompleted: CommonPerformanceStatNumType;
    invoicedTime: CommonPerformanceStatStringType;
    utilizationRate: CommonPerformanceStatNumType;
  };
};

export const GET_ADMIN_PERFORMANCE = gql`
  query GetAdminPerformancesQuery($relyingPartyAdminId: String!) {
    getAdminPerformances(relyingPartyAdminId: $relyingPartyAdminId) {
      dailyLabel
      weeklyLabel
      monthlyLabel
      tipsCompleted {
        dailyValue
        weeklyValue
        monthlyValue
      }
      cmrsCompleted {
        dailyValue
        weeklyValue
        monthlyValue
      }
      encounters {
        dailyValue
        weeklyValue
        monthlyValue
      }
      tcmsCompleted {
        dailyValue
        weeklyValue
        monthlyValue
      }
      rpmsCompleted {
        dailyValue
        weeklyValue
        monthlyValue
      }
      invoicedTime {
        dailyValue
        weeklyValue
        monthlyValue
      }
      utilizationRate {
        dailyValue
        weeklyValue
        monthlyValue
      }
    }
  }
`;

export const GET_HEALTH_PLANS = gql`
  query getHealthPlans($pageNum: Int!, $pageSize: Int!) {
    healthPlans(pageNum: $pageNum, pageSize: $pageSize) {
      healthPlans {
        id
        orgName
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const ADD_HEALTH_PLAN = gql`
  mutation addHealthPlan($name: String!) {
    addHealthPlan(name: $name) {
      status
      message
    }
  }
`;

export const UPDATE_HEALTH_PLAN = gql`
  mutation updateHealthPlan($healthPlanId: ID!, $name: String!) {
    updateHealthPlan(healthPlanId: $healthPlanId, name: $name) {
      status
      message
    }
  }
`;

export type GetRelyingPartyAdminSessionsResponse = {
  relyingPartyAdminSessions: {
    relyingPartyAdminSessions: RelyingPartyAdminSessionType[];
  };
};
export const GET_RELYING_PARTY_ADMINS_SESSIONS = gql`
  query GetRelyingPartyAdminSessions($relyingPartyAdminId: ID!, $pageNum: Int!, $pageSize: Int!) {
    relyingPartyAdminSessions(relyingPartyAdminId: $relyingPartyAdminId, pageNum: $pageNum, pageSize: $pageSize) {
      relyingPartyAdminSessions {
        id
        startedAt
        endedAt
        durationInSecs
        device {
          userAgent
          ip
        }
        timedOut
      }
    }
  }
`;

export const GET_RELYING_PARTY_ADMINS_ACTIVITIES = gql`
  query GetRelyingPartyAdminActivitiesBySession($relyingPartyAdminSessionId: ID!, $pageNum: Int!, $pageSize: Int!) {
    relyingPartyAdminActivitiesBySession(relyingPartyAdminSessionId: $relyingPartyAdminSessionId, pageNum: $pageNum, pageSize: $pageSize) {
      activityLogs {
        id
        logText
        activityType
        activityTime
        activityEndTime
        timeTakenSecs
        account {
          truentityId
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_RELYING_PARTY_ADMINS_ALL = gql`
  query GetRelyingPartyAdminsAll($pageNum: Int!, $pageSize: Int!) {
    relyingPartyAdmins(pageNum: $pageNum, pageSize: $pageSize) {
      relyingPartyAdmins {
        id
        name
        user {
          id
          firstName
          lastName
          email
        }
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        roleType
        npiNumber
        title
        techLicense
        hourlyRate
        supervisorEmail
        businessName
        addressLine1
        addressLine2
        addressZipcode
        addressCity
        addressState
        phone
        alternateEmail
        bankName
        bankAccountNumber
        bankRoutingNumber
        lastSession {
          id
          startedAt
          endedAt
          durationInSecs
          device {
            userAgent
            ip
          }
          timedOut
        }
        minutesToday
        minutesThisWeek
        minutesThisMonth
        minutesThisYear
        activityToday
        activityThisWeek
        activityThisMonth
        activityThisYear
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_RELYING_PARTY_PHARMACISTS = gql`
  query GetRelyingPartyPharmacistsAll($pageNum: Int!, $pageSize: Int!) {
    relyingPartyAdminsAll(pageNum: $pageNum, pageSize: $pageSize) {
      relyingPartyAdmins {
        id
        user {
          id
          firstName
          lastName
          email
        }
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        name
        roleType
        npiNumber
        techLicense
        title
        hourlyRate
        supervisorEmail
        businessName
        addressLine1
        addressLine2
        addressZipcode
        addressCity
        addressState
        phone
        alternateEmail
        bankName
        bankAccountNumber
        bankRoutingNumber
      }
    }
  }
`;

export type GetRelyingPartyPerformanceReportsResponse = {
  performanceReport: {
    data: RelyingPartyPerformanceReportType[];
    meta: Meta;
  };
};

export const GET_RELYING_PARTY_PERFORMANCE_REPORTS = gql`
  mutation PerformanceReport(
    $startDate: String!
    $endDate: String!
    $relyingPartyAdminId: String!
    $isExport: Boolean!
    $pageNum: Int!
    $pageSize: Int!
  ) {
    performanceReport(
      startDate: $startDate
      endDate: $endDate
      relyingPartyAdminId: $relyingPartyAdminId
      isExport: $isExport
      pageNum: $pageNum
      pageSize: $pageSize
    ) {
      data {
        date
        activeTime
        billedAmount
        bonusAmount
        numOfEncounters
        invoicedTime
        invoicedAmount
        relyingPartyAdminName
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetRPAgreementResponse = {
  relyingPartyAgreement: {
    status: string;
    agreementUrl: string;
  };
};

export const GET_RELYING_PARTY_AGREEMENT = gql`
  query GetRelyingPartyAgreement {
    relyingPartyAgreement {
      status
      agreementUrl
    }
  }
`;

export type ClickThroughAgreementResponse = {
  respondClickThroughAgreement: {
    status: string;
    message: string;
  };
};

export type CTAUserDetailsInput = {
  ipAddress: string;
};

export const RESPOND_CLICK_THROUGH_AGREEMENT = gql`
  mutation RespondClickThroughAgreement($action: AgreementRespondTypeEnum!, $userDetails: CtaUserDetailsInput!) {
    respondClickThroughAgreement(action: $action, userDetails: $userDetails) {
      status
      message
    }
  }
`;

export type CreateScheduledReportResponse = {
  createScheduledReport: {
    status: string;
    message: string;
  };
};

export const CREATE_SCHEDULED_REPORT = gql`
  mutation CreateScheduledReport($reportType: ScheduledReportTypeEnum!, $scheduledReportInput: ScheduleReportInput!) {
    createScheduledReport(reportType: $reportType, scheduledReportInput: $scheduledReportInput) {
      status
      message
    }
  }
`;

export type GetScheduledRpoertsResponse = {
  getScheduledReports: {
    scheduledReports: ScheduledReportType[];
    meta: Meta;
  };
};

export const GET_SCHEDULED_REPORTS = gql`
  query GetScheduledReports($filterOptions: ScheduledReportsFilterOptionsInput!, $pageNum: Int!, $pageSize: Int!) {
    getScheduledReports(filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      scheduledReports {
        id
        name
        organization
        status
        reportType
        s3Url
        createdAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type PinSettingsInputType = {
  securityPin: string | undefined;
  inactivityPinPromptTimeoutSeconds: number;
};

export const UPDATE_RELYING_PARTY_ADMIN_PIN_SETTINGS = gql`
  mutation UpdateRelyingPartyAdminPinSettings($pinSettingsInput: RelyingPartyAdminPinSettingsInput!) {
    updateRelyingPartyAdminPinSettings(pinSettingsInput: $pinSettingsInput) {
      status
      message
    }
  }
`;

export type RelyingPartyAdminSecuritySettingsResponse = {
  relyingPartyAdminSecuritySettings: {
    isSecurityPinSet: boolean;
    inactivityPinPromptTimeoutSeconds: number;
    inactivityTimeoutSeconds: number;
  };
};

export const GET_RELYING_PARTY_ADMIN_SECURITY_SETTINGS = gql`
  query RelyingPartyAdminSecuritySettings($relyingPartyAdminId: String!) {
    relyingPartyAdminSecuritySettings(relyingPartyAdminId: $relyingPartyAdminId) {
      isSecurityPinSet
      inactivityTimeoutSeconds
      inactivityPinPromptTimeoutSeconds
    }
  }
`;

export type RelyingPartyIdleTimeoutReLoginResponse = {
  relyingPartyIdleTimeoutReLogin: {
    status: string;
    code?: string;
    message: string;
    relyingPartyAdmin: {
      id: string;
      roleType: string;
      relyingParty: {
        id: string;
        name: string;
      };
    };
  };
};

export const RELYING_PARTY_IDLE_TIMEOUT_RE_LOGIN = gql`
  mutation RelyingPartyIdleTimeoutReLogin($pinCode: String!) {
    relyingPartyIdleTimeoutReLogin(pinCode: $pinCode) {
      status
      code
      message
      relyingPartyAdmin {
        id
        roleType
        relyingParty {
          id
          name
        }
      }
    }
  }
`;

export type ProviderStatisticsDataType = {
  approvedCount: string;
  skippedCount: string;
  rejectedCount: string;
  reportsReviewedCount: string;
  totalCount: string;
  signedOffCount: string;
  monthYear: string;
  provider: ProviderType;
};

export type GetRpmProviderStatisticsResponse = {
  getRpmProviderStatistics: {
    providerStatistics: ProviderStatisticsDataType[];
    meta: Meta;
  };
};

export const GET_RPM_PROVIDER_STATISTICS = gql`
  query GetRpmProviderStatistics($pageNum: Int!, $pageSize: Int!) {
    getRpmProviderStatistics(pageNum: $pageNum, pageSize: $pageSize) {
      providerStatistics {
        approvedCount
        skippedCount
        rejectedCount
        reportsReviewedCount
        totalCount
        monthYear
        signedOffCount
        provider {
          id
          individualFirstName
          individualLastName
        }
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export type CreateScheduledRPMPerformanceReportResponse = {
  createRelyingPartiesPerformanceReport: {
    status: string;
    message: string;
  };
};

export const CREATE_SCHEDULED_RPM_PERFORMANCE_REPORT = gql`
  mutation createRelyingPartiesPerformanceReport($monthYear: String!) {
    createRelyingPartiesPerformanceReport(monthYear: $monthYear) {
      status
      message
    }
  }
`;

export type GetAthenaMckApisResponse = {
  getAthenaApis: {
    name: string;
    endpoint: string;
    category: string;
    endpointMethod: string;
    description: string;
    parameters: {
      name: string;
      type: string;
      defaultValue: string;
      required: boolean;
      description: string;
    }[];
  }[];
};

export const GET_ATHENA_MOCK_APIS = gql`
  query GetAthenaApis {
    getAthenaApis {
      name
      endpoint
      category
      endpointMethod
      description
      parameters {
        name
        type
        defaultValue
        required
        description
      }
    }
  }
`;

export const TRIGGER_ATHENA_MOCK_API = gql`
  mutation TriggerAthenaEndpoints($apiName: String!, $parameters: JSON!) {
    triggerAthenaEndpoints(apiName: $apiName, parameters: $parameters) {
      status
      response
      error
    }
  }
`;

export type GetSystemOptionsResponse = {
  getSystemOptions: {
    options: SystemOptionType[];
  };
};

export const GET_SYSTEM_OPTIONS = gql`
  query GetSystemOptions($optionKey: String!) {
    getSystemOptions(optionKey: $optionKey) {
      options {
        value
        defaultContactMode
        isOptionsEditable
        nestedOptions {
          value
        }
      }
    }
  }
`;
