import type { GetDocumentPreviewResponse } from '@/graphql/rpmWorkflow';
import { GET_DOCUMENT_PREVIEW } from '@/graphql/rpmWorkflow';
import { useLazyQuery } from '@apollo/client';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import PdfDialog from '../components/Dialogs/PdfDialog';

type Props = {
  documentTitle: string;
};

export type FetchDocumentPreviewProps = {
  s3Key: string;
};

export const useDocumentPreview = ({ documentTitle }: Props) => {
  const { showModal } = useModal();
  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW, {
    fetchPolicy: 'cache-and-network'
  });
  const { enqueueSnackbar } = useSnackbar();

  const fetchDocumentPreview = async ({ s3Key }: FetchDocumentPreviewProps) => {
    try {
      const documentResponse = await getDocumentPreview({
        variables: {
          documentS3Key: s3Key
        }
      });
      if (documentResponse?.data?.documentPreview?.documentUrl) {
        fetch(`${documentResponse?.data?.documentPreview?.documentUrl}`)
          .then(response => response.blob())
          .then(blob => {
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            const modal = showModal(PdfDialog, {
              title: `${documentTitle} Report Preview`,
              pdfUrl: url,
              hideDialog: () => {
                modal.hide();
              }
            });
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Failed to load document preview', { variant: 'error' });
    }
  };

  return {
    fetchDocumentPreview
  };
};
