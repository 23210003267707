import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H4 } from '@/components/Typography';
import InputRow from '@/elements/InputRow';
import { GET_CLIENT_ORGANIZATIONS_STANDARD, type GetClientOrganizationsResponse } from '@/graphql/administration';
import type { FollowUpFormHandles } from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import FollowUpForm from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import type { TruentityDateInput } from '@/types/date';
import type { ClientOrganizationType, ClientStoreType, EncounterType, TaskType } from '@/types/graphql';
import { DISPOSITION_TYPES, TASK_MODE_OF_RESOLUTION_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import type { LabelValuePair } from '@/types/misc';
import { formatDateAndTime, formatDateAndTime2, formatTime } from '@/util/format';
import { unknown } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import { useQuery } from '@apollo/client';
import type { RefObject } from '@fullcalendar/core/preact';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../Button';
import { CustomSelectList } from '../../CustomSelectList';
import TruentityTextField from '../../TruentityTextField';
import { FollowupFormModeTypes } from '../FollowUpAddEditDialog';
import type { EncounterDialogFormState } from './EditEncounterDialog';
import { SelectedTaskListItem } from './EditEncounterDialog';

//TODO:  Fix the required fields. Not displaying correctly for the dropdowns

type EncounterDialogAdditionalFormStates = {
  originatingClientOrgId: string | undefined;
  originatingClientStoreId: string | undefined;
};

const encounterDialogDefaultData: EncounterDialogFormState & Partial<EncounterDialogAdditionalFormStates> = {
  id: '',
  disposition: '',
  notes: '',
  modeOfResolution: '',
  originatingClientOrgId: '',
  originatingClientStoreId: ''
};

export type EncounterDialogFormData = Partial<EncounterType> &
  Partial<EncounterDialogAdditionalFormStates> & {
    selectedTasks: string[];
    isFollowUpRequired: boolean;
  };

type Props = {
  onSubmit: (formData: EncounterDialogFormData) => void;
  onReset: () => void;
  showTasks: boolean;
  tasksFromAPI?: TaskType[];
  encounter?: Partial<EncounterType>;
  disableSubmitButton?: boolean;
  isEditForm?: boolean;
  followUpFormRef?: RefObject<FollowUpFormHandles>;
};
const EncounterDialogForm = ({
  onSubmit,
  onReset,
  showTasks,
  tasksFromAPI,
  encounter,
  disableSubmitButton,
  followUpFormRef,
  isEditForm = false
}: Props) => {
  const currentDateTime = new Date();
  const { account } = useAccountStore();

  const [performedDate, setPerformedDate] = useState<TruentityDateInput>(new Date());
  const [performedTime, setPerformedTime] = useState<TruentityDateInput>(formatTime(currentDateTime, 'HH:mm'));
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [state, setState] = useState(encounterDialogDefaultData);
  const [isRequiredFollowUpField, setIsRequiredFollowUpField] = useState<boolean>(false);
  const [allOrganizations, setAllOrganizations] = useState<ClientOrganizationType[]>([]);

  const accountClientOrgs = useMemo(() => account?.clientOrgs, [account]);
  const accountClientStores = useMemo(() => account?.clientStores, [account]);

  useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    onCompleted: data => setAllOrganizations(data?.clientOrganizations?.clientOrganizations || []),
    notifyOnNetworkStatusChange: true
  });

  const handleOnSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const dateTime = formatDateAndTime2(performedDate, performedTime);

    const formData: EncounterDialogFormData = {
      calledOn: dateTime,
      notes: state.notes,
      disposition: state.disposition,
      selectedTasks,
      modeOfResolution: state.modeOfResolution,
      originatingClientOrgId: state.originatingClientOrgId,
      originatingClientStoreId: state.originatingClientStoreId,
      isFollowUpRequired: isRequiredFollowUpField
    };
    onSubmit(formData);
  };

  const getClientOrgStores = useCallback(
    (originatingClientOrgId: string | undefined): ClientStoreType[] => {
      if (!originatingClientOrgId || originatingClientOrgId.length === 0) return [];

      return allOrganizations?.find(org => org.id === originatingClientOrgId)?.clientStores || [];
    },
    [allOrganizations]
  );

  useEffect(() => {
    const foundedClientOrg = allOrganizations.find(clientOrg => clientOrg.id === accountClientOrgs?.[0]?.id);
    const foundedClientStore = foundedClientOrg
      ? foundedClientOrg.clientStores?.find(store => store.id === accountClientStores?.[0]?.id)
      : undefined;

    if (encounter) {
      const { originatingClientStore, originatingClientOrg } = encounter;

      setPerformedDate(encounter?.calledOn);
      setPerformedTime(formatTime(encounter?.calledOn, 'HH:mm'));

      setState({
        ...encounter,
        originatingClientOrgId: originatingClientOrg?.id || foundedClientOrg?.id,
        originatingClientStoreId: originatingClientStore?.id || foundedClientStore?.id
      });

      if (encounter?.tasks && encounter?.tasks?.length > 0) {
        const selectedTasksIds = encounter?.tasks?.map(task => task.id);
        setSelectedTasks(selectedTasksIds);
      }
    } else {
      setState(previous => ({
        ...previous,
        originatingClientOrgId: foundedClientOrg ? foundedClientOrg.id : undefined,
        originatingClientStoreId: foundedClientStore ? foundedClientStore.id : undefined
      }));
    }
  }, [encounter, account, allOrganizations, accountClientOrgs, accountClientStores]);

  const handelCheckBox = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsRequiredFollowUpField(checked);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={1} pb={2}>
        <Grid item xs={12}>
          <Card sx={{ minWidth: 275, marginTop: '10px' }}>
            <CardContent>
              <Grid container spacing={1} pb={2}>
                <Grid item xs={6}>
                  <InputRow>
                    <TruentityDatePicker
                      label="Encounter Date"
                      fullWidth={true}
                      value={performedDate}
                      onChange={newValue => {
                        setPerformedDate(newValue);
                      }}
                    />
                  </InputRow>
                </Grid>

                <Grid item xs={6}>
                  <InputRow>
                    <TruentityTextField
                      id="time"
                      label="Encounter Time"
                      type="time"
                      value={performedTime}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300
                      }}
                      required
                      onChange={event => {
                        const value = event.target.value;
                        if (value) {
                          setPerformedTime(value);
                        }
                      }}
                    />
                  </InputRow>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                      <InputLabel id={'modeOfResolution'}>Mode Of Resolution</InputLabel>

                      <Select
                        labelId={'modeOfResolution'}
                        label={'Mode Of Resolution'}
                        placeholder={'Select an option...'}
                        value={state?.modeOfResolution}
                        onChange={input => {
                          setState({ ...state, modeOfResolution: input.target.value });
                        }}
                        required
                      >
                        {TASK_MODE_OF_RESOLUTION_TYPES.map((item: LabelValuePair) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                      <InputLabel id={'disposition'}>Disposition</InputLabel>

                      <Select
                        labelId={'newDisposition'}
                        label={'Disposition'}
                        placeholder={'Select an option...'}
                        value={state?.disposition}
                        onChange={event => {
                          setState({ ...state, disposition: event.target.value });
                        }}
                        required
                      >
                        {DISPOSITION_TYPES.map((item: LabelValuePair) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputRow>
                      <TruentityTextField
                        label="Additional Notes"
                        type="text"
                        multiline
                        maxRows={4}
                        value={state?.notes}
                        onChange={input => {
                          setState({
                            ...state,
                            notes: input.target.value
                          });
                        }}
                      />
                    </InputRow>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                      <InputLabel id={'originatingClientOrg'}>Originating Client Organization</InputLabel>

                      <Select
                        labelId="originatingClientOrg"
                        label="Originating Client Organization"
                        placeholder="Select an option..."
                        value={state?.originatingClientOrgId}
                        onChange={input => {
                          setState({ ...state, originatingClientOrgId: input.target.value, originatingClientStoreId: '' });
                        }}
                        required
                      >
                        {allOrganizations?.map(clientOrg => (
                          <MenuItem key={clientOrg.id} value={clientOrg.id}>
                            {clientOrg.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                      <InputLabel id={'originatingClientStore'}>Originating Client Store</InputLabel>

                      <Select
                        labelId="originatingClientStore"
                        label="Originating Client Store"
                        placeholder="Select an option..."
                        value={state?.originatingClientStoreId}
                        onChange={input => {
                          setState({ ...state, originatingClientStoreId: input.target.value });
                        }}
                        disabled={
                          state.originatingClientOrgId?.length === 0 || getClientOrgStores(state?.originatingClientOrgId).length === 0
                        }
                        required
                      >
                        {getClientOrgStores(state?.originatingClientOrgId).map(store => (
                          <MenuItem key={store.id} value={store.id}>
                            {store.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>

              {tasksFromAPI && showTasks && (
                <CustomSelectList<TaskType>
                  title={'Tasks worked on during this Encounter'}
                  items={tasksFromAPI}
                  selectedItems={selectedTasks}
                  setSelectedItems={setSelectedTasks}
                  options={tasksFromAPI.map(task => {
                    const subType = UPDATED_SUB_TASK_TYPES.find(type => type.value === task.subType);
                    const label = `${task.type} ${subType?.label ?? ''} - ${task.medicationName ?? unknown()} - ${formatDateAndTime(
                      task.createdAt
                    )}`;

                    return {
                      id: task.id,
                      value: task.id,
                      label
                    };
                  })}
                  generateSelectedItem={item => <SelectedTaskListItem task={item} />}
                />
              )}

              {!isEditForm && (
                <>
                  <Divider sx={{ marginTop: 2 }} />
                  <FormControlLabel
                    label={`Add a Follow-up Reminder`}
                    control={<Checkbox value={isRequiredFollowUpField} onChange={handelCheckBox} />}
                  />
                  <Accordion expanded={isRequiredFollowUpField}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`follow-up-reminder`}>
                      <H4>Follow-up Reminder</H4>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: '#fafafa', padding: 2 }}>
                      {account?.truentityId && (
                        <FollowUpForm
                          truentityId={account?.truentityId}
                          formMode={FollowupFormModeTypes.ADD}
                          doNotCall={account?.doNotCall || false}
                          accountAssignees={account?.currentAccountsAssignments.map(assignee => assignee.relyingPartyAdmin) || []}
                          ref={followUpFormRef}
                          hideSubmitButton
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
        <Button type="submit" a11yLabel={'Submit'} appearance="primary" disabled={disableSubmitButton} />
        <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={onReset} />
      </DialogActions>
    </form>
  );
};

export default EncounterDialogForm;
