import type { ReadingCategory } from '@/graphql/remotePatientMonitoring';
import type { KpiFilterType } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { Box } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { EchartBarChart } from '../Charts/charts';
import WidgetContainer from './WidgetContainer';

interface DeviceReadingWidgetProps {
  readings: Required<ReadingCategory>[];
  title: string;
  handleTabChangeBasedOnType: (rpmStatus: RpmStatusTypes) => void;
  setFilteredPatientsByCharts: Dispatch<SetStateAction<KpiFilterType[]>>;
}

const DeviceReadingWidget = ({ readings, title, handleTabChangeBasedOnType, setFilteredPatientsByCharts }: DeviceReadingWidgetProps) => {
  const filterPatients = params => {
    const index = params.dataIndex;
    const clickedItem = chartData[index];
    const name = params.name;

    const truentityIds = clickedItem.accounts.map(account => account.truentityId).filter(truentityId => truentityId !== undefined);

    handleTabChangeBasedOnType(RpmStatusTypes.ENROLLED);
    setFilteredPatientsByCharts([{ filterBy: name, truentityIds: truentityIds }]);
  };

  const chartData =
    readings?.map(item => ({
      name: item.name,
      value: item.value,
      accounts: item.accounts
    })) || [];

  const colors =
    title === 'Device Readings Tracker'
      ? ['#387F39', '#F1C40F', '#EB5353', '#AFAFB5']
      : ['#387F39', '#97BE67', '#BEDC74', '#F1C40F', '#EB5353'];

  return (
    <WidgetContainer title={title} maxWidth="500px" minWidth="300px">
      <Box sx={{ marginY: -7, marginX: -7 }}>
        <EchartBarChart
          data={chartData}
          categories={readings?.map(item => item.name)}
          colors={colors}
          height="370px"
          width="100%"
          horizontal={true}
          onBarClick={filterPatients}
        />
      </Box>
    </WidgetContainer>
  );
};

export default DeviceReadingWidget;
