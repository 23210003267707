import Button from '@/components/Button';
import { FollowUpAddEditDialog } from '@/components/Dialogs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import InformationDialog from '@/components/Dialogs/InformationDialog';
import MultiStepConfirmationDialog from '@/components/Dialogs/MultiStepConfirmationDialog';
import PatientStartVisitDialog from '@/components/Dialogs/PatientStartVisitDialog';
import ScheduleEnrollmentDialog from '@/components/Dialogs/ScheduleEnrollmentDialog';
import MuiMenu from '@/components/MuiMenu';
import PatientDetailContext from '@/context/patientDetailContext';
import { DELETE_ACCOUNT_FOLLOWUP, FollowUpStatusTypes, FollowUpTypes } from '@/graphql/account';
import type { ChangeAccountTelehealthVisitLaunchedFlagResponse, UpdateCandidateStatusResponse } from '@/graphql/remotePatientMonitoring';
import { CHANGE_ACCOUNT_TELEHEALTH_VISIT_LAUNCHED_FLAG, UPDATE_RPM_CANDIDATE_STATUS } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import ButtonPopover from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/ButtonPopover';
import type { PatientsData } from '@/routes/Patients/patients';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { Role } from '@/types/admin';
import type { LoggedUser } from '@/types/graphql';
import { RpmApprovalStatusTypes, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { mandatoryTabsForEnrolment } from '@/util/constants';
import { clusterFollowupsByDate } from '@/util/followUp';
import { formatDate, formatDateAndTime } from '@/util/format';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import type { FetchResult } from '@apollo/client';
import { useMutation, useReactiveVar } from '@apollo/client';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import { Box, Stack, Tooltip } from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type ModalType = RpmStatusTypes.ENROLLED | RpmStatusTypes.UNENROLLED;

const RpmWorkflowStatusBar = () => {
  const { id } = useParams();
  const { roleType } = useToken();
  const { showModal, hideModal } = useModal();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isEnrollmentEligibility } = useRpmSetupStore();
  const isProviderAdmin = useMemo(() => roleType === Role.PROVIDER, [roleType]);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const confirmationDialogId = useRef<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isCandidateOrScheduledPatent = useMemo(
    () => patientInfo?.rpmStatus === RpmStatusTypes.IS_CANDIDATE || patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    [patientInfo]
  );
  const isScheduledPatient = useMemo(() => patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT, [patientInfo]);
  const isAlreadyEnrolledPatient = useMemo(() => !!patientInfo?.rpmEnrolledAt && !!patientInfo?.rpmUnenrolledAt, [patientInfo]);

  const [updateCandidateStatus, { loading: loadingCandidateStatus }] = useMutation<UpdateCandidateStatusResponse>(
    UPDATE_RPM_CANDIDATE_STATUS,
    {
      onError: () => enqueueSnackbar(`This patient's status can not be changed, Please contact support`, { variant: 'error' })
    }
  );
  const [deleteFollowup] = useMutation(DELETE_ACCOUNT_FOLLOWUP);
  const [changeAccountTelehealthFlag] = useMutation<ChangeAccountTelehealthVisitLaunchedFlagResponse>(
    CHANGE_ACCOUNT_TELEHEALTH_VISIT_LAUNCHED_FLAG
  );

  const onStartTelehealthVisit = async (keepModal?: boolean) => {
    try {
      const response = await changeAccountTelehealthFlag({
        variables: {
          truentityId: id,
          isAccountTelehealthVisitLaunched: true
        }
      });

      if (response?.data?.changeAccountTelehealthVisitLaunchedFlag?.account?.accountSummary?.isTelehealthVisitLaunched) {
        setReloadPatientInfo(true);
      } else {
        enqueueSnackbar('Unable to start visit, Please contact support', { variant: 'error' });
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Unable to start visit, Please contact support', { variant: 'error' });
    } finally {
      if (!keepModal) {
        hidePrevModal();
      }
    }
  };

  const statusBarType: ModalType =
    patientInfo?.rpmStatus === RpmStatusTypes.IS_CANDIDATE ||
    patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT ||
    patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED
      ? RpmStatusTypes.ENROLLED
      : patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED
      ? RpmStatusTypes.UNENROLLED
      : RpmStatusTypes.ENROLLED; // Assigning a default value here

  const statusText = statusBarType === RpmStatusTypes.ENROLLED ? 'Enroll' : 'Unenroll';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBackToCandidate = () => {
    const modal = showModal(ConfirmDialog, {
      title: `Are you sure you want to move this patient back to candidate status?`,
      message: 'This patient will be moved back to candidate status, and the RPM Initial follow-up will not be removed.',
      onAgree: () => {
        updateCandidateStatus({
          variables: {
            truentityIds: [id],
            rpmStatus: RpmStatusTypes.IS_CANDIDATE
          }
        }).then(res => {
          handleSnackbar(RpmStatusTypes.IS_CANDIDATE, res);
          modal.hide();
        });
      },
      onDisagree: () => modal.hide()
    });
  };

  const handleSnackbar = useCallback(
    (rpmStatus: string, res: FetchResult<UpdateCandidateStatusResponse>) => {
      const updateCandidateStatus = res?.data?.updateCandidateStatus;

      if (updateCandidateStatus?.status === 'Success') {
        enqueueSnackbar(`Status changed to '${rpmStatus}' successfully.`, { variant: 'success' });

        setReloadPatientInfo(true);
      } else {
        enqueueSnackbar(`Status can not be changed to '${rpmStatus}'.`, { variant: 'error' });
      }
    },
    [setReloadPatientInfo, enqueueSnackbar]
  );

  const handleTelehealthVisitButton = () => {
    const modal = showModal(PatientStartVisitDialog, {
      title: 'Preparing for Telehealth Visit',
      patientInfo: patientInfo,
      onStartTelehealthVisit: onStartTelehealthVisit,
      hideDialog: () => {
        modal.hide();
      }
    });
    confirmationDialogId.current = modal.id;
  };

  const hidePrevModal = () => {
    confirmationDialogId.current && hideModal(confirmationDialogId.current);
  };

  const showInformationDialog = (title: string, message: string) => {
    const modal = showModal(InformationDialog, {
      title,
      message,
      actions: [
        {
          name: 'Close',
          action: () => {
            modal.hide();
          }
        }
      ]
    });
  };

  const openConfirmationModal = useCallback(
    (modalType: ModalType) => {
      const nextData = formatDate(moment().add(1, 'days'));
      const title = `Are you sure you want to ${statusText.toLowerCase()} this patient?`;
      const message =
        modalType === RpmStatusTypes.ENROLLED
          ? isAlreadyEnrolledPatient
            ? `The patient was previously enrolled on <b>${formatDate(
                patientInfo?.rpmEnrolledAt
              )}</b>. Are you sure you want to proceed with re-enrollment? Please note that this will restore all their previously tracked data and the date of enrollment will be set to <b>${formatDate(
                patientInfo?.rpmEnrolledAt
              )}</b>.`
            : `All device readings will be monitored starting tomorrow, ${nextData}.`
          : "This step will unenroll the patient from this program. Please have the patient return any devices that were supplied. These devices will also be removed from the patient's account. All prior collected data will be archived. Are you sure you want to continue?";

      if (modalType === RpmStatusTypes.UNENROLLED) {
        const modal = showModal(MultiStepConfirmationDialog, {
          title,
          message,
          onAgree: () => {
            updateCandidateStatus({
              variables: {
                truentityIds: [id],
                rpmStatus: modalType
              }
            }).then(res => handleSnackbar(RpmStatusTypes.UNENROLLED, res));
            modal.hide();
          },
          onDisagree: () => {
            modal.hide();
          }
        });
      } else {
        const modal = showModal(ConfirmDialog, {
          title,
          message,
          onAgree: () => {
            updateCandidateStatus({
              variables: {
                truentityIds: [id],
                rpmStatus: modalType
              }
            }).then(res => handleSnackbar(RpmStatusTypes.ENROLLED, res));
            modal.hide();
          },
          onDisagree: () => {
            modal.hide();
          }
        });
      }
    },
    [statusText, isAlreadyEnrolledPatient, patientInfo?.rpmEnrolledAt, showModal, updateCandidateStatus, id, handleSnackbar]
  );

  const isCurrentUsersFollowup = useCallback((followup: FollowUpRemindersTypes, currentUser: LoggedUser | null) => {
    return currentUser?.id === followup.relyingPartyAdmin.id;
  }, []);

  const cancelFollowup = useCallback(
    (followups: FollowUpRemindersTypes[]) => {
      const followupIds = followups.map(followup => followup.id);
      const followupDate = formatDate(followups?.[0]?.followUpOn);

      const modal = showModal(ConfirmDialog, {
        title: followupIds.length > 1 ? 'Delete All Initial Visit Follow-ups' : 'Delete Initial Visit Follow-up',
        message:
          followupIds.length > 1
            ? `Are you sure you want to delete all initial visit follow-ups scheduled for ${followupDate}? This action is irreversible. If you need to delete a specific follow-up, please proceed manually.`
            : `Are you sure you want to delete the initial visit follow-up scheduled for ${followupDate}? This action is irreversible.`,
        onAgree: async () => {
          await deleteFollowup({ variables: { followUpIds: followupIds } })
            .then(response => {
              const data = response.data.removeAccountFollowup;
              const variant = data?.status === 'Success' ? 'success' : 'error';
              enqueueSnackbar(data.message, { variant });
              modal.hide();
              setReloadPatientInfo(true);
            })
            .catch(() => {
              enqueueSnackbar('Failed to delete this follow-up', {
                variant: 'error'
              });
              modal.hide();
            });
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [deleteFollowup, enqueueSnackbar, setReloadPatientInfo, showModal]
  );

  const showEditFollowupModal = useCallback(
    (followups: FollowUpRemindersTypes[], patientInfo: PatientsData) => {
      const followupAssignments = patientInfo?.currentAccountsAssignments;

      const modal = showModal(FollowUpAddEditDialog, {
        title: 'Update Follow Up On',
        hideDialog: () => modal.hide(),
        handleRefetchAndClose: () => {
          modal.hide();
          setReloadPatientInfo(true);
        },
        truentityId: patientInfo?.truentityId,
        rpmStatus: patientInfo.rpmStatus,
        doNotCall: patientInfo?.doNotCall,
        followupsData: followups,
        accountAssignments: followupAssignments,
        isInitialVisitFollowupForm: true,
        isUpdateModal: true
      });
    },
    [setReloadPatientInfo, showModal]
  );

  const showConfirmationDialog = useCallback(
    (followups: FollowUpRemindersTypes[], onAgreeCallback: () => void) => {
      const initialVisitFollowUpCount = followups.length;
      const followupOnDate = formatDate(followups?.[0]?.followUpOn);
      const modal = showModal(ConfirmDialog, {
        title: 'Confirmation Required',
        message: `There are <b>${initialVisitFollowUpCount} follow-up${
          initialVisitFollowUpCount > 1 ? 's' : ''
        }</b> scheduled for <b>${followupOnDate}</b> with the same type. This action will modify all associated follow-ups. Are you sure you want to proceed?`,
        onAgree: () => {
          modal.hide();
          onAgreeCallback();
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [showModal]
  );

  const handleEditSchedule = useCallback(
    (followups: FollowUpRemindersTypes[]) => {
      if (!patientInfo) return console.error('Cannot identify patient. Please contact support.');

      const editModalCallback = () => showEditFollowupModal(followups, patientInfo);

      if (followups.length > 0) {
        showConfirmationDialog(followups, editModalCallback);
      } else {
        showEditFollowupModal(followups, patientInfo);
      }
    },
    [patientInfo, showConfirmationDialog, showEditFollowupModal]
  );

  const handleCancelSchedule = useCallback(
    (followups: FollowUpRemindersTypes[]) => {
      if (!patientInfo) return console.error('Cannot identify patient. Please contact support.');

      const deleteModalCallback = () => cancelFollowup(followups);

      if (followups.length > 0) {
        showConfirmationDialog(followups, deleteModalCallback);
      } else {
        cancelFollowup(followups);
      }
    },
    [cancelFollowup, patientInfo, showConfirmationDialog]
  );

  const handleScheduleForEnrollment = useCallback(() => {
    if (!patientInfo) return;

    const patientName = getAccountUserFullName(patientInfo?.user);
    const modal = showModal(ScheduleEnrollmentDialog, {
      title: `Schedule for Enrollment: ${patientName} `,
      patientInfo: patientInfo,
      hideDialog: () => modal.hide(),
      refetch: () => setReloadPatientInfo(true)
    });
  }, [patientInfo, showModal, setReloadPatientInfo]);

  const initialVisitFollowUps = useMemo(() => {
    if (!patientInfo || !patientInfo.accountsFollowupReminders || !currentUser) return [] as FollowUpRemindersTypes[];

    return patientInfo?.accountsFollowupReminders.filter(
      followup => followup.type === FollowUpTypes.RPM_INITIAL_VISIT && followup.followUpOn
    );
  }, [currentUser, patientInfo]);

  const clusteredInitialVisitFollowups = useMemo(() => {
    return clusterFollowupsByDate(initialVisitFollowUps) || [];
  }, [initialVisitFollowUps]);

  const initialVisitFollowUp = useMemo(() => {
    const currentUserFollowUp = clusteredInitialVisitFollowups?.find(followup => isCurrentUsersFollowup(followup, currentUser));

    return currentUserFollowUp || clusteredInitialVisitFollowups?.[0] || [];
  }, [currentUser, isCurrentUsersFollowup, clusteredInitialVisitFollowups]);

  const isAllInitialFollowupsCompleted = useMemo(() => {
    if (initialVisitFollowUps.length === 0) {
      return false;
    } else {
      return initialVisitFollowUps?.every(initialVisitFollowUp => initialVisitFollowUp.status === FollowUpStatusTypes.COMPLETED) || false;
    }
  }, [initialVisitFollowUps]);

  const isInitialFollowupScheduled = useMemo(
    () => isCandidateOrScheduledPatent && Object.keys(initialVisitFollowUp).length > 0 && !isAllInitialFollowupsCompleted,
    [isCandidateOrScheduledPatent, initialVisitFollowUp, isAllInitialFollowupsCompleted]
  );

  const getOnClickFunction = useCallback(
    e => {
      if (!isEnrollmentEligibility && (isCandidateOrScheduledPatent || patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED)) {
        return handleClick(e);
      }

      if (patientInfo?.rpmApprovalStatus !== RpmApprovalStatusTypes.APPROVED && patientInfo?.rpmStatus !== RpmStatusTypes.ENROLLED) {
        return showInformationDialog(
          'Patient cannot be enrolled at the moment',
          'Patient needs to be approved in order to enroll this patient.'
        );
      }

      openConfirmationModal(statusBarType);
    },
    [
      isEnrollmentEligibility,
      isCandidateOrScheduledPatent,
      patientInfo?.rpmStatus,
      patientInfo?.rpmApprovalStatus,
      openConfirmationModal,
      statusBarType,
      showInformationDialog
    ]
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {isScheduledPatient && !isProviderAdmin ? (
          <Tooltip title={patientInfo?.accountRpmMonitoredCondition?.length === 0 ? "Please select a 'Reason for Visit' to continue." : ''}>
            <Box>
              <Button
                variant={'outlined'}
                color="success"
                startIcon={<VideoChatIcon />}
                disabled={patientInfo?.accountRpmMonitoredCondition?.length === 0}
                onClick={handleTelehealthVisitButton}
              >
                Start Visit
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Box flex={1} />
        )}

        <Stack direction="row" justifyContent={'flex-end'} alignItems="center" p="0.5rem 1.5rem" borderRadius="0.5rem" gap={1}>
          {isCandidateOrScheduledPatent && (
            <>
              {!isProviderAdmin && (
                <>
                  {isInitialFollowupScheduled ? (
                    <MuiMenu
                      label={`Scheduled for ${formatDateAndTime(initialVisitFollowUp?.followUpOn)}`}
                      variant="outlined"
                      options={[
                        {
                          label: 'Edit Schedule',
                          onAction: () => handleEditSchedule(clusteredInitialVisitFollowups)
                        },
                        {
                          label: 'Cancel Schedule',
                          onAction: () => handleCancelSchedule(clusteredInitialVisitFollowups)
                        }
                      ]}
                    />
                  ) : (
                    <Button variant="outlined" onClick={handleScheduleForEnrollment}>
                      {isAllInitialFollowupsCompleted ? 'Re-schedule' : 'Schedule'} for Enrollment
                    </Button>
                  )}
                  {patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT && (
                    <Button variant="outlined" onClick={handleBackToCandidate}>
                      Revert to Candidate
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          {!isProviderAdmin && (
            <Button isLoading={loadingCandidateStatus} onClick={e => getOnClickFunction(e)}>
              {statusText}
            </Button>
          )}
        </Stack>
      </Stack>
      <ButtonPopover
        id={'button-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        mandatoryTabs={mandatoryTabsForEnrolment}
      />
    </>
  );
};

export default RpmWorkflowStatusBar;
