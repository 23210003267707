import colors from '@/styles/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { ChipProps } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary, Chip } from '@mui/material';
import type { ReactNode, SyntheticEvent } from 'react';
import { H4 } from './Typography';

export type AccordionOption = {
  label: ReactNode | string;
  secondLabel?: ReactNode | string;
  secondaryLabel?: string;
  chipLabel?: string;
  chipProps?: ChipProps;
  content: ReactNode;
  defaultExpand: boolean;
  expanded?: boolean;
  secondaryCustomLabel?: ReactNode;
  disabled?: boolean;
  onChange?: (() => void) | ((event: SyntheticEvent, isExpanded: boolean) => void);
};

export type AccordionProp = {
  options: AccordionOption[];
  sx?: any;
  detailsSx?: any;
  disableGutters?: boolean;
};

const MuiAccordion = ({ options, detailsSx, ...props }: AccordionProp) => {
  return (
    <>
      {options.map((option, index) => {
        return (
          <Accordion
            key={index}
            defaultExpanded={option.defaultExpand}
            expanded={option.expanded}
            onChange={option.onChange}
            disabled={option.disabled || false}
            {...props}
          >
            <AccordionSummary
              sx={{
                boxShadow: `inset 0 -1px 0 0 ${colors.neutralA8}`,
                display: 'flex',
                justifyContent: 'space-between'
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${option.label}-header-content-${index}`}
              id={`${option.label}-header-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <H4 style={{ display: 'inline-block', marginRight: 'auto' }}>{option.label}</H4>
                {option?.secondLabel && (
                  <H4 style={{ display: 'inline-block', marginLeft: '10px', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                    {option?.secondLabel}
                  </H4>
                )}
              </div>
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                {option.chipLabel && (
                  <div style={{ marginRight: '10px' }}>
                    <Chip label={option.chipLabel} variant="outlined" sx={{ width: 'fit-content' }} {...option?.chipProps} />
                  </div>
                )}
                {option.secondaryLabel && (
                  <H4 style={{ display: 'inline-block', fontSize: '14px', fontWeight: 400, lineHeight: '32px' }}>
                    {option.secondaryLabel}
                  </H4>
                )}
                {option.secondaryCustomLabel && option.secondaryCustomLabel}
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: detailsSx?.backgroundColor ?? '#fafafa', padding: detailsSx?.padding ?? 1, ...detailsSx }}
            >
              {option.content}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default MuiAccordion;
