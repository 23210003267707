import AlertsWidget from '@/components/KpiWidgets/AlertsWidget';
import DeviceReadingWidget from '@/components/KpiWidgets/DeviceReadingWidget';
import { EnrollmentsWidget } from '@/components/KpiWidgets/EnrollmentsWidget';
import SkeletonLoader from '@/components/SkeletonLoader';
import { Body1, H3 } from '@/components/Typography';
import type { GetRpmKpiStatisticsQueryResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_KPI_STATISTICS_QUERY } from '@/graphql/remotePatientMonitoring';
import type { LoggedUser } from '@/types/graphql';
import type { KpiFilterType, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { formatTime, getCurrentDate } from '@/util/format';
import { useQuery } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Grid } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  currentUser: LoggedUser | null;
  handleTabChangeBasedOnType: (rpmStatus: RpmStatusTypes) => void;
  setFilteredPatientsByCharts: Dispatch<SetStateAction<KpiFilterType[]>>;
};

const RpmMyPatientKpiSection = ({ currentUser, handleTabChangeBasedOnType, setFilteredPatientsByCharts }: Props) => {
  const [isKpiAvailable, setIsKpiAvailable] = useState(false);
  const [isKpiVisible, setIsKpiVisible] = useState<boolean>(true);

  const { data: rpmKpiStatisticsData, loading: rpmKpiStatisticsLoading } = useQuery<GetRpmKpiStatisticsQueryResponse>(
    GET_RPM_KPI_STATISTICS_QUERY,
    {
      skip: !isKpiAvailable,
      fetchPolicy: 'cache-and-network'
    }
  );

  const toggleKpiVisibility = () => {
    setIsKpiVisible(prev => !prev);
  };

  useEffect(() => {
    try {
      const settings = currentUser?.relyingParty?.settings;
      const settingsObject = settings ? JSON.parse(settings) : {};

      setIsKpiAvailable(settingsObject?.AdditionalFeatures?.kpi || false);
    } catch (error) {
      return;
    }
  }, [currentUser]);

  if (!isKpiAvailable) return null;

  return (
    <Box
      sx={{
        padding: 1
      }}
    >
      {isKpiVisible && (
        <>
          <H3 gutterBottom>{formatTime(getCurrentDate(), 'MMMM YYYY')}</H3>
          <Grid container spacing={2} mt={1} mb={2}>
            <Grid item xs={4}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  {!rpmKpiStatisticsLoading ? (
                    <AlertsWidget
                      criticalHighCount={rpmKpiStatisticsData?.getRpmKpiStatistics?.criticallyHigh ?? 0}
                      criticalLowCount={rpmKpiStatisticsData?.getRpmKpiStatistics?.criticallyLow ?? 0}
                      medRegimenCount={rpmKpiStatisticsData?.getRpmKpiStatistics?.medRegimenCount ?? 0}
                      noReadingsCount={rpmKpiStatisticsData?.getRpmKpiStatistics?.noReadings ?? 0}
                    />
                  ) : (
                    <SkeletonLoader width="100%" height={120} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!rpmKpiStatisticsLoading ? (
                    <EnrollmentsWidget
                      enrolledThisMonth={rpmKpiStatisticsData?.getRpmKpiStatistics?.enrolledThisMonth ?? 0}
                      enrolledYesterday={rpmKpiStatisticsData?.getRpmKpiStatistics?.enrolledYesterday ?? 0}
                      enrolledTotal={rpmKpiStatisticsData?.getRpmKpiStatistics?.enrolledTotal ?? 0}
                    />
                  ) : (
                    <SkeletonLoader width="100%" height={120} />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Grid sx={{ height: '100%' }}>
                {!rpmKpiStatisticsLoading && rpmKpiStatisticsData ? (
                  <DeviceReadingWidget
                    readings={rpmKpiStatisticsData?.getRpmKpiStatistics?.readingsCategories || []}
                    title="Device Readings Tracker"
                    handleTabChangeBasedOnType={handleTabChangeBasedOnType}
                    setFilteredPatientsByCharts={setFilteredPatientsByCharts}
                  />
                ) : (
                  <SkeletonLoader width="100%" height="100%" />
                )}
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Grid sx={{ height: '100%' }}>
                {!rpmKpiStatisticsLoading && rpmKpiStatisticsData ? (
                  <DeviceReadingWidget
                    readings={rpmKpiStatisticsData?.getRpmKpiStatistics?.treatmentsCategories || []}
                    title="Care Management Tracker"
                    handleTabChangeBasedOnType={handleTabChangeBasedOnType}
                    setFilteredPatientsByCharts={setFilteredPatientsByCharts}
                  />
                ) : (
                  <SkeletonLoader width="100%" height="100%" />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleKpiVisibility}>
          <Body1 color="primary" fontWeight="bold" sx={{ textTransform: 'uppercase' }}>
            {isKpiVisible ? 'Hide Patient KPI' : 'View Patient KPI'}
          </Body1>
          {isKpiVisible ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
        </Box>
      </Box>
    </Box>
  );
};

export default RpmMyPatientKpiSection;
