import Button from '@/components/Button';
import type { JSONData } from '@/components/JsonKit/JsonEditor';
import JsonEditor from '@/components/JsonKit/JsonEditor';
import type { AccordionOption } from '@/components/MuiAccordion';
import MuiAccordion from '@/components/MuiAccordion';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { Body1, Body2, H3, H4, H5 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetAthenaMckApisResponse } from '@/graphql/administration';
import { GET_ATHENA_MOCK_APIS, TRIGGER_ATHENA_MOCK_API } from '@/graphql/administration';
import { isJSON } from '@/util/json';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';

type RefactorMockApiDataType = Omit<GetAthenaMckApisResponse['getAthenaApis'][0], 'parameters'> & {
  parameters: JSONData;
  response: JSONData;
  isLoading?: boolean;
};

const MockApis = () => {
  const { setContent } = useContext(SideMenuContext);
  const { enqueueSnackbar } = useSnackbar();

  const [mockApiData, setMockApiData] = useState<RefactorMockApiDataType[]>([]);
  const [accordionProps, setAccordionProps] = useState<{ [key: string]: AccordionOption[] }>({});

  useQuery<GetAthenaMckApisResponse>(GET_ATHENA_MOCK_APIS, {
    onCompleted: data => setMockApiData(refactorMockApiData(data.getAthenaApis))
  });
  const [triggerMockAPI] = useMutation(TRIGGER_ATHENA_MOCK_API);

  const refactorMockApiData = (mockEndpointData: GetAthenaMckApisResponse['getAthenaApis']): RefactorMockApiDataType[] => {
    return mockEndpointData.map(data => {
      const parameters: JSONData = {};
      data.parameters.forEach(param => {
        let defaultValue;

        switch (param.type) {
          case 'boolean':
          case 'Boolean':
            defaultValue = Boolean(param.defaultValue) ?? false;
            break;
          case 'integer':
          case 'Integer':
            defaultValue = parseInt(param.defaultValue) ?? 0;
            break;
          case 'string':
          case 'String':
            defaultValue = param.defaultValue ?? '';
            break;
          default:
            defaultValue = param.defaultValue ?? null;
            break;
        }
        parameters[param.name] = defaultValue;
      });

      return {
        ...data,
        parameters: parameters,
        response: {}
      };
    });
  };

  const handleJsonUpdate = useCallback(
    (changedValues: Record<string, unknown>, key: string) => {
      const { updated_src } = changedValues;
      const updatedValue = JSON.stringify(updated_src);

      if (updated_src && isJSON(updatedValue)) {
        const updatedData = mockApiData.map(apiData => {
          if (apiData.name === key) {
            return {
              ...apiData,
              parameters: updated_src as JSONData
            };
          }
          return apiData;
        });
        setMockApiData(updatedData);
      }
    },
    [mockApiData]
  );

  const handleButton = useCallback(
    async (data: RefactorMockApiDataType) => {
      const updatedData = { ...data, isLoading: true };
      setMockApiData(prevData => prevData.map(api => (api.name === data.name ? updatedData : api)));

      try {
        const res = await triggerMockAPI({
          variables: {
            apiName: data.name,
            parameters: data.parameters
          }
        });

        const response = res?.data?.triggerAthenaEndpoints?.response;
        const error = res?.data?.triggerAthenaEndpoints?.error;

        if (response) {
          updatedData.response = response;
        } else {
          console.log(error);

          updatedData.response = error;
          enqueueSnackbar('There is an issue with this API. Please contact support.', { variant: 'error' });
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar('There is an issue with this API. Please contact support.', { variant: 'error' });
      } finally {
        updatedData.isLoading = false;
        setMockApiData(prevData => prevData.map(api => (api.name === data.name ? updatedData : api)));
      }
    },
    [enqueueSnackbar, triggerMockAPI]
  );

  const handleClearButton = (data: RefactorMockApiDataType) => {
    const updatedData: RefactorMockApiDataType = { ...data, response: {} };
    setMockApiData(prevData => prevData.map(api => (api.name === data.name ? updatedData : api)));
  };

  useEffect(() => {
    if (!mockApiData || mockApiData.length === 0) return;

    const groupedData = mockApiData.reduce((acc: { [key: string]: AccordionOption[] }, mockAPI: RefactorMockApiDataType) => {
      if (!acc[mockAPI.category]) {
        acc[mockAPI.category] = [];
      }

      acc[mockAPI.category].push({
        label: (
          <Stack direction="row" spacing={1}>
            <H4 color="primary">{mockAPI.endpointMethod}</H4>
            <H5>{mockAPI.endpoint}</H5>
          </Stack>
        ),
        defaultExpand: false,
        content: (
          <Stack padding={2} spacing={3}>
            <Stack direction="row" spacing={2}>
              <Body1 sx={{ fontWeight: 'bold' }}>Method:</Body1>
              <Body1 color="primary">{mockAPI.endpointMethod}</Body1>
            </Stack>
            <Body2 color="text.secondary">{mockAPI.description}</Body2>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <H5>Parameters</H5>
                <Button onClick={() => handleButton(mockAPI)} isLoading={mockAPI.isLoading}>
                  Try it out
                </Button>
              </Stack>
              <JsonEditor
                src={mockAPI.parameters}
                onAdd={e => handleJsonUpdate(e, mockAPI.name)}
                onDelete={e => handleJsonUpdate(e, mockAPI.name)}
                onEdit={e => handleJsonUpdate(e, mockAPI.name)}
              />
            </Stack>

            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <H5>Response</H5>
                <Button size="small" variant="outlined" onClick={() => handleClearButton(mockAPI)}>
                  Clear
                </Button>
              </Stack>
              <JsonEditor src={mockAPI.response} isDisabled={true} />
            </Stack>
          </Stack>
        )
      });

      return acc;
    }, {});

    setAccordionProps(groupedData);
  }, [mockApiData, handleJsonUpdate, handleButton]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return (
    <Stack spacing={4} sx={{ p: 2 }}>
      <Paper sx={{ padding: 2 }} component={Stack} flexDirection="row" alignItems="center" justifyContent="space-between" elevation={1}>
        <H3>Athena APIs Documentation</H3>
      </Paper>

      <Stack component={Paper} spacing={2} sx={{ padding: 3 }} elevation={1}>
        {Object.entries(accordionProps).map(([key, value]) => (
          <Box key={key}>
            <H4>{key}</H4>
            <MuiAccordion sx={{ mt: 1 }} options={value} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default MockApis;
