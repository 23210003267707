import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { FollowUpStatusTypes, FollowUpSubTypes, FollowUpTypes } from '@/graphql/account';
import type { CreateRpmFollowupConsultResponse } from '@/graphql/rpmWorkflow';
import { CREATE_RPM_FOLLOWUP_CONSULT } from '@/graphql/rpmWorkflow';
import type { AddFollowupConsultFormType } from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/AddFollowupConsultForm';
import AddFollowupConsultForm from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/AddFollowupConsultForm';
import { color } from '@/styles/assets/colors';
import { formatDateAndTime2 } from '@/util/format';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from '../Button';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  onComplete: () => void;
};

const AddFollowupConsultDialog: React.FC<Props> = ({ title, onComplete, hideDialog, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [createRpmFollowupConsult] = useMutation<CreateRpmFollowupConsultResponse>(CREATE_RPM_FOLLOWUP_CONSULT);

  const { control, reset, handleSubmit } = useForm<AddFollowupConsultFormType>({
    defaultValues: {
      followupConsultDate: null,
      followupConsultTime: null,
      calendarFollowupType: FollowUpTypes.FOLLOW_UP_VISIT,
      calendarFollowupSubType: FollowUpSubTypes.RPM,
      calendarFollowupStatus: FollowUpStatusTypes.PENDING,
      calendarFollowupNote: ''
    }
  });

  const onSubmit: SubmitHandler<AddFollowupConsultFormType> = data => handleAddFollowupConsultSubmit(data);

  const setFormError = (message: string): boolean => {
    console.log('setFormError: ', message);
    enqueueSnackbar(message, {
      variant: 'error'
    });
    return false;
  };

  const validateFollowupConsultForm = (values: AddFollowupConsultFormType): boolean => {
    if (values.followupConsultDate === null) {
      return setFormError('Follow-up date is required');
    } else if (values.followupConsultTime === null) {
      return setFormError('Follow-up time is required');
    } else {
      return true;
    }
  };

  const handleAddFollowupConsultSubmit = async (values: AddFollowupConsultFormType) => {
    try {
      if (validateFollowupConsultForm(values)) {
        const dateTime = formatDateAndTime2(values.followupConsultDate, values.followupConsultTime);
        const response = await createRpmFollowupConsult({
          variables: {
            rpmFollowupConsultInput: {
              truentityId: id,
              consultAt: dateTime,
              accountFollowupInput: {
                type: values.calendarFollowupType,
                subType: values.calendarFollowupSubType,
                note: values.calendarFollowupNote,
                status: values.calendarFollowupStatus
              }
            }
          }
        });
        if (response.data?.createRpmFollowupConsult?.status === 'Success') {
          enqueueSnackbar('RPM Follow-up Visit added successfully.', {
            variant: 'success'
          });
          onComplete();
        } else {
          enqueueSnackbar(response.data?.createRpmFollowupConsult?.message ?? 'Could not add a new Follow-up Visit', {
            variant: 'error'
          });
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Could not add a new Follow-up Visit', {
        variant: 'error'
      });
    }
  };

  const handleAddFollowupConsultCancel = () => {
    reset();
    hideDialog();
  };

  return (
    <BaseDialog hideDialog={hideDialog} maxWidth={'md'} fullWidth {...props} title={title}>
      <DialogContent sx={{ backgroundColor: color.grey100 }}>
        <AddFollowupConsultForm control={control} onSubmit={handleSubmit(onSubmit)} />
        <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
          <Button type="submit" a11yLabel="Save" onClick={handleSubmit(onSubmit)} appearance="primary" />
          <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={handleAddFollowupConsultCancel} />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddFollowupConsultDialog;
