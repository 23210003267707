import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import IcdDescriptionDialog from '@/components/Dialogs/IcdDescriptionDialog';
import { H3 } from '@/components/Typography';
import Link from '@/elements/Link';
import type { GetRpmClaimsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_CLAIMS } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { MedicalServices } from '@/types/admin';
import type { RpmClaimsType } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { checkIfCurrentMonth, formatDate, formatDateIgnoreTZ, formatTime } from '@/util/format';
import { pathnameIncludes } from '@/util/location';
import { getClaimsChipColor } from '@/util/rpm';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Chip, IconButton, LinearProgress, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

type Props = {
  monthAndYear: DateInput;
  scheduleButtonClicked: boolean;
  claimsData: RpmClaimsType[];
  setClaimsData: Dispatch<SetStateAction<RpmClaimsType[]>>;
  handleScheduleReport: () => Promise<void>;
};

const csvHeaders = [
  { label: 'EHR ID', key: 'ehrId' },
  { label: 'DOB', key: 'dob' },
  { label: 'FIRST NAME', key: 'firstName' },
  { label: 'LAST NAME', key: 'lastName' },
  { label: 'TYPE', key: 'type' },
  { label: 'ICD10 CODE', key: 'icd10Code' },
  { label: 'ICD10 DESCRIPTION', key: 'icd10Description' },
  { label: 'DATE OF SERVICE', key: 'dateOfService' },
  { label: 'PROVIDER NAME', key: 'providerName' },
  { label: 'PROVIDER NPI', key: 'providerNPI' }
];

const RpmClaims = ({ claimsData, monthAndYear, scheduleButtonClicked, setClaimsData, handleScheduleReport }: Props) => {
  const isRpmBillingPage = useMemo(() => pathnameIncludes('/reports/rpm-billing'), []);
  const modifiedCsvHeaders = useMemo(() => {
    if (isRpmBillingPage) {
      csvHeaders.splice(1, 0, { label: 'COMPANY', key: 'company' });
      return csvHeaders;
    } else {
      return csvHeaders;
    }
  }, [isRpmBillingPage]);

  const { showModal } = useModal();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);

  const [getRpmClaims, { data: rpmClaimsData, loading: rpmClaimsLoading }] = useLazyQuery<GetRpmClaimsResponse>(GET_CLAIMS, {
    fetchPolicy: 'cache-and-network'
  });

  const callClaimsQuery = useCallback(
    async (isExport: boolean) => {
      await getRpmClaims({
        variables: {
          monthYear: formatTime(monthAndYear, 'MMM YYYY'),
          pageNum: isExport ? 1 : currentPage + 1,
          pageSize: isExport ? 100 : DEFAULT_PAGE_SIZE,
          isExport: isExport,
          all: isRpmBillingPage
        }
      }).catch((error: ApolloError) => {
        console.error(error);
      });
    },
    [currentPage, getRpmClaims, isRpmBillingPage, monthAndYear]
  );

  const onFetchExportButtonClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv();
  };

  const exportDataToCsv = () => {
    callClaimsQuery(true);
  };

  const getRpmClaimsDataFiltered = () => {
    const DEFAULT_STRING = 'Not Provided';
    const EMPTY_STRING = '-';
    const includeCompany = isRpmBillingPage;

    return claimsData?.map(data => {
      const baseData = {
        ehrId: data?.ehrId || DEFAULT_STRING,
        dob: data?.account.birthDate || DEFAULT_STRING,
        firstName: data?.account?.user.firstName || DEFAULT_STRING,
        lastName: data.account?.user.lastName || DEFAULT_STRING,
        type: data?.cptCode && data?.description ? `${data.cptCode} - ${data.description}` : DEFAULT_STRING,
        icd10Code: data?.diagnosesCodes || DEFAULT_STRING,
        icd10Description: data?.diagnosesNames || DEFAULT_STRING,
        dateOfService: formatDate(data?.dateOfService) || EMPTY_STRING,
        providerName: data?.provider?.name || EMPTY_STRING,
        providerNPI: data?.provider?.npiNumber || EMPTY_STRING
      };

      if (includeCompany) {
        return {
          ...baseData,
          company: data?.relyingParty.name || DEFAULT_STRING
        };
      }

      return baseData;
    });
  };

  const renderIcd10Values = useCallback(({ value }) => {
    const values = value.split(', ');
    const displayValues = values.slice(0, 2).map((val, index) => (
      <Box key={index} textTransform="capitalize" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {val}
      </Box>
    ));

    return (
      <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
        {displayValues}
      </Stack>
    );
  }, []);

  const showIcdDescriptionModal = useCallback(
    (icdCodes: string, icdNames: string) => {
      const modalRef = showModal(IcdDescriptionDialog, {
        title: 'ICD10 Codes and Descriptions',
        hideDialog: () => {
          modalRef.hide();
        },
        diagnosesCodes: icdCodes,
        diagnosesNames: icdNames
      });
    },
    [showModal]
  );

  const Icd10CodeRenderer = useCallback(
    ({ icdCodes, icdNames }) => {
      const codes = icdCodes.split(',');
      const hasMoreCodes = codes.length > 2;

      return (
        hasMoreCodes && (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Box>+ {`+ ${codes.length - 2}`}</Box>
            <IconButton onClick={() => showIcdDescriptionModal(icdCodes, icdNames)}>
              <ChevronRightIcon />
            </IconButton>
          </Stack>
        )
      );
    },
    [showIcdDescriptionModal]
  );

  const generateFilename = (currentUser, monthAndYear) => {
    const relyingPartyName = currentUser?.relyingParty?.name ?? '';
    const formattedDate = formatTime(monthAndYear, 'MMM, yyyy');

    if (isRpmBillingPage) {
      return `RPM Claims - ${formattedDate}.csv`;
    } else {
      return `${relyingPartyName} - RPM Claims - ${formattedDate}.csv`;
    }
  };

  useEffect(() => {
    if (rpmClaimsData?.getRpmClaims?.billingAccounts) {
      setClaimsData(rpmClaimsData?.getRpmClaims?.billingAccounts ?? []);
      setTotalRowCount(rpmClaimsData?.getRpmClaims?.meta?.totalCount || 0);
    }
  }, [rpmClaimsData, setClaimsData]);

  useEffect(() => {
    callClaimsQuery(false);
    setFetchDataButtonVisible(true);
  }, [monthAndYear, currentPage, callClaimsQuery]);

  const columns: GridColDef<RpmClaimsType>[] = useMemo(
    () => [
      {
        field: 'ehrId',
        headerName: 'EHR Id',
        flex: 1,
        maxWidth: 150,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params.row.ehrId ?? '',
        sortable: false
      },
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        maxWidth: 250,
        renderCell: params => {
          const name = getAccountUserFullName(params.row.account.user);
          const status = params.row.account.rpmStatus;
          const accountMonitoringDevices = params.row.account.accountsMonitoringDevices;
          const relying_party_id = params.row?.relyingParty.id;

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {currentUser?.relyingParty.id === relying_party_id ? (
                <Link
                  to={
                    getAccountRedirectUrl(
                      params?.row?.account.truentityId ?? '',
                      MedicalServices.RPM,
                      status ?? '',
                      accountMonitoringDevices
                    ) ?? ''
                  }
                >
                  {name}
                </Link>
              ) : (
                <span>{name}</span>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'company',
        headerName: 'Company',
        flex: 1,
        maxWidth: 200,
        valueGetter: params => params.row.relyingParty?.name || 'Not Provided',
        sortable: false
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        flex: 1,
        maxWidth: 150,
        valueGetter: params => formatDateIgnoreTZ(params.row.account.birthDate) ?? '',
        sortable: false
      },
      {
        field: 'dateEnrolled',
        headerName: 'Date Enrolled',
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmEnrolledAt = params.row.account?.rpmEnrolledAt;
          return rpmEnrolledAt ? new Date(rpmEnrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        renderCell: params => {
          const { cptCode, description } = params.row;
          const { backgroundColor, textColor } = getClaimsChipColor(cptCode);

          return (
            <Stack direction="row" sx={{ userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              <Chip label={`${cptCode} - ${description}`} sx={{ backgroundColor: backgroundColor, color: textColor }} />
            </Stack>
          );
        }
      },
      {
        field: 'icd10Code',
        headerName: 'ICD10 Code',
        flex: 1,
        maxWidth: 100,
        renderCell: params => renderIcd10Values({ value: params.row.diagnosesCodes || '' }),
        sortable: false
      },
      {
        field: 'action',
        headerName: '',
        sortable: false,
        flex: 1,
        maxWidth: 20,
        filterable: false,
        pinnable: false,
        align: 'center',
        headerAlign: 'left',
        renderCell: params => Icd10CodeRenderer({ icdCodes: params.row.diagnosesCodes || '', icdNames: params.row.diagnosesNames || '' })
      },
      {
        field: 'icd10Description',
        headerName: 'ICD10 Description',
        flex: 2,
        renderCell: params => renderIcd10Values({ value: params.row.diagnosesNames || '' }),
        sortable: false
      },
      {
        field: 'dateOfService',
        headerName: 'Date of Service',
        flex: 1,
        valueFormatter: params => (params?.value ? formatDate(params?.value) : ''),
        sortable: false
      },
      {
        field: 'providerName',
        headerName: 'Provider Name',
        flex: 1,
        valueGetter: params => params?.row?.provider?.name,
        sortable: false
      },
      {
        field: 'providerNPI',
        headerName: 'Provider NPI',
        flex: 1,
        valueGetter: params => params?.row?.provider?.npiNumber,
        sortable: false
      }
    ],
    [Icd10CodeRenderer, currentUser?.relyingParty.id, renderIcd10Values]
  );

  return (
    <Stack>
      <Stack width="100%" flexDirection="column" justifyContent="stretch" alignItems="stretch">
        {checkIfCurrentMonth(monthAndYear) && claimsData?.length === 0 && !scheduleButtonClicked ? (
          <Stack alignItems="center" justifyContent="center" height="50vh" textAlign="center">
            <H3 sx={{ color: color.grey500, lineHeight: 1, marginBottom: 1 }}>The data for the current month has not been generated.</H3>
            <H3 sx={{ color: color.grey500, lineHeight: 1 }}>
              Click the following button to schedule report for the current month and generate data.
            </H3>
            <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={handleScheduleReport}>
              Schedule Report
            </Button>
          </Stack>
        ) : (
          <>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" marginBottom={1} spacing={2}>
              {fetchDataButtonVisible ? (
                <>
                  <Button
                    title=""
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() => callClaimsQuery(false)}
                    disabled={rpmClaimsLoading}
                  >
                    Refresh
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={claimsData?.length === 0}
                    onClick={() => {
                      onFetchExportButtonClicked();
                    }}
                  >
                    Load for Export
                  </Button>
                </>
              ) : (
                <Button isLoading={rpmClaimsLoading} color="primary" variant="contained" size="small">
                  {(claimsData?.length ?? 0) > 0 && (
                    <CSVLink
                      headers={modifiedCsvHeaders}
                      data={getRpmClaimsDataFiltered()}
                      filename={generateFilename(currentUser, monthAndYear)}
                      style={{ textDecoration: 'none', color: '#fff' }}
                    >
                      {rpmClaimsLoading ? 'Loading CSV...' : 'Export'}
                    </CSVLink>
                  )}
                </Button>
              )}
            </Stack>
            <Stack flex={1}>
              <TruentityDataGrid
                name={`${isRpmBillingPage ? 'all' : 'company'}-claims-submissions`}
                autoHeight
                rows={claimsData}
                rowCount={totalRowCount}
                paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                onPaginationModelChange={({ page }) => {
                  setCurrentPage(page);
                }}
                slots={{
                  loadingOverlay: LinearProgress
                }}
                columnVisibilityModel={{
                  company: isRpmBillingPage
                }}
                loading={rpmClaimsLoading}
                paginationMode="server"
                columns={columns}
                disableRowSelectionOnClick
                sx={{ backgroundColor: '#ffffff' }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default RpmClaims;
