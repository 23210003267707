import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ScheduleClaimSubmission from '@/components/Dialogs/ScheduleClaimSubmission';
import type { JSONData } from '@/components/JsonKit/JsonEditor';
import { GET_CLAIM_SUBMISSIONS, GET_CLAIM_SUBMISSION_INFO, UPDATE_CLAIM_SUBMISSIONS } from '@/graphql/claimSubmissions';
import { MedicalServices } from '@/types/admin';
import type {
  ClaimSubmission,
  ClaimSubmissionTabType,
  GetClaimSubmissionInfoResponse,
  GetClaimSubmissionResponse
} from '@/types/claimSubmission';
import { ClaimSubmissionType } from '@/types/claimSubmission';
import { CustomTimezone } from '@/types/date';
import type { RelyingPartyType } from '@/types/graphql';
import { getAccountUserFullName } from '@/util/account';
import { getGeneralTimezone, toDate, tomorrow } from '@/util/date';
import { formatDate, formatDateAndTime, formatDateToTimezone } from '@/util/format';
import { getProviderFullName } from '@/util/rpm';
import { capitalizeLetterBeforeCharacter, unknown } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { ErrorOutlineRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Chip, IconButton, Paper, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { ReactElement, SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ProcessDataPopover from '../PatientDetails/RemotePatientMonitoring/Components/RpmClaimSubmission/ProcessDataPopover';

type Props = {
  monthAndYear: DateInput;
  selectedRelyingPartyId: string;
};

const claimIconMap = new Map<string, ReactElement>([
  [ClaimSubmissionType.UNSCHEDULED, <EventBusyIcon fontSize="small" />],
  [ClaimSubmissionType.SCHEDULED, <TodayIcon fontSize="small" />],
  [ClaimSubmissionType.FAILED, <DoNotDisturbAltIcon fontSize="small" />],
  [ClaimSubmissionType.COMPLETED, <CheckCircleIcon fontSize="small" />]
]);

const claimSubmissionTabs: ClaimSubmissionTabType[] = Object.keys(ClaimSubmissionType).map(value => {
  return { type: value, label: value, icon: claimIconMap.get(value), path: value.toLowerCase() } as ClaimSubmissionTabType;
});

const columnVisibility = {
  unscheduled: {
    patientName: true,
    billingKey: true,
    relyingPartyName: true,
    scheduledOn: false,
    serviceDate: true,
    processData: true,
    statusDetails: true,
    performedOn: false,
    action: true
  },
  scheduled: {
    patientName: true,
    billingKey: true,
    relyingPartyName: true,
    scheduledOn: true,
    serviceDate: true,
    processData: true,
    statusDetails: false,
    performedOn: false,
    action: true
  },
  failed: {
    patientName: true,
    billingKey: true,
    relyingPartyName: true,
    scheduledOn: false,
    serviceDate: true,
    processData: true,
    statusDetails: true,
    performedOn: true,
    action: true
  },
  completed: {
    patientName: true,
    billingKey: true,
    relyingPartyName: true,
    scheduledOn: false,
    serviceDate: true,
    processData: true,
    statusDetails: true,
    performedOn: true,
    action: false
  }
};

const RpmSubmission = ({ monthAndYear, selectedRelyingPartyId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal, hideModal } = useModal();

  const confirmationModalId = useRef<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [allowableRelyingParties, setAllowableRelyingParties] = useState<RelyingPartyType[]>([]);
  const [processDataPopoverDetails, setProcessDataPopoverDetails] = useState<{
    anchorEl: null | HTMLElement;
    popoverContent: JSONData;
  }>({ anchorEl: null, popoverContent: {} });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    patientName: true,
    billingKey: true,
    relyingPartyName: true,
    scheduledOn: true,
    serviceDate: true,
    processData: true,
    statusDetails: true,
    performedOn: false,
    action: true
  });

  const isSelectedLengthZero = useMemo(() => selectionModel.length === 0, [selectionModel]);
  const scheduledUtcDate = useMemo(() => {
    return toDate(`${formatDate(tomorrow())} 08:00:00 UTC`);
  }, []);
  const activeClaimTab = useMemo(() => {
    return claimSubmissionTabs[activeTab];
  }, [activeTab]);
  const isScheduledTab = useMemo(() => activeClaimTab.type === ClaimSubmissionType.SCHEDULED, [activeClaimTab]);
  const isUnscheduledTab = useMemo(() => activeClaimTab.type === ClaimSubmissionType.UNSCHEDULED, [activeClaimTab]);
  const isCompletedTab = useMemo(() => activeClaimTab.type === ClaimSubmissionType.COMPLETED, [activeClaimTab]);

  useQuery<GetClaimSubmissionInfoResponse>(GET_CLAIM_SUBMISSION_INFO, {
    variables: {
      monthYear: monthAndYear,
      ...(selectedRelyingPartyId !== 'ALL' && { relyingPartyIds: [selectedRelyingPartyId] })
    },
    onCompleted: data => {
      const claimSubmissionAllowableRelyingParties = data?.getClaimSubmissionInfo?.submissionAllowedRelyingParties || [];
      setAllowableRelyingParties(claimSubmissionAllowableRelyingParties);
    },
    onError: error => {
      console.error(error);
      setAllowableRelyingParties([]);
    }
  });

  const { data: claimSubmissionData, refetch: claimSubmissionRefetch } = useQuery<GetClaimSubmissionResponse>(GET_CLAIM_SUBMISSIONS, {
    variables: {
      monthYear: monthAndYear,
      serviceType: MedicalServices.RPM,
      status: activeClaimTab.type,
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      ...(selectedRelyingPartyId !== 'ALL' && { relyingPartyIds: [selectedRelyingPartyId] })
    },
    fetchPolicy: 'cache-and-network',
    onError: () => enqueueSnackbar('Unable to retrieve claim submissions. Please contact support for assistance.', { variant: 'error' })
  });
  const [updateClaimSubmission] = useMutation(UPDATE_CLAIM_SUBMISSIONS, {
    onCompleted: data => {
      const { status = 'Error', message = 'Unexpected error occurred.' } = data?.updateClaimSubmission ?? {};
      const variant = status === 'Success' ? 'success' : status === 'Warning' ? 'warning' : 'error';
      enqueueSnackbar(message, { variant });
      claimSubmissionRefetch();
      confirmationModalId.current && hideModal(confirmationModalId.current);
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to update the selected claim submission(s). Please contact support.', { variant: 'error' });
      }
    }
  });

  const open = Boolean(processDataPopoverDetails.anchorEl);

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, selectedTabIndex: number): void => {
      setActiveTab(selectedTabIndex);
      const activeTab = claimSubmissionTabs[selectedTabIndex];
      const visibility = columnVisibility[activeTab.path];
      const newVisibilityModel = { ...columnVisibilityModel, ...visibility };
      setColumnVisibilityModel(newVisibilityModel);
      setCurrentPage(0);
    },
    [columnVisibilityModel]
  );

  const handleJsonPreview = (event: React.MouseEvent<HTMLButtonElement>, jsonData: JSONData) => {
    setProcessDataPopoverDetails({
      anchorEl: event.currentTarget,
      popoverContent: jsonData
    });
  };

  const handleClosePopover = () => {
    setProcessDataPopoverDetails({ ...processDataPopoverDetails, anchorEl: null });
  };

  const onTransitionExited = () => {
    setProcessDataPopoverDetails({ ...processDataPopoverDetails, popoverContent: {} });
  };

  const handleClaimSubmissionChange = useCallback(
    (claimId: GridRowId, status: ClaimSubmissionType) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Status Change',
        message: `Are you sure you want to change the status of this billing submission to <b>'${status}'</b>?`,
        onAgree: () =>
          updateClaimSubmission({
            variables: {
              submissionIds: [claimId],
              submissionStatus: status
            }
          }),
        onDisagree: () => modal.hide()
      });
      confirmationModalId.current = modal.id;
    },
    [showModal, updateClaimSubmission]
  );

  const handleClaimStatusUpdate = (status: ClaimSubmissionType, selectionModel: GridRowId[]) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Status Change',
      message: `Are you sure you want to change the status of selected billing submission(s) to <b>'${status}'</b>?`,
      onAgree: () =>
        updateClaimSubmission({
          variables: {
            submissionIds: selectionModel,
            submissionStatus: status
          }
        }),
      onDisagree: () => modal.hide()
    });
    confirmationModalId.current = modal.id;
  };

  const handleScheduleClaim = (allowableRelyingParties: RelyingPartyType[], monthAndYear: DateInput, selectedRelyingPartyId: string) => {
    const modal = showModal(ScheduleClaimSubmission, {
      title: 'Select service dates',
      description: 'Please select the service date for which you want to submit your claim submissions for each organization.',
      allowableRelyingParties,
      monthAndYear: monthAndYear,
      selectedRelyingPartyId: selectedRelyingPartyId,
      onSuccess: () => {
        modal.hide();
        claimSubmissionRefetch();
      },
      hideDialog: () => modal.hide()
    });
  };

  const columns: GridColDef<ClaimSubmission>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1,
        valueGetter: params => getAccountUserFullName(params?.row?.account?.user)
      },
      {
        field: 'billingKey',
        headerName: 'Billing Key',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1
      },
      {
        field: 'relyingPartyName',
        headerName: 'Organization Name',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row?.relyingParty?.name || unknown()
      },
      {
        field: 'providerName',
        headerName: 'Provider Name',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1,
        valueGetter: params => (params.row?.provider ? getProviderFullName(params.row?.provider) : unknown())
      },
      {
        field: 'scheduledOn',
        headerName: 'Scheduled On',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1,
        valueGetter: params => formatDateAndTime(params.row?.scheduledOn) || unknown()
      },
      {
        field: 'serviceDate',
        headerName: 'Service Date',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => formatDate(params.row?.serviceDate) || unknown()
      },
      {
        field: 'processData',
        headerName: 'Processed Data',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        renderCell: params => (
          <Button
            size="small"
            variant={'text'}
            startIcon={<DataObjectIcon />}
            disableElevation
            onClick={e => handleJsonPreview(e, params.row.processData)}
          />
        )
      },
      {
        field: 'statusDetails',
        headerName: 'Status Details',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        renderCell: params => {
          const statusObj = params.row.additionalData;
          if (typeof statusObj !== 'object' || !statusObj || Object.values(statusObj).length === 0) return unknown();
          const statusText: string | null = Object.values(statusObj)?.[0] || null;
          if (!statusText) return unknown();
          return (
            <Chip
              size={'small'}
              icon={params.row.status === ClaimSubmissionType.COMPLETED ? <CheckCircleIcon /> : <ErrorOutlineRounded />}
              variant="outlined"
              label={statusText}
            />
          );
        }
      },
      {
        field: 'performedOn',
        headerName: 'Performed On',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => (params.row?.performedOn ? formatDate(params.row?.performedOn) : unknown())
      },
      {
        field: 'action',
        headerName: 'Action',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        renderCell: params => (
          <Stack direction="row" spacing={2}>
            <IconButton
              hidden={isCompletedTab || isUnscheduledTab}
              onClick={() => handleClaimSubmissionChange(params.id, ClaimSubmissionType.UNSCHEDULED)}
            >
              <EventBusyIcon fontSize="small" />
            </IconButton>
            <IconButton
              hidden={isScheduledTab || isCompletedTab || isScheduledTab}
              onClick={() => handleClaimSubmissionChange(params.id, ClaimSubmissionType.SCHEDULED)}
            >
              <EventRepeatIcon fontSize="small" />
            </IconButton>
          </Stack>
        )
      }
    ],
    [isCompletedTab, isUnscheduledTab, isScheduledTab, handleClaimSubmissionChange]
  );

  useEffect(() => {
    const defaultActiveTab = claimSubmissionTabs.findIndex(tab => tab.label === ClaimSubmissionType.SCHEDULED);
    setActiveTab(defaultActiveTab);
  }, []);

  return (
    <>
      <Stack>
        <Stack spacing={1} justifyContent="flex-end" alignItems="center" direction="row">
          {isScheduledTab && claimSubmissionData?.getClaimSubmissions?.meta?.totalCount !== 0 && (
            <Alert
              status="info"
              description={`These claim submissions are scheduled to submit on ${formatDateToTimezone({
                date: scheduledUtcDate,
                format: 'MMM DD, YYYY - hh:mm A',
                timezone: getGeneralTimezone(CustomTimezone.ADMIN_TIMEZONE)
              })} (Eastern Time).`}
            />
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              color="success"
              startIcon={<TodayIcon />}
              onClick={() =>
                !isScheduledTab
                  ? handleClaimStatusUpdate(ClaimSubmissionType.SCHEDULED, selectionModel)
                  : handleScheduleClaim(allowableRelyingParties, monthAndYear, selectedRelyingPartyId)
              }
              disabled={(!isScheduledTab && isSelectedLengthZero) || allowableRelyingParties.length === 0}
              hidden={isCompletedTab}
            >
              Schedule {!isSelectedLengthZero && !isScheduledTab && selectionModel.length} Claims Submissions
            </Button>
            <Button
              variant="outlined"
              startIcon={<EventBusyIcon />}
              onClick={() => handleClaimStatusUpdate(ClaimSubmissionType.UNSCHEDULED, selectionModel)}
              disabled={isSelectedLengthZero}
              hidden={isCompletedTab || isUnscheduledTab}
            >
              Unschedule {!isSelectedLengthZero && selectionModel.length} Claims Submissions
            </Button>
          </Stack>
        </Stack>

        <Stack width="100%" flexDirection="column" justifyContent="stretch" alignItems="stretch" mt={2}>
          <Paper elevation={0} component={Stack} direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Box>
              <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
                {claimSubmissionTabs?.map((tab, index: number) => (
                  <Tab
                    label={<h6 style={{ marginBottom: 0 }}>{capitalizeLetterBeforeCharacter(tab.label)}</h6>}
                    icon={tab?.icon}
                    iconPosition="start"
                    key={`tab-${index}`}
                    id={`tab-${index}`}
                  />
                ))}
              </Tabs>
            </Box>
            <Box>
              <TruentityDataGrid
                name={`billing-submission-dg`}
                autoHeight
                rows={claimSubmissionData?.getClaimSubmissions?.claimSubmissions || []}
                rowCount={claimSubmissionData?.getClaimSubmissions?.meta?.totalCount || 0}
                paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                columnVisibilityModel={columnVisibilityModel}
                onPaginationModelChange={({ page }) => {
                  setCurrentPage(page);
                }}
                checkboxSelection={!isCompletedTab}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={newSelection => {
                  setSelectionModel(newSelection);
                }}
                loading={false}
                paginationMode="server"
                columns={columns}
                disableRowSelectionOnClick
                sx={{ backgroundColor: '#ffffff' }}
              />
            </Box>
          </Paper>
        </Stack>
        <ProcessDataPopover
          anchorEl={processDataPopoverDetails.anchorEl}
          open={open}
          onClose={handleClosePopover}
          jsonData={processDataPopoverDetails.popoverContent}
          onTransitionExited={onTransitionExited}
        />
      </Stack>
    </>
  );
};

export default RpmSubmission;
