import { VERIFY_MEDICATION } from '@/graphql/medication';
import type { MedicationVerificationStatus } from '@/types/medication';
import client from '@/util/apollo/client';

export const changeVerificationStatusAccountMedications = async (medsVerificationStatuses: MedicationVerificationStatus[]) => {
  try {
    const result = await client.mutate({
      mutation: VERIFY_MEDICATION,
      variables: { medsVerificationStatuses }
    });

    return result.data.changeVerificationStatusAccountMedications;
  } catch (error) {
    console.error(error);
    return {
      status: null
    };
  }
};
