import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import { getAccountUserFullName } from '@/util/account';
import { formatDateIgnoreTZ } from '@/util/format';
import type { StackProps } from '@mui/material';
import { Autocomplete, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { PatientsData } from './patients';

const SELECT_DEFAULTS: { id: string; name: string }[] = [
  { id: 'unassigned', name: 'Unassigned' },
  { id: 'all', name: 'All' }
];

type Props = StackProps & {
  patientType: string;
  patients: PatientsData[];
  filteredPatients: PatientsData[];
  setFilteredPatients: (val: PatientsData[]) => void;
  resetFilter: () => void;
  clientOrganizations: ClientOrganizationObject[];
};

export function PatientsDataFilter({
  patientType,
  patients,
  filteredPatients,
  setFilteredPatients,
  resetFilter,
  clientOrganizations,
  ...props
}: Props) {
  const { id } = useParams();
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string>('all');
  const [selectedClientStoreId, setSelectedClientStoreId] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [nextCallOnChecked, setNextCallOnChecked] = useState<boolean>(false);
  const [patientName, setPatientName] = useState<string>('');

  const filterOrgs = useCallback(
    (patients: PatientsData[]) => {
      if (selectedOrgId && selectedOrgId?.length > 0) {
        return patients.filter(p => {
          if (selectedOrgId === 'unassigned') {
            return p?.clientStores?.length === 0;
          }

          if (selectedOrgId === 'all') {
            return true;
          }

          return p?.clientOrgs?.some(c => c.id === selectedOrgId);
        });
      }

      return patients;
    },
    [selectedOrgId]
  );

  const filterStores = useCallback(
    (patients: PatientsData[]) => {
      if (selectedClientStoreId && selectedClientStoreId?.length > 0) {
        return patients.filter(p => {
          if (selectedClientStoreId === 'unassigned') {
            return p?.clientStores?.length === 0;
          }

          if (selectedClientStoreId === 'all') {
            return true;
          }

          return p?.clientStores?.some(c => c.id === selectedClientStoreId);
        });
      }

      return patients;
    },
    [selectedClientStoreId]
  );

  const filterName = useCallback(
    (patients: PatientsData[]) => {
      if (!patientName) {
        return patients;
      }

      return patients.filter(p => {
        const fullName = getAccountUserFullName(p.user).toLowerCase();
        return fullName?.includes(patientName.toLowerCase());
      });
    },
    [patientName]
  );

  const filterNextCallOnChecked = useCallback(
    (patients: PatientsData[]) => {
      if (nextCallOnChecked) {
        const formattedSelectedDate = formatDateIgnoreTZ(selectedDate, 'YYYY-MM-DD');
        return patients.filter(p => {
          if (p.accountsFollowupReminders) {
            const hasUpcomingFollowUp = p.accountsFollowupReminders.some(reminder => {
              const formattedReminderDate = formatDateIgnoreTZ(reminder.followUpOn, 'YYYY-MM-DD');
              return formattedReminderDate >= formattedSelectedDate;
            });
            return hasUpcomingFollowUp;
          }
        });
      }

      return patients;
    },
    [nextCallOnChecked, selectedDate]
  );

  const applyFilters = useCallback(() => {
    setFilteredPatients(filterName(filterStores(filterOrgs(filterNextCallOnChecked(patients)))));
  }, [filterName, filterNextCallOnChecked, filterOrgs, filterStores, patients, setFilteredPatients]);

  useEffect(() => {
    // adding this so that whenever
    // patient type is changed (My P{atients || Recent Patients) to clesr filters
    setSelectedOrgId('all');
    setSelectedClientStoreId('');
    setSelectedDate(new Date());
    setNextCallOnChecked(false);
    setPatientName('');
  }, [patientType]);

  useEffect(() => {
    setFilteredClientStores([]);
    setSelectedClientStoreId('');

    const org = clientOrganizations?.find(o => o.id === selectedOrgId);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores([...SELECT_DEFAULTS, ...org.clientStores]);
      setSelectedClientStoreId('all');
    }
  }, [clientOrganizations, selectedOrgId]);

  useEffect(() => {
    applyFilters();
  }, [selectedClientStoreId, selectedOrgId, patientName, selectedDate, nextCallOnChecked, applyFilters]);

  return (
    <Stack {...props}>
      <Autocomplete
        disablePortal={true}
        getOptionLabel={(option: PatientsData) => option.user?.firstName + ' ' + option.user?.lastName}
        onInputChange={(event, newInputValue) => setPatientName(newInputValue)}
        options={filteredPatients}
        sx={{ width: 400 }}
        renderInput={params => <TextField {...params} label="Patient Lookup" margin="dense" />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.user?.firstName + ' ' + option.user?.lastName}
            </li>
          );
        }}
      />

      <Stack direction="row" justifyContent={'flex-start'}>
        <SelectList
          fullWidth={false}
          formControlProps={{ sx: { width: 400 } }}
          label="Organization"
          options={clientOrganizations.map(
            (item: ClientOrganizationObject) =>
              ({
                value: item.id,
                label: item.name
              } as TextSelectOption)
          )}
          placeholder="Select an option..."
          value={selectedOrgId}
          onChange={event => setSelectedOrgId(event.target.value as string)}
        />

        {filteredClientStores.length > 0 && (
          <SelectList
            disabled={filteredClientStores.length === 0}
            fullWidth={false}
            formControlProps={{ sx: { width: 400, marginLeft: '10px' } }}
            label="Location"
            options={filteredClientStores.map(
              (item: ClientStoreObject) =>
                ({
                  value: item.id,
                  label: item.name
                } as TextSelectOption)
            )}
            placeholder="Select an option..."
            value={selectedClientStoreId}
            onChange={event => setSelectedClientStoreId(event.target.value as string)}
          />
        )}
      </Stack>

      {patientType === id && (
        <Stack direction="row" justifyContent={'flex-start'}>
          <FormControlLabel
            sx={{ width: 125, justifyContent: 'center' }}
            control={
              <Checkbox
                checked={nextCallOnChecked}
                onChange={event => {
                  setNextCallOnChecked(event.target.checked);
                  setSelectedDate(new Date());
                }}
              />
            }
            label="Follow Up On"
          />

          <TruentityDatePicker
            disabled={!nextCallOnChecked}
            sx={{ width: 275 }}
            label="Follow-up Date (on or after)"
            format="YYYY-MM-DD"
            value={selectedDate}
            onChange={date => setSelectedDate(date as Date)}
            TextFieldProps={{ required: true }}
          />
        </Stack>
      )}
    </Stack>
  );
}
