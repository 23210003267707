import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MedicalReconciliation from './MedicalReconciliation';

const MedReconciliation = () => {
  const { rpmFollowupConsultId } = useParams();
  const { activeRpmSetupTab } = useRpmSetupStore();
  const { rpmFollowupConsultTabs } = useRpmFollowupConsultStore();

  const isReadOnly = useMemo(() => {
    if (rpmFollowupConsultId) {
      return rpmFollowupConsultTabs[RpmWorkflowTab.MEDICAL_RECONCILIATION]?.isReadOnly || false;
    } else {
      return activeRpmSetupTab?.isReadOnly || false;
    }
  }, [activeRpmSetupTab?.isReadOnly, rpmFollowupConsultId, rpmFollowupConsultTabs]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <MedicalReconciliation />
    </BaseRpmWorkflowTabContent>
  );
};

export default MedReconciliation;
