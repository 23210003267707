import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { H3, H5 } from '@/components/Typography';
import type { CreatePdfRpmFollowupConsultReport } from '@/graphql/rpmWorkflow';
import { CREATE_PDF_RPM_FOLLOWUP_CONSULT_REPORT } from '@/graphql/rpmWorkflow';
import { useDocumentPreview } from '@/hooks/useDocumentPreview';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import ClinicalSummariesDataGrid from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ClinicalSummary/ClinicalSummariesDataGrid';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { RpmFollowupConsultStatusType, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMutation } from '@apollo/client';
import { Box, Divider, Stack } from '@mui/material';
import type { GridRowId } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const RpmFollowupClinicalSummary = () => {
  const { rpmFollowupConsultId: rpmFollowupConsultId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { rpmFollowupConsult, setShouldReFetchRpmFollowupConsult, rpmFollowupConsultTabs } = useRpmFollowupConsultStore();
  const { fetchDocumentPreview } = useDocumentPreview({ documentTitle: 'Follow-up Visit Report' });

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const isReadOnlyRole = useMemo(
    () => rpmFollowupConsultTabs[RpmWorkflowTab.CLINICAL_SUMMARY]?.isReadOnly || false,
    [rpmFollowupConsultTabs]
  );
  const { hasAvailableAccountCareSummaries, setShouldReFetchAccountCareSummaries } = useRpmSetupStore();
  const hasRpmFollowupConsultDocument = useMemo(
    () => (rpmFollowupConsult?.reportS3Key?.length ?? 0) > 0,
    [rpmFollowupConsult?.reportS3Key]
  );
  const isActionDisabled = useMemo(() => {
    if (rpmFollowupConsultId) {
      return rpmFollowupConsult?.status === RpmFollowupConsultStatusType.COMPLETED;
    } else {
      return false;
    }
  }, [rpmFollowupConsultId, rpmFollowupConsult]);

  const [createRpmFollowupConsultReport, { loading: loadingCreateRpmFollowupReport }] = useMutation<CreatePdfRpmFollowupConsultReport>(
    CREATE_PDF_RPM_FOLLOWUP_CONSULT_REPORT,
    {
      variables: {
        rpmFollowupConsultId: rpmFollowupConsultId
      }
    }
  );

  const generateRpmFollowupConsultReport = useCallback(async () => {
    try {
      const rpmFollowupConsultReportResponse = await createRpmFollowupConsultReport();

      if (rpmFollowupConsultReportResponse?.data?.createPdfRpmFollowupConsultReport?.status === 'Success') {
        setShouldReFetchAccountCareSummaries(true);
        setShouldReFetchRpmFollowupConsult(true);
      } else {
        const errorMessage =
          rpmFollowupConsultReportResponse?.errors && rpmFollowupConsultReportResponse.errors.length > 0
            ? rpmFollowupConsultReportResponse.errors[0]?.message
            : 'Failed to generate Patient Follow-up Visit Document. Please try again later.';

        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Failed to generate Patient Follow-up Visit Document. Please try again later.', { variant: 'error' });
      console.error(err);
    }
  }, [createRpmFollowupConsultReport, enqueueSnackbar, setShouldReFetchAccountCareSummaries, setShouldReFetchRpmFollowupConsult]);

  const loadClinicalSummaryAlert = () => {
    if (hasAvailableAccountCareSummaries === false) {
      return <Alert status="info" description="At least one clinical document must be available before completing RPM Follow-up Visit" />;
    }
    if (!hasRpmFollowupConsultDocument) {
      return (
        <Alert
          status="info"
          description="RPM Follow-up Visit Document must be generated before completing RPM Follow-up Visit"
          hidden={hasRpmFollowupConsultDocument}
        />
      );
    }
    return <></>;
  };

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnlyRole}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center" marginY={1}>
          <H3>Medical History</H3>
        </Stack>
        <Stack spacing={4} sx={{ height: 'auto', minHeight: '200px', width: '100%' }}>
          {loadClinicalSummaryAlert()}
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              width: '100%',
              height: 'auto',
              my: 2,
              p: 1,
              border: '1px solid',
              borderColor: theme => theme.palette.divider,
              backgroundColor: color.grey50,
              borderRadius: 1
            }}
          >
            <Box flex={'1'} display={'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center">
              {hasRpmFollowupConsultDocument ? (
                <>
                  <Box
                    sx={{ width: '66px' }}
                    component={'img'}
                    src={'https://truentity-general.s3.amazonaws.com/Indycare-CarePlan-Thumbnail.png'}
                  />
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: color.black100,
                      marginLeft: 2
                    }}
                  >
                    Preview RPM Follow-up Visit Document
                  </H5>
                </>
              ) : (
                <H5
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: color.black100,
                    marginLeft: 2
                  }}
                >
                  RPM Follow-up Visit Document
                </H5>
              )}
            </Box>
            <Stack flexDirection="row" justifyContent="flex-end" columnGap={2}>
              <Button
                variant="outlined"
                label={hasRpmFollowupConsultDocument ? 'Regenerate' : 'Generate'}
                disabled={isReadOnlyRole}
                isLoading={loadingCreateRpmFollowupReport}
                onClick={generateRpmFollowupConsultReport}
              />

              {hasRpmFollowupConsultDocument && (
                <Button
                  sx={{ width: '86px' }}
                  label="View"
                  disabled={loadingCreateRpmFollowupReport}
                  onClick={() => {
                    fetchDocumentPreview({
                      s3Key: rpmFollowupConsult?.reportS3Key || ''
                    });
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Divider />
          <ClinicalSummariesDataGrid
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            isReadOnly={isReadOnlyRole || isActionDisabled}
            rpmFollowupConsultId={rpmFollowupConsultId}
          />
        </Stack>
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default RpmFollowupClinicalSummary;
