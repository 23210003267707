import Button from '@/components/Button';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ScheduledReportsListDialog from '@/components/Dialogs/ScheduledReportsListDialog';
import MuiTabs from '@/components/MuiTabs';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1 } from '@/components/Typography';
import type { CreateScheduledReportResponse } from '@/graphql/administration';
import { CREATE_SCHEDULED_REPORT } from '@/graphql/administration';
import { color } from '@/styles/assets/colors';
import { ScheduledReportTypeEnum } from '@/types/administration';
import type { RpmClaimsType } from '@/types/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { checkIfCurrentMonth, formatTime } from '@/util/format';
import { pathnameIncludes } from '@/util/location';
import { useMutation, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import RpmClaims from './RpmClaims';
import RpmSubmission from './RpmSubmission';

const RpmClaimsSubmissions = () => {
  const isRpmBillingPage = useMemo(() => pathnameIncludes('/reports/rpm-billing'), []);
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [claimsData, setClaimsData] = useState<RpmClaimsType[]>([]);
  const [monthAndYear, setMonthAndYear] = useState<DateInput>(formatTime(new Date(), 'MMM YYYY'));
  const [scheduleButtonClicked, setScheduleButtonClicked] = useState<boolean>(false);
  const [selectedRelyingPartyId, setSelectedRelyingPartyId] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [createScheduledReport, { loading: createScheduledReportLoading }] =
    useMutation<CreateScheduledReportResponse>(CREATE_SCHEDULED_REPORT);

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const scheduleRpmBillingReport = async () => {
    try {
      const response = await createScheduledReport({
        variables: {
          reportType: isRpmBillingPage ? ScheduledReportTypeEnum.ALL_RPM_BILLING_REPORT : ScheduledReportTypeEnum.RPM_BILLING_REPORT,
          scheduledReportInput: {
            filterMonthYear: formatTime(monthAndYear, 'MMM YYYY')
          }
        }
      });

      if (response?.data?.createScheduledReport?.status === 'Success') {
        enqueueSnackbar('RPM Billing Report scheduled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to schedule RPM Billing Report', { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Failed to schedule RPM Billing Report', { variant: 'error' });
    }
  };

  const handleScheduleReport = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Schedule Report',
      message: `This will schedule to ${
        (claimsData?.length ?? 0) > 0 ? 'regenerate' : 'generate'
      } data and RPM Billing Report for the month year: <b>${monthAndYear}</b>. Are you sure you want to proceed?`,
      onAgree: async () => {
        if (checkIfCurrentMonth(monthAndYear)) {
          setScheduleButtonClicked(true);
        }
        await scheduleRpmBillingReport();
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      maxWidth: 'md'
    });
  };

  const handleViewScheduledReports = () => {
    const modal = showModal(ScheduledReportsListDialog, {
      hideDialog: () => {
        modal.hide();
      },
      reportType: isRpmBillingPage ? ScheduledReportTypeEnum.ALL_RPM_BILLING_REPORT : ScheduledReportTypeEnum.RPM_BILLING_REPORT,
      title: 'Scheduled Reports'
    });
  };

  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  };

  useEffect(() => {
    if (isRpmBillingPage) {
      return setSelectedRelyingPartyId('ALL');
    }
    return setSelectedRelyingPartyId(currentUser?.relyingParty.id || '');
  }, [currentUser?.relyingParty.id, isRpmBillingPage]);

  return (
    <Stack>
      <Stack
        sx={{
          padding: 3,
          backgroundColor: color.paper,
          borderRadius: '8px',
          marginBottom: 3
        }}
        spacing={2}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        direction={'column'}
      >
        <Stack direction="row" width="100%" height="auto" justifyContent="space-between" alignItems="center" spacing={2}>
          <H1 textAlign="left" sx={{ flex: 4 }}>
            {`${isRpmBillingPage ? 'All Claims Submissions' : 'Claims Submissions'}`}
          </H1>
          <TruentityDatePicker
            showMonth={true}
            showYear={true}
            value={monthAndYear}
            onChange={handleMonthYear}
            views={['month', 'year']}
            sx={{ width: '415px' }}
          />
        </Stack>
        {selectedTab === 0 && (
          <Stack direction="row" width="100%" height="auto" spacing={2} justifyContent="flex-end" alignItems="center">
            <Button isLoading={createScheduledReportLoading} variant="contained" color="primary" onClick={handleScheduleReport}>
              Schedule Report
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="small"
              onClick={handleViewScheduledReports}
              isLoading={createScheduledReportLoading}
            >
              View Scheduled Reports
            </Button>
          </Stack>
        )}
      </Stack>

      <Stack width="100%" flexDirection="column" justifyContent="stretch" alignItems="stretch">
        <Paper>
          <MuiTabs
            tabs={[
              {
                label: 'Claims',
                children: (
                  <RpmClaims
                    monthAndYear={monthAndYear}
                    claimsData={claimsData}
                    setClaimsData={setClaimsData}
                    scheduleButtonClicked={scheduleButtonClicked}
                    handleScheduleReport={handleScheduleReport}
                  />
                )
              },
              {
                label: 'Submissions',
                children: <RpmSubmission monthAndYear={monthAndYear} selectedRelyingPartyId={selectedRelyingPartyId} />,
                disabled: selectedRelyingPartyId.length === 0 || claimsData.length === 0
              }
            ]}
            customPadding
            tabVariant="standard"
            onTabChange={handleTabChange}
          />
        </Paper>
      </Stack>
    </Stack>
  );
};

export default RpmClaimsSubmissions;
