import { RpmAlertsLabelTypes } from '@/types/remotePatientMonitoring';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AlertBadge from './AlertBadge';
import WidgetContainer from './WidgetContainer';

const AlertsWidget = ({
  criticalHighCount,
  criticalLowCount,
  medRegimenCount,
  noReadingsCount
}: {
  criticalHighCount: number;
  criticalLowCount: number;
  medRegimenCount: number;
  noReadingsCount: number;
}) => {
  const navigate = useNavigate();

  const handleAlertWidgetClick = (alertType: RpmAlertsLabelTypes) => {
    return navigate(`../alerts?filter-type=${alertType}`);
  };

  return (
    <WidgetContainer title="Alerts">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%',
            cursor: 'pointer'
          }}
          onClick={() => handleAlertWidgetClick(RpmAlertsLabelTypes.CRITICALLY_HIGH)}
        >
          <AlertBadge title="Critical High" count={criticalHighCount} colorScheme="scheme1" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%',
            cursor: 'pointer'
          }}
          onClick={() => handleAlertWidgetClick(RpmAlertsLabelTypes.CRITICALLY_LOW)}
        >
          <AlertBadge title="Critical Low" count={criticalLowCount} colorScheme="scheme2" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%',
            cursor: 'pointer'
          }}
          onClick={() => handleAlertWidgetClick(RpmAlertsLabelTypes.MED_REGIMEN)}
        >
          <AlertBadge title="Med Regimen" count={medRegimenCount} colorScheme="scheme3" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%',
            cursor: 'pointer'
          }}
          onClick={() => handleAlertWidgetClick(RpmAlertsLabelTypes.NO_READINGS)}
        >
          <AlertBadge title="No Readings" count={noReadingsCount} colorScheme="scheme4" />
        </Box>
      </Box>
    </WidgetContainer>
  );
};

export default AlertsWidget;
