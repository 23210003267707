import DropdownMenu from '@/components/DropdownMenu';
import { Body1 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import { UPDATE_RPM_ACCOUNT_MONITORED_CONDITION } from '@/graphql/remotePatientMonitoring';
import { AccountRpmMonitoredCondition, RpmApprovalStatusTypes, RpmFollowupConsultStatusType } from '@/types/remotePatientMonitoring';
import { getConditionStrings, getDiagnosisUsingConditions } from '@/util/rpm';
import { useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { useMutation } from '@apollo/client';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';

type Props = {
  rpmFollowupConsultId?: string;
};

const RpmConditionsSelect = ({ rpmFollowupConsultId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { rpmFollowupConsult } = useRpmFollowupConsultStore();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);

  const isActionDisabled = useMemo(() => {
    if (rpmFollowupConsultId) {
      return rpmFollowupConsult?.status === RpmFollowupConsultStatusType.COMPLETED;
    } else {
      return patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED;
    }
  }, [patientInfo, rpmFollowupConsult, rpmFollowupConsultId]);

  const [updateAccountMonitoredCondition] = useMutation(UPDATE_RPM_ACCOUNT_MONITORED_CONDITION, {
    onCompleted(data) {
      setReloadPatientInfo(true);
      enqueueSnackbar(data?.updateRpmAccountMonitoredCondition?.message, { variant: 'success' });
    },
    onError(err) {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar(
          'Unable to update patient conditions due to a system error. Please try again or contact support if the issue persists.',
          { variant: 'error' }
        );
      }
    }
  });

  const handleMonitoringCondition = (condition: AccountRpmMonitoredCondition) => {
    updateAccountMonitoredCondition({
      variables: {
        truentityId: patientInfo?.truentityId,
        conditions: getDiagnosisUsingConditions(condition)
      }
    });
  };

  const monitoredCondition = useMemo(() => {
    const conditions = patientInfo?.accountRpmMonitoredCondition;
    return getConditionStrings(conditions);
  }, [patientInfo?.accountRpmMonitoredCondition]);

  return (
    <Stack direction="row" spacing={1} alignItems={'center'}>
      <Body1>Reason For Visit:</Body1>
      <DropdownMenu
        menuItemsData={{
          label: monitoredCondition,
          items: [
            {
              label: AccountRpmMonitoredCondition.DIABETES,
              callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.DIABETES)
            },
            {
              label: AccountRpmMonitoredCondition.HYPERTENSION,
              callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.HYPERTENSION)
            },
            {
              label: AccountRpmMonitoredCondition.DIABETES_AND_HYPERTENSION,
              callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.DIABETES_AND_HYPERTENSION)
            },
            {
              label: AccountRpmMonitoredCondition.UNASSIGNED,
              callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.UNASSIGNED)
            }
          ]
        }}
        ButtonProps={{
          variant: 'outlined',
          size: 'small',
          color: 'error',
          disabled: isActionDisabled,
          sx: { minWidth: '100%', borderRadius: 8, padding: '3px 10px' }
        }}
      />
    </Stack>
  );
};

export default RpmConditionsSelect;
