import type { PatientsData } from '@/routes/Patients/patients';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import type { AccountType, ClientOrganizationType, FileMonitoringType, RelyingPartyAdminType } from '@/types/graphql';
import type Meta from '@/types/meta';
import { gql } from '@apollo/client';

export const ADD_ACCOUNT = gql`
  mutation AddAccount(
    $email: Email!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $dateOfBirth: ISO8601DateTime!
    $phoneNumber: String!
    $gender: String!
    $zipcode: String!
    $healthPlan: String!
    $consent: String!
    $clientOrganization: String!
    $clientStore: String!
    $ehrId: String!
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
  ) {
    addAccount(
      email: $email
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      phoneNumber: $phoneNumber
      gender: $gender
      zipcode: $zipcode
      healthPlan: $healthPlan
      consent: $consent
      clientOrganization: $clientOrganization
      clientStore: $clientStore
      ehrId: $ehrId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressCity: $addressCity
      addressState: $addressState
    ) {
      status
      message
      account {
        id
        truentityId
        rpmStatus
        doNotCall
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
        user {
          id
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;

export type GetPatientDetailResponse = {
  accountGet: PatientsData;
};

export const GET_PATIENT_DETAIL_FOR_CALENDAR = gql`
  query getPatientDetailForCalendar($truentityId: String!) {
    accountGet(truentityId: $truentityId) {
      id
      truentityId
      address
      phone
      birthDate
      accountExternalEhr {
        type
        externalId
      }
      numOfReadingsInCurrentMonth
      totalTimeSpentSecsInCurrentMonth
      gender
      rpmStatus
      rpmApprovalStatus
      healthPlan {
        id
        orgName
      }
      emergencyContactName
      emergencyContactPhone
      emergencyContactEmail
      addressLine1
      addressLine2
      addressState
      addressCity
      zipcode
      consent
      data
      rpmStatus
      clientOrgs {
        name
        id
        clientStores {
          name
          id
        }
      }
      clientStores {
        name
        id
      }
      user {
        id
        firstName
        middleName
        lastName
        email
      }
      numQuickUploads
      numQuickUploadsNew
      doNotCall
      doNotCallLastSpecifiedAt
      accountsMonitoringDevices {
        id
        externalPatientId
        hardwareId
        hardwarePatientId
        status
        monitoringDevice {
          id
          brandName
          name
          typeName
        }
      }
      primaryLanguage
      numPendingTasks
      numCompletedTasks
      lastEncounterDate
      accountsAssignments {
        relyingPartyAdmin {
          id
          name
        }
      }
      currentAccountsAssignments {
        relyingPartyAdmin {
          id
          name
        }
      }
      accountsFollowupRemindersByAdmin {
        id
        followUpOn
        type
        subType
        notes
        status
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
            email
          }
        }
        createdAt
      }
    }
  }
`;

export const GET_PATIENT_DETAIL = gql`
  query getPatientDetail($truentityId: String!) {
    accountGet(truentityId: $truentityId) {
      id
      truentityId
      address
      phone
      birthDate
      accountExternalEhr {
        type
        externalId
      }
      numOfReadingsInCurrentMonth
      totalTimeSpentSecsInCurrentMonth
      gender
      rpmStatus
      rpmStatusUpdatedAt
      rpmApprovalStatus
      rpmSignOffStatus
      healthPlan {
        id
        orgName
      }
      emergencyContactName
      emergencyContactPhone
      emergencyContactEmail
      addressLine1
      addressLine2
      addressState
      addressCity
      zipcode
      consent
      data
      rpmStatus
      rpmEnrolledAt
      rpmUnenrolledAt
      onboardedAt
      clientOrgs {
        name
        id
        clientStores {
          name
          id
        }
      }
      clientStores {
        name
        id
      }
      user {
        id
        firstName
        middleName
        lastName
        email
      }
      numQuickUploads
      numQuickUploadsNew
      doNotCall
      doNotCallLastSpecifiedAt
      lastContacted {
        type
        date
      }
      accountsFollowupReminders {
        id
        followUpOn
        type
        subType
        notes
        status
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
            email
          }
        }
        createdAt
      }
      accountsMonitoringDevices {
        id
        externalPatientId
        hardwareId
        hardwarePatientId
        status
        monitoringDevice {
          id
          brandName
          name
          typeName
        }
        latestReadings {
          id
          value
          unit
          recordedAt
          source
          isBaseline
        }
      }
      primaryLanguage
      numPendingTasks
      numCompletedTasks
      lastEncounterDate
      setting
      isTempVitalAccessBlocked
      isTempVitalAccessConfigured
      accountsAssignments {
        relyingPartyAdmin {
          id
          name
        }
      }
      currentAccountsAssignments {
        relyingPartyAdmin {
          id
          name
        }
      }
      accountsFollowupRemindersByAdmin {
        id
        followUpOn
        type
        subType
        notes
        status
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
            email
          }
        }
        createdAt
      }
      accountRpmMonitoredCondition
      accountSummary {
        isTelehealthVisitLaunched
      }
      lastCompletedFollowup {
        consultAt
      }
      accountRpmDiagnosis
    }
  }
`;

export type GetAccountsResponse = {
  accountsByProvider: {
    accounts: PatientsData[];
    meta: Meta;
  };
};

export const GET_ACCOUNT_COMMENTS = gql`
  query comments($truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    commentsByAccount(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      comments {
        id
        body
        createdAt
        sender {
          name
          email
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const DELETE_ACCOUNT_COMMENT = gql`
  mutation removeCommentFromAccount($commentId: String!, $truentityId: String!) {
    removeCommentFromAccount(commentId: $commentId, truentityId: $truentityId) {
      status
      message
      comment {
        id
        body
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query getPatients($pageNum: Int!, $pageSize: Int!) {
    accountsByProvider(pageNum: $pageNum, pageSize: $pageSize) {
      accounts {
        id
        truentityId
        address
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        consent
        data
        clientOrgs {
          name
          id
          clientStores {
            name
            id
          }
        }
        clientStores {
          name
          id
        }
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        numQuickUploads
        numQuickUploadsNew
        numPendingTasks
        numCompletedTasks
        lastEncounterDate
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetAccountsSuperAdminResponse = {
  accountsAll: {
    accounts: PatientsData[];
    meta: Meta;
  };
};

export const GET_ACCOUNTS_SUPER_ADMIN = gql`
  query getPatientsSuperAdmin($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountFilterOptionsInput) {
    accountsAll(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accounts {
        id
        truentityId
        address
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        consent
        data
        clientOrgs {
          name
          id
          clientStores {
            name
            id
          }
        }
        clientStores {
          name
          id
        }
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        numQuickUploads
        numQuickUploadsNew
        numPendingTasks
        numCompletedTasks
        lastEncounterDate
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetMyPatientsResponse = {
  accountsAssignedToRelyingPartyAdmin: {
    accounts: PatientsData[];
    meta: Meta;
  };
};

export const GET_MY_PATIENTS = gql`
  query GetAccountsAssignedRelyingPartyAdmin($relyingPartyAdminId: ID!, $pageNum: Int!, $pageSize: Int!) {
    accountsAssignedToRelyingPartyAdmin(relyingPartyAdminId: $relyingPartyAdminId, pageNum: $pageNum, pageSize: $pageSize) {
      accounts {
        id
        truentityId
        address
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        consent
        data
        clientOrgs {
          name
          id
          clientStores {
            name
            id
          }
        }
        clientStores {
          name
          id
        }
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        numQuickUploads
        numQuickUploadsNew
        numPendingTasks
        numCompletedTasks
        lastEncounterDate
        accountsAssignments {
          relyingPartyAdmin {
            name
            id
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_MY_PATIENTS_SIMPLIFIED = gql`
  query GetAccountsAssignedRelyingPartyAdmin($relyingPartyAdminId: ID, $pageNum: Int!, $pageSize: Int!) {
    accountsAssignedToRelyingPartyAdmin(relyingPartyAdminId: $relyingPartyAdminId, pageNum: $pageNum, pageSize: $pageSize) {
      accounts {
        id
        truentityId
        rpmStatus
        mtmStatus
        tcmStatus
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        clientOrgs {
          name
          id
        }
        clientStores {
          name
          id
        }
        birthDate
        phone
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        numQuickUploadsNew
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetRelyingPartyAdminsResponse = {
  relyingPartyAdminsAll: {
    relyingPartyAdmins: { id: string; name: string; roleType: string }[];
  };
};

export type GetRelyingPartyAdminByIdResponse = {
  relyingPartyAdminById: RelyingPartyAdminType;
};

export type GetRelyingPartyRpmReviewerResponse = {
  getRpmReviewer: RelyingPartyAdminType;
};

export const GET_RELYING_PARTY_ADMINS = gql`
  query GetRelyingPartyAdminsAll($pageNum: Int!, $pageSize: Int!) {
    relyingPartyAdminsAll(pageNum: $pageNum, pageSize: $pageSize) {
      relyingPartyAdmins {
        name
        id
        roleType
      }
    }
  }
`;

export type GetAccountCareMonitoringFilesResponse = {
  accountFileMonitoring: {
    monitoringFiles: FileMonitoringType[];
    meta: Meta;
  };
};

export const GET_ACCOUNT_CARE_MONITORING_FILES = gql`
  query GetAccountFileMonitoringsFiles($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountCareMonitoringFilesInput!) {
    accountFileMonitoring(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      monitoringFiles {
        id
        fileName
        numRecords
        logText
        sftpEndpoint
        status
        createdAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetGlobalAccountSearchResponse = {
  accountsSearch: {
    accounts: PatientsData[];
    meta: Meta;
  };
};

export const GET_GLOBAL_SEARCH_ACCOUNTS = gql`
  query AccountsSearch($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountFilterOptionsInput!) {
    accountsSearch(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accounts {
        id
        truentityId
        gender
        rpmStatus
        mtmStatus
        tcmStatus
        zipcode
        phone
        birthDate
        medicalServices {
          name
          text
          isSameOrg
          isOfferOrg
          isEnrolled
        }
        user {
          id
          email
          firstName
          lastName
          email
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_GLOBAL_SEARCH_ACCOUNTS_COUNTS = gql`
  query AccountsSearch($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountFilterOptionsInput!) {
    accountsSearch(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const ONBOARD_ACCOUNT = gql`
  mutation OnboardAccount($truentityId: String!, $service: String!) {
    onboardAccount(truentityId: $truentityId, service: $service) {
      status
      message
    }
  }
`;

export type GetAccountsByFilterResponse = {
  accountsLookup: {
    accounts: PatientsData[];
  };
};
export const GET_ACCOUNT_BY_FILTER = gql`
  query GetAccountsByFilter($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountFilterOptionsInput!) {
    accountsLookup(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accounts {
        id
        truentityId
        address
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        consent
        data
        clientOrgs {
          name
          id
          clientStores {
            name
            id
          }
        }
        clientStores {
          name
          id
        }
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        numQuickUploads
        numQuickUploadsNew
        numPendingTasks
        numCompletedTasks
        lastEncounterDate
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_ACCOUNT_BY_FILTER_SIMPLIFIED = gql`
  query GetAccountsByFilterSimplified(
    $pageNum: Int!
    $pageSize: Int!
    $filterOptions: AccountFilterOptionsInput!
    $relyingPartyAdminId: String
  ) {
    accountsLookup(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions, relyingPartyAdminId: $relyingPartyAdminId) {
      accounts {
        id
        truentityId
        rpmStatus
        mtmStatus
        tcmStatus
        zipcode
        accountsMonitoringDevices {
          externalPatientId
          status
        }
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
        phone
        birthDate
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        healthPlan {
          id
          orgName
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetAccountsLookupResponse = {
  accountsLookupHistory: {
    accounts: PatientsData[];
    meta: Meta;
  };
};
export const GET_ACCOUNTS_LOOKUP_HISTORY = gql`
  query GetAccountsLookupHistory($pageNum: Int!, $pageSize: Int!) {
    accountsLookupHistory(pageNum: $pageNum, pageSize: $pageSize) {
      accounts {
        id
        truentityId
        address
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        consent
        data
        clientOrgs {
          name
          id
          clientStores {
            name
            id
          }
        }
        clientStores {
          name
          id
        }
        user {
          id
          firstName
          middleName
          lastName
          email
        }
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        currentMedsCount
        lastMedrecDate
        lastImport {
          status
          lastImportDate
        }
        numQuickUploads
        numQuickUploadsNew
        numPendingTasks
        numCompletedTasks
        lastEncounterDate
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type UpdateUserProfileResponse = {
  updateUserAccountProfile: {
    account: AccountType;
  };
};

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserAccountProfile($truentityId: String!, $profileData: UserAccountProfileInput!) {
    updateUserAccountProfile(truentityId: $truentityId, profileData: $profileData) {
      account {
        truentityId
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        healthPlan {
          id
        }
        accountExternalEhr {
          type
          externalId
        }
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        user {
          id
          firstName
          middleName
          lastName
        }
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        consent
      }
    }
  }
`;

export const LOGGED_USER = gql`
  query loggedUser($relyingPartyId: ID!) {
    relyingParty(relyingPartyId: $relyingPartyId) {
      id
      name
      logoUrl
      orgHandle
      email
      settings
      coverLetterBody
      coverLetterS3Key
      logo
      addressLine1
      addressLine2
      addressState
      addressCity
      phone
      isCtaAccepted
      ctaAcceptedOn
      ctaAcceptedInfo
      ctaRejectedOn
      ctaAcceptedByAdmin {
        name
      }
    }
  }
`;

export const LOGIN_TENANT_ADMIN = gql`
  mutation relyingPartyLogin($email: Email!, $password: String!) {
    relyingPartyLogin(email: $email, password: $password) {
      accessToken
      refreshToken
      relyingPartyAdmin {
        id
        roleType
        presignedAvatarUrl
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        title
        hasMultipleRelyingParties
        businessName
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZipcode
        phone
        alternateEmail
        hourlyRate
        supervisorEmail
        bankName
        bankRoutingNumber
        bankAccountNumber
        externalDialpadId
        settings
        relyingParty {
          id
          name
          logoUrl
          orgHandle
          settings
          addressLine1
          addressLine2
          addressState
          addressCity
          phone
          email
          phone
          coverLetterBody
          medicalServices {
            name
            shortName
            serviceType
          }
          isCtaAccepted
        }
        roleType
        npiNumber
        techLicense
        user {
          id
          firstName
          lastName
          email
        }
      }
      otpId
    }
  }
`;

export const TWOFACTOR_LOGIN_ADMIN = gql`
  mutation relyingPartyTwofactorLogin($otpId: String!, $otpCode: String!) {
    relyingPartyTwofactorLogin(otpId: $otpId, otpCode: $otpCode) {
      accessToken
      refreshToken
      relyingPartyAdmin {
        id
        roleType
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        title
        businessName
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZipcode
        phone
        alternateEmail
        hourlyRate
        bankName
        bankRoutingNumber
        bankAccountNumber
        externalDialpadId
        settings
        hasMultipleRelyingParties
        relyingParty {
          id
          name
          logoUrl
          orgHandle
          settings
          medicalServices {
            name
            shortName
            serviceType
          }
          isCtaAccepted
        }
        roleType
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export type UpdateRelyingPartyResponse = {
  updateRelyingParty: {
    status: string;
    relyingParty: {
      id: string;
      name: string;
      coverLetterBody: string;
      settings: string;
    };
  };
};

export const UPDATE_RELYING_PARTY = gql`
  mutation UpdateRelyingParty(
    $relyingPartyId: ID!
    $coverLetterBody: String
    $name: String
    $logo: String
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $email: String
    $phone: String
    $settings: String
  ) {
    updateRelyingParty(
      relyingPartyId: $relyingPartyId
      name: $name
      logo: $logo
      coverLetterBody: $coverLetterBody
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressCity: $addressCity
      addressState: $addressState
      email: $email
      phone: $phone
      settings: $settings
    ) {
      status
      relyingParty {
        id
        name
        coverLetterBody
        addressLine1
        addressLine2
        addressCity
        email
        phone
        settings
      }
    }
  }
`;

export const UPDATE_RELYING_PARTY_PROFILE = gql`
  mutation UpdateRelyingPartyAdminProfile($relyingPartyAdminId: ID!, $profileData: RelyingPartyAdminProfileInput!) {
    updateRelyingPartyAdminProfile(relyingPartyAdminId: $relyingPartyAdminId, profileData: $profileData) {
      status
      message
    }
  }
`;

export type PioneerRxEventTypes = {
  id: string;
  accountId: string;
  account: AccountType;
  clientOrgId: string;
  clientOrg: ClientOrganizationType;
  eventName: string;
  messageHeader: string;
  messageBody: string;
  createdAt: Date;
  displayName: string;
};

export type GetAllPioneerEventsResponse = {
  getPioneerrx: {
    accounts: PioneerRxEventTypes[];
  };
};

export const GET_ALL_PIONEER_RX_EVENTS = gql`
  query GetPioneerrx($truentityId: String!) {
    getPioneerrx(truentityId: $truentityId) {
      accounts {
        id
        accountId
        account {
          truentityId
        }
        clientOrgId
        eventName
        messageHeader
        messageBody
        createdAt
      }
    }
  }
`;

export type GetPioneerEventsResponseById = {
  getPioneerrxById: PioneerRxEventTypes;
};

export const GET_PIONEER_RX_EVENT_BY_ID = gql`
  query GetPioneerrxById($truentityId: String!, $eventId: String!) {
    getPioneerrxById(truentityId: $truentityId, eventId: $eventId) {
      accountId
      clientOrgId
      clientOrg {
        id
        name
      }
      eventName
      messageBody
      messageHeader
      createdAt
    }
  }
`;

export type GetAllPioneerEventsDefaultResponse = {
  pioneerrxEvents: {
    accounts: PioneerRxEventTypes[];
  };
};

export const GET_ALL_PIONEER_RX_EVENTS_DEFAULT = gql`
  query PioneerrxEventsQuery($orgId: String!, $pageNum: Int!, $pageSize: Int!) {
    pioneerrxEvents(orgId: $orgId, pageNum: $pageNum, pageSize: $pageSize) {
      accounts {
        id
        account {
          id
          user {
            firstName
            lastName
            email
          }
          accountsAssignments {
            relyingPartyAdmin {
              id
              name
            }
          }
          truentityId
          gender
          phone
          birthDate
          address
          healthPlan {
            id
            orgName
          }
          currentMedsCount
        }
        clientOrg {
          id
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export enum FollowUpStatusTypes {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED'
}

export enum FollowUpTypes {
  CALL_PATIENT = 'Call Patient',
  CALL_PROVIDER = 'Call Provider',
  TEXT_PATIENT = 'Text Patient',
  REVIEW_CHART = 'Review Chart',
  REVIEW = 'Review',
  RPM_INITIAL_VISIT = 'RPM Initial Visit',
  FOLLOW_UP_VISIT = 'Follow-up Visit'
}

export enum FollowUpSubTypes {
  NONE = 'None',
  RPM = 'RPM',
  CMR = 'CMR',
  TIP = 'TIP',
  Adherence = 'Adherence'
}

export type AccountsFollowupResponse = {
  addAccountFollowup?: { status: string; message: string; followUps?: FollowUpRemindersTypes[] };
  updateAccountFollowup?: { status: string; message: string; followUps?: FollowUpRemindersTypes[] };
};

export const ADD_ACCOUNT_FOLLOWUP = gql`
  mutation AddAccountFollowup(
    $truentityId: String!
    $followUpAssigneeIds: [ID!]!
    $followUpOn: ISO8601DateTime!
    $type: String!
    $subType: String
    $note: String
    $status: AccountFollowupStatusTypeEnum
  ) {
    addAccountFollowup(
      truentityId: $truentityId
      followUpAssigneeIds: $followUpAssigneeIds
      followUpOn: $followUpOn
      type: $type
      subType: $subType
      note: $note
      status: $status
    ) {
      status
      message
      followUps {
        id
        type
        followUpOn
        subType
        status
        notes
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export type UpdateAccountFollowupResponse = {
  updateAccountFollowup: {
    status: string;
    message: string;
    followUps?: FollowUpRemindersTypes[];
  };
};

export const UPDATE_ACCOUNT_FOLLOWUP = gql`
  mutation UpdateAccountFollowup(
    $followUpIds: [ID!]!
    $followUpAssigneeIds: [ID!]!
    $followUpOn: ISO8601DateTime
    $type: String
    $subType: String
    $note: String
    $status: AccountFollowupStatusTypeEnum
  ) {
    updateAccountFollowup(
      followUpIds: $followUpIds
      followUpAssigneeIds: $followUpAssigneeIds
      followUpOn: $followUpOn
      type: $type
      subType: $subType
      note: $note
      status: $status
    ) {
      status
      message
      followUps {
        id
        type
        followUpOn
        subType
        status
        notes
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export type GetAccountFollowupByIdResponse = {
  accountsFollowupReminderById: FollowUpRemindersTypes;
};

export const GET_ACCOUNT_FOLLOWUP_BY_ID = gql`
  query GetAccountFollowupById($id: ID!) {
    accountsFollowupReminderById(id: $id) {
      id
      followUpOn
      type
      subType
      notes
      status
      relyingPartyAdmin {
        id
        user {
          firstName
          lastName
          email
        }
      }
      createdAt
    }
  }
`;

export const DELETE_ACCOUNT_FOLLOWUP = gql`
  mutation RemoveAccountFollowup($followUpIds: [ID!]!) {
    removeAccountFollowup(followUpIds: $followUpIds) {
      status
      message
    }
  }
`;

export type RemoveAccountFollowupsResponse = {
  removeAccountFollowups: {
    status: string;
    message: string;
  };
};

export const REMOVE_ACCOUNT_FOLLOWUPS = gql`
  mutation RemoveAccountFollowups($truentityId: String!) {
    removeAccountFollowups(truentityId: $truentityId) {
      status
      message
    }
  }
`;

export const GET_ACCOUNT_MEDICAL_SERVICES = gql`
  query GetAccountMedicalServices($truentityId: ID!, $clientOrgId: String!, $clientStoreId: String!) {
    getAccountMedicalServices(truentityId: $truentityId, clientOrgId: $clientOrgId, clientStoreId: $clientStoreId) {
      mtm
      tcm
      rpm
      map
      ms
      bloodGlucose
      bloodPressure
      weight
    }
  }
`;

export const CREATE_ACCOUNT_MEDICAL_SERVICES = gql`
  mutation CreateAccountMedicalService(
    $truentityId: ID!
    $clientOrgId: String!
    $clientStoreId: String!
    $medicalServiceOptions: MedicalServicesOptionsInput!
  ) {
    createAccountMedicalService(
      truentityId: $truentityId
      clientOrgId: $clientOrgId
      clientStoreId: $clientStoreId
      medicalServiceOptions: $medicalServiceOptions
    ) {
      status
      message
    }
  }
`;

export type GetGlobalAccountByIdResponse = {
  globalAccountById: AccountType;
};

export const GET_GLOBAL_ACCOUNT_BY_ID = gql`
  query GlobalAccountByIdQuery($truentityId: String!) {
    globalAccountById(truentityId: $truentityId) {
      truentityId
      zipcode
      address
      totalTimeSpentSecsInCurrentMonth
      consent
      birthDate
      phone
      rpmApprovalStatus
      rpmStatus
      healthPlan {
        id
        orgName
      }
      gender
      data
      setting
      user {
        id
        email
        firstName
        lastName
      }
      clientOrgs {
        id
        name
        clientStores {
          id
          name
        }
      }
      clientStores {
        id
        name
      }
      accountsAssignments {
        relyingPartyAdmin {
          name
        }
      }
      lastImport {
        lastImportDate
        status
      }
      numPendingTasks
      numCompletedTasks
      lastEncounterDate
      doNotCall
      doNotCallLastSpecifiedAt
      nextCallSuggestedAt
      primaryLanguage
      currentMedsCount
      lastMedrecDate
      numQuickUploads
      numQuickUploadsNew
      addressLine1
      addressLine2
      addressState
      addressCity
      accountsFollowupReminders {
        id
        relyingPartyAdminId
      }
      accountsFollowupRemindersByAdmin {
        id
        relyingPartyAdminId
      }
    }
  }
`;

export type GetAccountFollowupsResponse = {
  accountFollowups: { accountFollowups: FollowUpRemindersTypes[] };
};

export const GET_ACCOUNT_FOLLOWUPS = gql`
  query AccountFollowupsQuery($truentityId: ID!, $pageNum: Int, $pageSize: Int, $filterOptions: AccountFollowupFilterOptionsInput) {
    accountFollowups(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountFollowups {
        id
        followUpOn
        type
        subType
        status
        notes
        account {
          truentityId
          rpmStatus
          user {
            firstName
            middleName
            lastName
          }
        }
        relyingPartyAdmin {
          id
          user {
            firstName
            middleName
            lastName
          }
        }
        createdAt
      }
    }
  }
`;

export const UPDATE_ACCOUNT_SETTING = gql`
  mutation UpdateAccountSetting($truentityId: String!, $settings: String!) {
    updateAccountSetting(truentityId: $truentityId, settings: $settings) {
      settings
    }
  }
`;

export const SET_ACCOUNT_TEMP_VITAL_ACCESS_PIN = gql`
  mutation UpdateAccountTempVitalAccessPin($truentityId: ID!, $pinCode: String!) {
    updateAccountTempVitalAccessPin(truentityId: $truentityId, pinCode: $pinCode) {
      status
      message
    }
  }
`;

export const REFRESH_ENROLLED_ACCOUNTS_SNAPSHOT = gql`
  mutation RefreshCurrentMonthEnrolledAccountsSnapshot {
    refreshCurrentMonthEnrolledAccountsSnapshot {
      status
      message
    }
  }
`;

export type UploadLogoResponse = {
  uploadLogo: {
    message: string;
    etag: string;
    s3Url: string;
  };
};
export const UPLOAD_LOGO = gql`
  mutation UploadLogo($file: Upload!, $relyingPartyId: String!) {
    uploadLogo(file: $file, relyingPartyId: $relyingPartyId) {
      message
      etag
      s3Url
    }
  }
`;

export type UploadAvatarResponse = {
  uploadAvatar: {
    message: string;
    etag: string;
    s3Url: string;
    presignedUrl: string;
  };
};
export const UPLOAD_AVATAR = gql`
  mutation UploadAvatar($file: Upload!, $relyingPartyAdminId: String!) {
    uploadAvatar(file: $file, relyingPartyAdminId: $relyingPartyAdminId) {
      message
      etag
      s3Url
      presignedUrl
    }
  }
`;

export type GetInitialVisitFollowupsResponse = {
  getAllInitialFollowUps: {
    accountFollowups: FollowUpRemindersTypes[];
    meta: Meta;
  };
};

export const GET_ALL_INITIAL_FOLLOW_UPS = gql`
  query GetAllInitialFollowUps($filterOptions: AccountFollowupFilterOptionsInput, $pageSize: Int, $pageNum: Int) {
    getAllInitialFollowUps(filterOptions: $filterOptions, pageSize: $pageSize, pageNum: $pageNum) {
      accountFollowups {
        id
        followUpOn
        type
        subType
        status
        notes
        relyingParty {
          id
          name
        }
        account {
          truentityId
          rpmStatus
          accountSummary {
            isTelehealthVisitLaunched
            signOffAt
          }
          user {
            firstName
            lastName
          }
        }
        createdAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;
