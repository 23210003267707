import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import type { GetTasksResponse } from '@/graphql/taskEncounter';
import { ADD_ENCOUNTER, GET_TASKS } from '@/graphql/taskEncounter';
import type { FollowUpFormHandles } from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import type { TaskType } from '@/types/graphql';
import { useMutation, useQuery } from '@apollo/client';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import BaseDialog from '../BaseDialog';
import type { EncounterDialogFormData } from './EncounterDialogForm';
import EncounterDialogForm from './EncounterDialogForm';

type Props = BaseDialogProps & {
  id: string;
  hideDialog: () => void;
  onComplete: () => void;
  accountCareEventId?: string;
  showTasks: boolean;
};

const NewEncounterDialog = ({ id, title, hideDialog, onComplete, accountCareEventId, showTasks, ...props }: Props): React.ReactElement => {
  const followUpFormRef = useRef<FollowUpFormHandles>(null);

  const [saveEncounter] = useMutation(ADD_ENCOUNTER, {
    onError: err => {
      console.error(err.message);
      enqueueSnackbar('Unable to update profile', { variant: 'error' });
    }
  });

  const { data: getTasksData, error: getTasksError } = useQuery<GetTasksResponse>(GET_TASKS, {
    variables: {
      truentityId: id,
      pageNum: 1, //TODO:  This is not going to work if the number of tasks becomes too big
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        status: ['PENDING']
      }
    },
    fetchPolicy: 'cache-and-network'
  });
  const [tasksFromAPI, setTasksFromAPI] = useState<TaskType[]>([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formData: EncounterDialogFormData) => {
    setDisableSubmitButton(true);

    const { isFollowUpRequired, ...encounter } = formData;

    saveEncounter({
      variables: {
        ...encounter,
        title: 'Title',
        type: 'BillableAnalysis',
        subType: 'SUB_TYPE',
        accountCareEventId: accountCareEventId,
        truentityId: id
      }
    })
      .then(() => {
        if (!isFollowUpRequired) {
          enqueueSnackbar('Encounter saved', { variant: 'success' });
          onComplete();

          return;
        }
        followUpFormRef.current
          ?.submitForm()
          .then(() => {
            enqueueSnackbar('Encounter saved', { variant: 'success' });
            onComplete();
          })
          .catch(error => {
            console.error(error);
            enqueueSnackbar('Unable to update profile', { variant: 'error' });
          });
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Unable to save encounter', { variant: 'error' });
      })
      .finally(() => {
        setDisableSubmitButton(false);
      });
  };

  useEffect(() => {
    if (getTasksData?.tasks) {
      setTasksFromAPI(getTasksData.tasks.tasks);
    }
  }, [getTasksData]);

  useEffect(() => {
    if (getTasksError) {
      console.error(getTasksError?.message);
      enqueueSnackbar('Unable to retrieve tasks', { variant: 'error' });
    }
  }, [enqueueSnackbar, getTasksError]);

  return (
    <BaseDialog hideDialog={hideDialog} maxWidth={'md'} fullWidth {...props} title={title}>
      <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
        <EncounterDialogForm
          disableSubmitButton={disableSubmitButton}
          onSubmit={onSubmit}
          onReset={hideDialog}
          showTasks={showTasks}
          tasksFromAPI={tasksFromAPI}
          followUpFormRef={followUpFormRef}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default NewEncounterDialog;
