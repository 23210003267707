import Button from '@/components/Button';
import ChipsList from '@/components/ChipsList';
import { FollowUpAddEditDialog } from '@/components/Dialogs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { renderFollowUpStatusChip } from '@/components/Dialogs/FollowUpAddEditDialog';
import { GridItem } from '@/components/GridItem';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H1, H2 } from '@/components/Typography';
import Icon from '@/elements/Icon';
import type { FollowUpStatusTypes, GetAccountFollowupByIdResponse } from '@/graphql/account';
import { DELETE_ACCOUNT_FOLLOWUP, GET_ACCOUNT_FOLLOWUP_BY_ID } from '@/graphql/account';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { findClosestReminderForToday } from '@/util/account';
import { zip } from '@/util/array';
import { formatDate, formatDateAndTime } from '@/util/format';
import { unknown } from '@/util/string';
import { useLazyQuery, useMutation } from '@apollo/client';
import { faVenusMars } from '@fortawesome/free-solid-svg-icons';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BackupIcon from '@mui/icons-material/Backup';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import InterestsIcon from '@mui/icons-material/Interests';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import TagIcon from '@mui/icons-material/Tag';
import TaskIcon from '@mui/icons-material/Task';
import { Chip, Divider, Drawer, Grid, IconButton, Popover, Stack } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { PatientsData } from './patients';

type Props = {
  patientsData: PatientsData | null;
  followupId?: string | null;
  refetchPatientDetail?: () => any;
};

const PatientInfoDrawer = ({ patientsData, followupId, refetchPatientDetail }: Props) => {
  const { showModal } = useModal();
  const [currentDate] = useState(new Date());
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [patientInfo, setPatientInfo] = useState<PatientsData | null>(null);
  const [fullName, setFullName] = useState<string>();
  const birthDateTodate = patientInfo?.birthDate ? new Date(patientInfo?.birthDate.replace(/-/g, '/')) : '';
  const years = moment(currentDate).diff(moment(birthDateTodate), 'years');
  const [relyingAdmins, setRelyingPartyAdmins] = useState<string[]>([]);
  const [filteredFollowup, setFilteredFollowup] = useState<FollowUpRemindersTypes | undefined>({} as FollowUpRemindersTypes);

  const orgNames = patientInfo?.clientOrgs?.map(org => org?.name);
  const storeNames = patientInfo?.clientStores?.map(store => store?.name);

  const orgStorePairs = zip(orgNames || [], storeNames || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const maxNamesToShow = 1;

  const [getFollowupById] = useLazyQuery<GetAccountFollowupByIdResponse>(GET_ACCOUNT_FOLLOWUP_BY_ID, {
    onCompleted: data => {
      setFilteredFollowup(data.accountsFollowupReminderById);
    }
  });
  const [deleteFollowup] = useMutation(DELETE_ACCOUNT_FOLLOWUP);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setShowMore(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowMore(false);
  };

  const handleEditButton = (
    truentityId: string | undefined,
    doNotCall: boolean,
    followupData: FollowUpRemindersTypes | undefined,
    rpmStatus?: string
  ) => {
    if (truentityId) {
      if (!followupData) return enqueueSnackbar('Cannot update follow-up since the follow-up could not be found.', { variant: 'error' });

      const modal = showModal(FollowUpAddEditDialog, {
        title: 'Update Follow Up On',
        hideDialog: () => {
          modal.hide();
        },
        handleRefetchAndClose: () => {
          modal.hide();
          setPatientInfo(null);
          refetchPatientDetail && refetchPatientDetail();
        },
        truentityId,
        rpmStatus: rpmStatus,
        doNotCall,
        followupsData: [followupData],
        isUpdateModal: true
      });
    }
  };

  const handleDeleteButton = () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Delete Follow-up',
      message: 'Are you sure you want to delete this follow-up? This action cannot be undone.',
      onAgree: async () => {
        await deleteFollowup({ variables: { followUpIds: [filteredFollowup?.id] } })
          .then(response => {
            const data = response.data.removeAccountFollowup;
            const variant = data!.status === 'Success' ? 'success' : 'error';
            enqueueSnackbar(data.message, { variant });
            setPatientInfo(null);
            modal.hide();
            refetchPatientDetail && refetchPatientDetail();
          })
          .catch(() => {
            enqueueSnackbar('Failed to delete this follow-up', {
              variant: 'error'
            });
            modal.hide();
          });
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  useEffect(() => {
    setPatientInfo(patientsData);
  }, [patientsData]);

  useEffect(() => {
    if (patientInfo) {
      setRelyingPartyAdmins(patientInfo?.currentAccountsAssignments?.map(ass => ass.relyingPartyAdmin?.name) || []);
    }
    if (followupId && patientInfo) {
      getFollowupById({
        variables: {
          id: followupId
        }
      });
    } else if (patientInfo) {
      const closestFollowUp = findClosestReminderForToday(patientInfo.accountsFollowupRemindersByAdmin);
      setFilteredFollowup(closestFollowUp ?? undefined);
    } else {
      setFilteredFollowup({} as FollowUpRemindersTypes);
    }
  }, [patientInfo, followupId]);

  useEffect(() => {
    if (patientInfo?.user) {
      setFullName(
        patientInfo?.user?.lastName && patientInfo?.user?.firstName
          ? `${patientInfo?.user?.lastName}, ${patientInfo?.user?.firstName}`
          : '---'
      );
    }
  }, [currentDate, patientInfo]);

  return (
    <Drawer
      container={this}
      anchor={'right'}
      open={patientInfo !== null}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      color="inherit"
      onClose={() => setPatientInfo(null)}
    >
      <Stack spacing={6} p={4} sx={{ maxWidth: '600px' }}>
        <Stack spacing={2}>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            <H1>{fullName} </H1>
            <Chip
              variant={'filled'}
              sx={{ textTransform: 'uppercase', letterSpacing: 2, height: 20, fontSize: '.75rem' }}
              label={patientInfo?.doNotCall ? 'Do Not Follow Up' : 'May Follow Up'}
              color={patientInfo?.doNotCall ? 'error' : 'success'}
            />
            <Box flex={1}></Box>
            <Button label="Detail" onClick={() => navigate(`/patients/${patientInfo?.truentityId}/details/medications/triage/list`)} />
          </Stack>

          <Stack direction="row" spacing={1}>
            <React.Fragment>
              <Stack direction="row" spacing={1}>
                {orgStorePairs.slice(0, maxNamesToShow).map(([orgName, storeName]) => (
                  <React.Fragment key={`${orgName}-${storeName}`}>
                    {orgName && <Chip label={orgName} />}
                    {storeName && <Chip label={storeName} variant="outlined" />}
                  </React.Fragment>
                ))}
                {orgStorePairs.length > maxNamesToShow && (
                  <Button variant="text" onClick={handleClick}>
                    {showMore ? 'Show Less' : 'Show More'}
                  </Button>
                )}
              </Stack>
              <Popover
                open={showMore}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Stack direction="column" spacing={1} sx={{ p: 2 }}>
                  {orgStorePairs.map(
                    ([orgName, storeName], index) =>
                      index >= maxNamesToShow && (
                        <div key={`${orgName}-${storeName}`}>
                          {orgName && <Chip label={orgName} style={{ marginRight: '4px' }} />}
                          {storeName && <Chip label={storeName} variant="outlined" />} <br />
                        </div>
                      )
                  )}
                  <Button variant="text" onClick={handleClose}>
                    Close
                  </Button>
                </Stack>
              </Popover>
            </React.Fragment>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <H2>Profile</H2>
          <Grid container rowSpacing={2}>
            <GridItem xs={12} icon={<TagIcon />} label="Truentity Id" value={patientInfo?.truentityId?.toUpperCase()} />
            {/* @2024/04/05 Patient emails hidden */}
            {/* <GridItem xs={12} icon={<MailIcon />} label="Email" value={patientInfo?.user?.email} /> */}
            {patientInfo?.birthDate && (
              <GridItem xs={12} icon={<CalendarMonthIcon />} label="Date of Birth" value={`${formatDate(birthDateTodate)} (${years} yr)`} />
            )}
            <GridItem xs={12} icon={<LocalPhoneIcon />} label="Phone" value={<TruentityPhoneNumber value={patientInfo?.phone} />} />
            <GridItem xs={12} icon={<RoomIcon />} label="Location" value={patientInfo?.address} />
            <GridItem xs={12} icon={<Icon icon={faVenusMars} />} label="Gender" value={patientInfo?.gender ?? unknown()} />
          </Grid>
        </Stack>

        <Stack spacing={1}>
          <H2>Meds Summary</H2>
          <Grid container rowSpacing={2}>
            <GridItem xs={12} icon={<LocalHospitalIcon />} label="Current Meds" value={patientInfo?.currentMedsCount} />
            <GridItem xs={12} icon={<BackupIcon />} label="New Uploads" value={patientInfo?.numQuickUploadsNew || 0} />
            <GridItem
              xs={12}
              icon={<CalendarMonthIcon />}
              label="Last Import Date"
              value={formatDateAndTime(patientInfo?.lastImport?.lastImportDate)}
            />
          </Grid>
        </Stack>

        <Stack spacing={1}>
          <H2>Tasks and Encounters</H2>
          <Grid container rowSpacing={2}>
            <GridItem xs={12} icon={<AssignmentIcon />} label="Pending Tasks" value={patientInfo?.numPendingTasks} />
            <GridItem xs={12} icon={<AssignmentTurnedInIcon />} label="Completed Tasks" value={patientInfo?.numCompletedTasks} />
            <GridItem
              xs={12}
              icon={<CalendarMonthIcon />}
              label="Last Encounter On"
              value={formatDateAndTime(patientInfo?.lastEncounterDate)}
            />
            <GridItem
              xs={12}
              icon={<CalendarMonthIcon />}
              label="Follow-up Date"
              value={
                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                  {formatDate(filteredFollowup?.followUpOn)}
                  {followupId && (
                    <Stack direction="row">
                      <IconButton
                        onClick={() =>
                          handleEditButton(
                            patientInfo?.truentityId,
                            patientInfo?.doNotCall as boolean,
                            filteredFollowup,
                            patientInfo?.rpmStatus
                          )
                        }
                      >
                        <CreateIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={handleDeleteButton}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              }
            />
            <GridItem
              xs={12}
              icon={<CalendarMonthIcon />}
              label="Follow-up Time"
              value={formatDate(filteredFollowup?.followUpOn, 'HH:mm A')}
            />
            <GridItem xs={12} icon={<InterestsIcon />} label="Follow-up Type" value={filteredFollowup?.type} />
            {filteredFollowup?.subType && (
              <GridItem xs={12} icon={<InterestsIcon />} label="Follow-up Sub Type" value={filteredFollowup?.subType.toUpperCase()} />
            )}
            <GridItem
              xs={12}
              icon={<TaskIcon />}
              label="Follow-up Status"
              value={renderFollowUpStatusChip(filteredFollowup?.status as FollowUpStatusTypes)}
            />
          </Grid>
        </Stack>

        <Stack spacing={2}>
          <Divider />
          <GridItem xs={12} icon={<PersonIcon />} label="Assigned To" value={<ChipsList useAvatar={true} items={relyingAdmins} />} />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default PatientInfoDrawer;
