import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';
import type { ApexOptions } from 'apexcharts';
import ReactECharts from 'echarts-for-react';
import type { ReactNode } from 'react';
import Chart from 'react-apexcharts';
import { H3 } from '../Typography';

type BaseChartProps = StackProps & {
  title?: ReactNode;
  children: ReactNode;
  additionalCss?: any;
};

type PieChartProps = {
  options?: Partial<ApexOptions>;
  width?: string | number;
  height?: string | number;
  series?: ApexOptions['series'];
  colors?: string[];
} & Partial<BaseChartProps>;

type StackedBarChartProps = {
  data: { name: string; value: number }[];
  colors?: string[];
  height?: string | number;
  width?: string | number;
};

export const chartColors = {
  blue: '#3498DB',
  green: '#2ECC71',
  yellow: '#F1C40F',
  orange: '#E67E22',
  red: '#E74C3C',
  purple: '#9B59B6',
  lightGreen: '#1ABC9C',
  grey: '#95A5A6'
};

export const BaseChart = ({ title, children, additionalCss, ...props }: BaseChartProps) => {
  return (
    <Stack
      sx={{
        padding: '15px',
        marginBottom: '10px',
        borderRadius: additionalCss?.borderRadius || undefined,
        width: additionalCss?.width ? additionalCss.width : null,
        backgroundColor: additionalCss?.backgroundColor ? additionalCss.backgroundColor : '#ffffff'
      }}
      {...props}
    >
      {title && <H3 textAlign={'center'}>{title}</H3>}
      {children}
    </Stack>
  );
};

export const PieChart = ({ options, series, width, colors = [], ...props }: PieChartProps) => {
  options = {
    ...options,
    dataLabels: {
      enabled: false
    },
    theme: {
      monochrome: {
        enabled: false
      }
    },
    colors:
      (colors?.length ?? 0) > 0
        ? colors
        : [
            chartColors.blue,
            chartColors.green,
            chartColors.yellow,
            chartColors.orange,
            chartColors.red,
            chartColors.purple,
            chartColors.lightGreen,
            chartColors.grey
          ]
  };

  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} type="pie" width={width} />
    </BaseChart>
  );
};

export const BarChart = ({ options, series, width, height, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} type="bar" width={width} height={height} />
    </BaseChart>
  );
};

export const LineChart = ({ options, series, width, height, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart style={{ minWidth: '-webkit-fill-available' }} options={options} series={series} type="line" width={width} height={height} />
    </BaseChart>
  );
};

export const DonutChart = ({ options, series, labels, width, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} labels={labels} type="donut" width={width} />
    </BaseChart>
  );
};

export const SparkLine = ({ title, subTitle, series, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart
        type="area"
        options={{
          chart: {
            type: 'area',
            height: 160,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'straight'
          },
          yaxis: {
            min: 0,
            labels: {
              show: false
            }
          },
          title: {
            text: title,
            offsetX: 0,
            style: {
              fontSize: '24px'
            }
          },
          subtitle: {
            text: subTitle,
            offsetX: 0,
            style: {
              fontSize: '14px'
            }
          }
        }}
        series={series}
      />
    </BaseChart>
  );
};

export const EChart = ({ option }) => {
  return (
    <ReactECharts
      option={option}
      style={{ height: '420px', width: '550px' }}
      notMerge={true}
      lazyUpdate={true}
      opts={{ renderer: 'svg' }}
    />
  );
};

export const StackedBarChart = ({
  data,
  colors = ['#387F39', '#F1C40F', '#EB5353', '#AFAFB5'],
  height = '210px',
  width = '400px'
}: StackedBarChartProps & { isLoading?: boolean }) => {
  const option = {
    grid: {
      left: '1%',
      containLabel: false
    },
    xAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false }
    },
    yAxis: {
      type: 'category',
      splitLine: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false }
    },
    series: data.map((item, index) => ({
      name: item.name,
      type: 'bar',
      stack: 'total',
      itemStyle: {
        color: colors[index % colors.length],
        borderRadius: index === 0 ? [5, 0, 0, 5] : index === data.length - 1 ? [0, 5, 5, 0] : [0, 0, 0, 0]
      },
      data: [item.value]
    }))
  };

  return <ReactECharts option={option} style={{ height, width }} notMerge={true} lazyUpdate={true} opts={{ renderer: 'svg' }} />;
};
export const EchartBarChart = ({
  data,
  categories,
  colors = ['#387F39', '#F1C40F', '#EB5353', '#AFAFB5'],
  height = '300px',
  width = '300px',
  horizontal = true,
  onBarClick,
  animation = false
}: {
  data: { name: string; value: number }[];
  categories: string[];
  colors?: string[];
  height?: string;
  width?: string;
  horizontal?: boolean;
  onBarClick?: (params: unknown) => void;
  animation?: boolean;
}) => {
  const option = {
    grid: {
      containLabel: true
    },
    xAxis: horizontal
      ? {
          type: 'value',
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false }
        }
      : {
          type: 'category',
          data: categories,
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            interval: 0,
            formatter: function (value: string) {
              const words = value.split(' ');
              const lines: string[] = [];
              for (let i = 0; i < words.length; i += 2) {
                lines.push(words.slice(i, i + 2).join(' '));
              }
              return lines.join('\n');
            },
            textStyle: {
              fontSize: 12,
              lineHeight: 16,
              overflow: 'truncate'
            }
          }
        },
    yAxis: horizontal
      ? {
          type: 'category',
          data: categories,
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            interval: 0,
            formatter: function (value: string) {
              const words = value.split(' ');
              const lines: string[] = [];
              for (let i = 0; i < words.length; i += 2) {
                lines.push(words.slice(i, i + 2).join(' '));
              }
              return lines.join('\n');
            },
            textStyle: {
              fontSize: 12,
              lineHeight: 16,
              overflow: 'truncate'
            }
          }
        }
      : {
          type: 'value',
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false }
        },
    series: {
      data: data.map((item, index) => ({
        value: item.value,
        itemStyle: {
          color: colors[index % colors.length],
          borderRadius: [5, 5, 5, 5]
        }
      })),
      type: 'bar',
      animation
    }
  };

  const onEvents = {
    click: (params: unknown) => {
      if (onBarClick) {
        onBarClick(params);
      }
    }
  };

  return (
    <ReactECharts
      option={option}
      style={{ height, width }}
      notMerge={true}
      lazyUpdate={true}
      opts={{ renderer: 'svg' }}
      onEvents={onEvents}
    />
  );
};
