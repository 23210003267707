import type { Props as AlterProps } from '@/components/Alert';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import type { AccountsFollowupResponse, UpdateAccountFollowupResponse } from '@/graphql/account';
import { ADD_ACCOUNT_FOLLOWUP, FollowUpStatusTypes, FollowUpTypes, UPDATE_ACCOUNT_FOLLOWUP } from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import type { FollowUpRemindersTypes, InitialVisitFollowupCustomTypes } from '@/types/accountProfile';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { toDate } from '@/util/date';
import { followUpStatuses, followUpSubTypes, followUpTypes } from '@/util/followUp';
import {
  formatDate,
  formatDateAndTime2,
  formatDateIgnoreTZAddDays,
  formatTime,
  getCurrentDate,
  setSpecificTimeOnDate
} from '@/util/format';
import { useMutation, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { Autocomplete, Grid, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { FormEvent } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

type Props = {
  truentityId: string;
  formMode: FollowupFormModeTypes;
  doNotCall: boolean;
  accountAssignees: RelyingPartyAdminData[];
  rpmStatus?: string | undefined;
  isInitialVisitFollowupForm?: boolean;
  defaultFollowUpType?: string;
  defaultFollowupSubType?: string;
  followupsData?: FollowUpRemindersTypes[] | undefined;
  hideSubmitButton?: boolean;
  hideDialog?: () => void;
  handleRefetchAndClose?: () => void;
};

export type FollowUpFormHandles = {
  submitForm: () => Promise<FollowUpRemindersTypes[] | undefined>;
};

const FollowUpForm = forwardRef<FollowUpFormHandles, Props>(
  (
    {
      truentityId,
      formMode,
      doNotCall,
      accountAssignees,
      rpmStatus,
      defaultFollowUpType,
      defaultFollowupSubType,
      followupsData,
      hideSubmitButton = false,
      isInitialVisitFollowupForm = false,
      hideDialog,
      handleRefetchAndClose
    },
    ref
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useReactiveVar(currentLoggedUserVar);
    const performDateDefaultValue = useRef<Date | null>(null);

    const [performedDate, setPerformedDate] = useState<DateInput>(formatDateIgnoreTZAddDays(getCurrentDate(), 7));
    const [performedTime, setPerformedTime] = useState<DateInput>(setSpecificTimeOnDate(10, 0, 0));
    const [type, setType] = useState<string>('');
    const [subType, setSubType] = useState<string>('None');
    const [status, setStatus] = useState<string>(FollowUpStatusTypes.PENDING);
    const [note, setNote] = useState<string>('');
    const [alertContent, setAlertContent] = useState<AlterProps>({} as AlterProps);
    const [followupAssignees, setFollowupAssignees] = useState<RelyingPartyAdminData[]>([]);

    const isRpmEnrolled = useMemo(() => (rpmStatus && rpmStatus === RpmStatusTypes.ENROLLED) || false, [rpmStatus]);
    const isRpmUnenrolled = useMemo(() => (rpmStatus && rpmStatus === RpmStatusTypes.UNENROLLED) || false, [rpmStatus]);
    const initialVisitCustomProps: InitialVisitFollowupCustomTypes | undefined = useMemo(() => {
      if (isInitialVisitFollowupForm) {
        return {
          hiddenFields: {
            type: true,
            subType: true,
            status: true
          },
          customLabels: {
            performedDate: 'Visit Date',
            performedTime: 'Visit Time',
            type: 'Visit Type',
            assignee: 'Visit Assignee',
            subType: 'Visit Subtype'
          }
        };
      }
    }, [isInitialVisitFollowupForm]);

    const medicalServicesShortNames = useMemo(
      () => currentUser?.relyingParty.medicalServices.map(medicalService => medicalService.shortName) || [],
      [currentUser]
    );

    const FOLLOW_UP_TYPES: TextSelectOption[] = useMemo(() => {
      const types = followUpTypes(doNotCall, isRpmEnrolled, isRpmUnenrolled, medicalServicesShortNames);
      const initialVisitFollowup = followupsData?.every(followup => followup.type === FollowUpTypes.RPM_INITIAL_VISIT);

      if (!isInitialVisitFollowupForm && !initialVisitFollowup && isRpmEnrolled) {
        const filteredTypes = types.filter(element => element.value !== FollowUpTypes.RPM_INITIAL_VISIT);
        return filteredTypes;
      }
      return types;
    }, [doNotCall, isRpmEnrolled, isRpmUnenrolled, medicalServicesShortNames, followupsData, isInitialVisitFollowupForm]);

    const FOLLOW_UP_SUB_TYPES: TextSelectOption[] = useMemo(() => followUpSubTypes(), []);

    const [addAccountFollowUp] = useMutation<AccountsFollowupResponse>(ADD_ACCOUNT_FOLLOWUP);
    const [updateAccountFollowUp] = useMutation<UpdateAccountFollowupResponse>(UPDATE_ACCOUNT_FOLLOWUP);

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const dateTime = formatDateAndTime2(performedDate, performedTime);
      const assigneesIds = followupAssignees.map(assignee => assignee.id);
      const followupIds = followupsData?.map(followupData => followupData.id) || [];

      const followupObject = {
        ...(formMode === FollowupFormModeTypes.UPDATE && followupsData !== undefined && followupIds.length > 0
          ? { followUpIds: followupIds }
          : { truentityId }),
        followUpAssigneeIds: assigneesIds,
        followUpOn: dateTime,
        type: type,
        subType: subType === 'None' ? '' : subType,
        note: note,
        status: status
      };

      if (formMode === FollowupFormModeTypes.UPDATE) {
        try {
          const response = await updateAccountFollowUp({
            variables: followupObject
          });
          if (response?.data?.updateAccountFollowup?.status === 'Success') {
            enqueueSnackbar(response?.data?.updateAccountFollowup?.message, {
              variant: 'success'
            });
            return response?.data?.updateAccountFollowup?.followUps || undefined;
          } else {
            enqueueSnackbar(
              response?.errors !== undefined && response.errors?.length > 0 ? response.errors[0]?.message : 'Could not update followup',
              {
                variant: 'error'
              }
            );
          }
        } catch (err: any) {
          enqueueSnackbar(err?.message ?? 'Could not update followup', { variant: 'error' });
        } finally {
          if (handleRefetchAndClose) {
            handleRefetchAndClose();
          } else if (hideDialog) {
            hideDialog();
          }
        }
      } else {
        try {
          const response = await addAccountFollowUp({
            variables: followupObject
          });
          if (response?.data?.addAccountFollowup?.status === 'Success') {
            enqueueSnackbar(response?.data?.addAccountFollowup?.message, {
              variant: 'success'
            });
            return response?.data?.addAccountFollowup?.followUps || undefined;
          } else {
            enqueueSnackbar(
              response?.errors !== undefined && response.errors?.length > 0 ? response.errors[0]?.message : 'Could not add followup',
              {
                variant: 'error'
              }
            );
          }
        } catch (err: any) {
          enqueueSnackbar(err?.message ?? 'Could not add followup', { variant: 'error' });
        } finally {
          if (handleRefetchAndClose) {
            handleRefetchAndClose();
          } else if (hideDialog) {
            hideDialog();
          }
        }
      }
    };

    const followupAssigneeValue = useMemo(() => {
      if (followupsData) {
        const assignees: RelyingPartyAdminData[] = followupsData.map(followupData => {
          return { id: followupData.relyingPartyAdmin.id, name: getAccountUserFullName(followupData.relyingPartyAdmin.user) };
        });
        setFollowupAssignees(assignees);
        return assignees;
      } else if (currentUser) {
        setFollowupAssignees([{ id: currentUser.id, name: getAccountUserFullName(currentUser.user) }]);
        return [{ id: currentUser.id, name: getAccountUserFullName(currentUser.user) }];
      }
    }, [currentUser, followupsData]);

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        const fakeEvent = { preventDefault: () => {} } as FormEvent<HTMLFormElement>;

        return handleOnSubmit(fakeEvent);
      }
    }));

    useEffect(() => {
      if (followupsData) return;

      if (!isRpmEnrolled && defaultFollowUpType) {
        const defaultType = FOLLOW_UP_TYPES.find(type => type.value === defaultFollowUpType);

        if (defaultType) {
          setType(defaultType.value);
          return;
        }
      }

      setType(doNotCall ? FOLLOW_UP_TYPES[1].value : FOLLOW_UP_TYPES[0].value);
    }, [doNotCall, isRpmEnrolled, followupsData, defaultFollowUpType, FOLLOW_UP_TYPES]);

    useEffect(() => {
      if (followupsData) return;

      if (defaultFollowupSubType) {
        const defaultSubType = FOLLOW_UP_SUB_TYPES.find(subType => subType.value === defaultFollowupSubType);

        if (defaultSubType) {
          setSubType(defaultSubType.value);
          return;
        }
      }
    }, [followupsData, defaultFollowupSubType, FOLLOW_UP_SUB_TYPES]);

    useEffect(() => {
      if (followupsData) {
        const followupData = followupsData?.[0];
        setPerformedDate(formatDate(followupData.followUpOn));
        setPerformedTime(formatTime(followupData.followUpOn, 'HH:mm'));
        performDateDefaultValue.current = toDate(followupData.followUpOn);
        setStatus(followupData.status);
        setType(followupData.type);
        setSubType(followupData.subType);
        setNote(followupData.notes);
      }
    }, [followupsData]);

    useEffect(() => {
      const newAssigneeNames = followupAssignees
        .map(assignee => assignee.name)
        .filter(name => !accountAssignees.map(assignee => assignee.name).includes(name));

      if (newAssigneeNames.length > 0) {
        let description = '';
        if (newAssigneeNames.length === 1) {
          description = `${newAssigneeNames[0]} is not assigned to this patient. Proceeding further will assign this admin to the patient, and any upcoming follow-ups for the patient will also be assigned to "${newAssigneeNames[0]}".`;
        } else {
          const lastAssignee = newAssigneeNames.pop();
          description = `${newAssigneeNames.join(', ')} ${
            newAssigneeNames.length > 1 ? 'are' : 'is'
          } not assigned to this patient, and ${lastAssignee} is not assigned to this patient. Proceeding further will assign these admins to the patient, and any upcoming follow-ups for the patient will also be assigned to "${lastAssignee}.`;
        }

        setAlertContent({
          title: 'Important: New Assignee',
          description,
          status: 'info'
        });
      } else {
        setAlertContent({} as AlterProps);
      }
    }, [accountAssignees, followupAssignees]);

    return (
      <Stack
        sx={{
          minHeight: '250px'
        }}
        component="form"
        onSubmit={handleOnSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TruentityDatePicker
              label={initialVisitCustomProps?.customLabels.performedDate || 'Follow-up Date'}
              format="YYYY-MM-DD"
              value={performedDate}
              defaultValue={performDateDefaultValue.current}
              onChange={date => setPerformedDate(date as Date)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{
                marginTop: '0.5rem'
              }}
              fullWidth
              id="time"
              label={initialVisitCustomProps?.customLabels.performedTime || 'Follow-up Time'}
              type="time"
              value={performedTime}
              inputProps={{
                step: 300
              }}
              required
              onChange={event => {
                const value = event.target.value;
                if (value) {
                  setPerformedTime(value);
                }
              }}
            />
          </Grid>
          {!initialVisitCustomProps?.hiddenFields.type && (
            <Grid item xs={6}>
              <SelectList
                id="followupType"
                label={initialVisitCustomProps?.customLabels.type || 'Follow-up Type'}
                options={FOLLOW_UP_TYPES}
                placeholder="Select an option..."
                value={type}
                keepOriginalValue
                onChange={event => setType(event.target.value as string)}
                readOnly={isInitialVisitFollowupForm && type === FollowUpTypes.RPM_INITIAL_VISIT}
                required
              />
            </Grid>
          )}
          <Grid item xs={!initialVisitCustomProps?.hiddenFields.type || !initialVisitCustomProps?.hiddenFields.subType ? 6 : 12}>
            <Autocomplete
              multiple
              id="follow-up-assignees"
              options={accountAssignees}
              onChange={(_event, value) => setFollowupAssignees(value)}
              getOptionLabel={option => option.name || ''}
              disabled={!isInitialVisitFollowupForm && formMode === FollowupFormModeTypes.UPDATE}
              defaultValue={followupAssigneeValue}
              renderInput={params => (
                <TextField
                  {...params}
                  label={initialVisitCustomProps?.customLabels.assignee || 'Follow-up Assignee'}
                  placeholder="select an option"
                  margin="dense"
                  disabled={!isInitialVisitFollowupForm && formMode === FollowupFormModeTypes.UPDATE}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
          {!initialVisitCustomProps?.hiddenFields.subType && (
            <Grid item xs={6}>
              <SelectList
                id="followupSubType"
                label={initialVisitCustomProps?.customLabels.subType || 'Follow-up Sub Type (Optional)'}
                options={FOLLOW_UP_SUB_TYPES}
                placeholder="Select an option..."
                value={subType}
                keepOriginalValue
                onChange={event => setSubType(event.target.value as string)}
              />
            </Grid>
          )}
          {!initialVisitCustomProps?.hiddenFields.status && (
            <Grid item xs={6}>
              <SelectList
                id="status"
                label={initialVisitCustomProps?.customLabels.status || 'Status'}
                options={followUpStatuses()}
                placeholder="Select an status..."
                value={status}
                keepOriginalValue
                onChange={event => setStatus(event.target.value as string)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              defaultValue={note}
              multiline
              fullWidth
              rows={2}
              inputProps={{ max: 2 }}
              placeholder="Type your note here"
              id="note"
              label="Notes (Optional)"
              onChange={event => {
                setNote(event.target.value);
              }}
            />
          </Grid>
          {!hideSubmitButton && (
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button type="button" a11yLabel="Cancel" variant="text" onClick={hideDialog}>
                  Cancel
                </Button>
                <Button type="submit" a11yLabel={formMode} />
              </Stack>
            </Grid>
          )}
        </Grid>
        {Object.keys(alertContent).length > 0 && formMode === FollowupFormModeTypes.ADD && (
          <Alert
            title={alertContent.title}
            description={alertContent.description}
            status={alertContent.status}
            style={{ marginTop: '8px' }}
          />
        )}
      </Stack>
    );
  }
);

export default FollowUpForm;
