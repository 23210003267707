import { Body1 } from '@/components/Typography';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import type { StackProps } from '@mui/material';
import { Box, Collapse, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

type Props = StackProps & {
  icon?: ReactNode;
  isLoading?: boolean;
  primaryText?: ReactNode | string;
  children: ReactNode;
  defaultOpen?: boolean;
  selected?: boolean;
  onMainTabItemClicked?: () => void;
  disableCollapse?: boolean;
};

const CollapsibleListGroup = ({
  icon,
  primaryText,
  children,
  defaultOpen,
  selected,
  onMainTabItemClicked,
  disableCollapse,
  isLoading = false,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!disableCollapse) {
      setOpen(defaultOpen === true);
    }
  }, [defaultOpen, disableCollapse]);

  const renderExpandIcon = () => {
    if (disableCollapse || isLoading) {
      return <></>;
    } else {
      return open ? <ExpandLess /> : <ExpandMore />;
    }
  };

  return (
    <Stack {...props}>
      <ListItemButton
        selected={selected}
        onClick={() => {
          if (onMainTabItemClicked) {
            onMainTabItemClicked();
          }

          if (!disableCollapse) {
            setOpen(!open);
          }
        }}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          color={'white'}
          primary={
            <Body1 fontWeight={400} color={'inherit'}>
              {primaryText}
            </Body1>
          }
        />
        {isLoading ? (
          <CircularProgress
            size={18}
            color={'inherit'}
            sx={{
              marginX: 0.5
            }}
          />
        ) : (
          <></>
        )}
        {renderExpandIcon()}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <Box pl={2}>{children}</Box>
      </Collapse>
    </Stack>
  );
};

export default CollapsibleListGroup;
