import Button from '@/components/Button';
import { Caption } from '@/components/Typography';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StyleIcon from '@mui/icons-material/Style';
import { Badge, IconButton, Stack } from '@mui/material';

export default function AccountFollowUp(props: {
  doNotCall: boolean | undefined;
  followUpRemindersTypes: FollowUpRemindersTypes[];
  onFollowUpClick: () => void;
  onCalendarClick: () => void;
  onAllFollowUpsClick: () => void;
}) {
  return (
    <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} py={1}>
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} alignItems={'center'}>
        <Caption sx={{ color: props.doNotCall ? 'red' : 'green', fontWeight: 'bold', fontSize: '12px' }}>
          {props.doNotCall ? 'DO NOT FOLLOW UP' : 'MAY FOLLOW UP'}
        </Caption>
        <Badge color={props.followUpRemindersTypes?.length > 0 ? 'success' : 'error'} variant="dot">
          <Button variant="outlined" size="small" label="New Follow-up" startIcon={<AddIcCallIcon />} onClick={props.onFollowUpClick} />
        </Badge>
        <Button variant="outlined" size="small" label="All Follow-ups" startIcon={<StyleIcon />} onClick={props.onAllFollowUpsClick} />
        <IconButton onClick={props.onCalendarClick}>
          <CalendarMonthIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
