import MuiMenu from '@/components/MuiMenu';
import { RpmFollowupConsultFilterType } from '@/types/remotePatientMonitoring';

type Props = {
  onFilterValueChange: (value: RpmFollowupConsultFilterType) => void;
  followupFilterType: RpmFollowupConsultFilterType;
};

const RpmFollowTabFilterSelect = ({ onFilterValueChange, followupFilterType }: Props) => {
  return (
    <MuiMenu
      variant="outlined"
      label={followupFilterType === RpmFollowupConsultFilterType.INDIVIDUAL ? 'This Visit' : 'All'}
      ButtonProps={{
        sx: {
          textTransform: 'none'
        }
      }}
      options={[
        {
          label: 'This Visit',
          onAction: () => {
            onFilterValueChange(RpmFollowupConsultFilterType.INDIVIDUAL);
          }
        },
        {
          label: 'All',
          onAction: () => {
            onFilterValueChange(RpmFollowupConsultFilterType.ALL);
          }
        }
      ]}
    />
  );
};

export default RpmFollowTabFilterSelect;
